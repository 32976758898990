import React  from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import {checkAuthLoader} from "./shared/auth";
import ChangePassword from "./containers/Auth/ChangePassword";
import Login from "./containers/Auth/Login";
import NavBar from "./components/NavBar/NavBar";
import ForgotPwd from "./containers/Auth/ForgotPwd";
import CheckCode from "./containers/Auth/CheckCode";
import ResetPwd from "./containers/Auth/ResetPwd";
import PoliticaPrivacidade from "./containers/PoliticaPrivacidade/PoliticaPrivacidade";
import Dashboard from "./containers/Dashboard/Dashboard";
import Error from "./containers/Error/Error";
import Certificados from "./containers/Certificados/Certificados";
import Perfil from "./containers/Perfil/Perfil";
import Contas from "./containers/Contas/Contas";
import NewConta from "./containers/Contas/NewConta";
import NotasCompras from "./containers/NotasCompras/NotasCompras";
import EXM from "./containers/EXM/EXM";
import DetalhaConta from "./containers/Contas/DetalhaConta";
// import Boletos from "./containers/Boletos/Boletos";
import BoletosHistoric from "./containers/BoletosHistoric/BoletosHistoric";
import ChangeLogs from "./containers/ChangeLogs/ChangeLogs";
import UploadCertificado from "./containers/Certificados/UploadCertificado";
import Cadastros from "./containers/Cadastros/Cadastros";
import LancamentosIgnorados from "./containers/Contas/LancamentosIgnorados/LancamentosIgnorados";
import ImportFile from "./containers/EXM/ImportFile";
import EditarLancamentoRecorrente from "./containers/EXM/EditarMovimentacao/EditarLancamentoRecorrente";
import AnexoInteligente from "./containers/AnexoInteligente/AnexoInteligente";

export const getRoutes = (dispatch, token, isAdmin, resetarSenha, isCommonUser, isOperator, isApprover, storeId) => {
    let routes = [];
    //Rotas autenticadas
    if (token && storeId) {
        if (resetarSenha) {
            routes = [
                {path: "mudar_senha", element: <ChangePassword />, loader: () => checkAuthLoader(dispatch)},
                {path: "*", element: <Navigate to="/mudar_senha" />},
            ];
        }
        else if (isAdmin) {
            routes = [
                {path: "dashboard", element: <Dashboard />, loader: () => checkAuthLoader(dispatch)},
                // {path: "boletos", element: <Boletos />, loader: () => checkAuthLoader(dispatch)},
                {path: "historico_boletos", element: <BoletosHistoric />, loader: () => checkAuthLoader(dispatch)},
                // {path: "upload_boletos", element: <UploadBoleto />, loader: () => checkAuthLoader(dispatch)},
                {path: "certificados", element: <Certificados />, loader: () => checkAuthLoader(dispatch)},
                {path: "upload_certificado", element: <UploadCertificado />, loader: () => checkAuthLoader(dispatch)},
                {path: "contas", element: <Contas />, loader: () => checkAuthLoader(dispatch)},
                {path: "nova_conta", element: <NewConta />, loader: () => checkAuthLoader(dispatch)},
                {path: "perfil", element: <Perfil />, loader: () => checkAuthLoader(dispatch)},
                {path: "notas_compras", element: <NotasCompras />, loader: () => checkAuthLoader(dispatch)},
                {path: "extrato_movimentacoes", element: <EXM />, loader: () => checkAuthLoader(dispatch)},
                {path: "importar_planilha", element: <ImportFile />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalhamento_conta", element: <DetalhaConta />, loader: () => checkAuthLoader(dispatch)},
                {path: "change_logs", element: <ChangeLogs />, loader: () => checkAuthLoader(dispatch)},
                {path: "cadastros", element: <Cadastros />, loader: () => checkAuthLoader(dispatch)},
                {path: "ignorados", element: <LancamentosIgnorados />, loader: () => checkAuthLoader(dispatch)},
                {path: "editar_recorrencia", element: <EditarLancamentoRecorrente />, loader: () => checkAuthLoader(dispatch)},
                {path: "anexo_inteligente", element: <AnexoInteligente />, loader: () => checkAuthLoader(dispatch)},
                {path: "*", element: <Navigate to="/dashboard" />}
            ];
        } else {
            const common_routes = [
                {path: "dashboard", element: <Dashboard />, loader: () => checkAuthLoader(dispatch)},
                {path: "contas", element: <Contas />, loader: () => checkAuthLoader(dispatch)},
                {path: "nova_conta", element: <NewConta />, loader: () => checkAuthLoader(dispatch)},
                {path: "perfil", element: <Perfil />, loader: () => checkAuthLoader(dispatch)},
                {path: "detalhamento_conta", element: <DetalhaConta />, loader: () => checkAuthLoader(dispatch)},
                {path: "extrato_movimentacoes", element: <EXM />, loader: () => checkAuthLoader(dispatch)},
                {path: "notas_compras", element: <NotasCompras />, loader: () => checkAuthLoader(dispatch)},
                {path: "change_logs", element: <ChangeLogs />, loader: () => checkAuthLoader(dispatch)},
                {path: "cadastros", element: <Cadastros />, loader: () => checkAuthLoader(dispatch)},
                {path: "ignorados", element: <LancamentosIgnorados />, loader: () => checkAuthLoader(dispatch)},
                {path: "*", element: <Navigate to="/dashboard" />}
            ];
            if (isCommonUser) {
                routes = common_routes;
            }
            if (isOperator) {
                routes = common_routes;
                routes.push(
                    // {path: "boletos", element: <Boletos />, loader: () => checkAuthLoader(dispatch)},
                    {path: "historico_boletos", element: <BoletosHistoric />, loader: () => checkAuthLoader(dispatch)},
                    {path: "notas_compras", element: <NotasCompras />, loader: () => checkAuthLoader(dispatch)},
                    {path: "editar_recorrencia", element: <EditarLancamentoRecorrente />, loader: () => checkAuthLoader(dispatch)},
                    {path: "importar_planilha", element: <ImportFile />, loader: () => checkAuthLoader(dispatch)},
                    {path: "certificados", element: <Certificados />, loader: () => checkAuthLoader(dispatch)},
                    {path: "upload_certificado", element: <UploadCertificado />, loader: () => checkAuthLoader(dispatch)},
                    {path: "anexo_inteligente", element: <AnexoInteligente />, loader: () => checkAuthLoader(dispatch)},
                    // {path: "upload_boletos", element: <UploadBoleto />, loader: () => checkAuthLoader(dispatch)}
                );
                // Para adicionar mais rotas para operadores, usar o routes.push
            }
            if (isApprover){
                routes.push(
                    // {path: "boletos", element: <Boletos />, loader: () => checkAuthLoader(dispatch)},
                    {path: "historico_boletos", element: <BoletosHistoric />, loader: () => checkAuthLoader(dispatch)}
                );
            }
        }
    } else {
        routes = [
            {path: "forgot_pwd", element: <ForgotPwd />},
            {path: "check_code", element: <CheckCode />},
            {path: "reset_pwd", element: <ResetPwd />},
            {path: "politica", element: <PoliticaPrivacidade />},
            {index: true, element: <Login />},
        ];
        if (!token) {
            routes.push({path: "*", element: <Navigate to="/" />});
        }
    }

    return createBrowserRouter([
        {
            path: "*",
            errorElement: <Error />,
            element: <NavBar />,
            children: routes
        },
    ]);
};