import {
	CHANGE_STORE,
	CHANGE_ESTABELECIMENTO,
	CHANGE_ESTABELECIMENTOS,
	CHANGE_LOJAS,
	CHANGE_MODIFIED,
	CHANGE_OPEN_ACCORDION_SELECTED,
	CHANGE_STORE_NAME,
	CHANGE_OPEN_COMPONENT,
	CHANGE_OPEN_LANCAMENTO,
	CHANGE_ID_LANCAMENTO_CREATED,
	CHANGE_NOTA_ID
} from "../actions/types";

const INITIAL_STATE = {
	storeId: null,
	storeName: null,
	estabelecimentoId: 0,
	openAccordionSelected: "",
	estabelecimentos: null,
	lojas: [],
	dataModified: "",
	openComponent: false,
	openLancamento: { tipo: "", obj: {} },
	idLancamentoCreated: [],
	notaId: null
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
	case CHANGE_STORE:
		return { ...state, storeId: action.payload };
	case CHANGE_STORE_NAME:
		return { ...state, storeName: action.payload };
	case CHANGE_ESTABELECIMENTO:
		return { ...state, estabelecimentoId: action.payload };
	case CHANGE_ESTABELECIMENTOS:
		return { ...state, estabelecimentos: action.payload };
	case CHANGE_LOJAS:
		return { ...state, lojas: action.payload };
	case CHANGE_MODIFIED:
		return { ...state, dataModified: action.payload };
	case CHANGE_OPEN_ACCORDION_SELECTED:
		return { ...state, openAccordionSelected: action.payload };
	case CHANGE_OPEN_COMPONENT:
		return { ...state, openComponent: action.payload };
	case CHANGE_OPEN_LANCAMENTO:
		return { ...state, openLancamento: action.payload };
	case CHANGE_ID_LANCAMENTO_CREATED:
		return { ...state, idLancamentoCreated: action.payload };
	case CHANGE_NOTA_ID:
		return { ...state, notaId: action.payload };
	default:
		return state;
	}
};
