import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    WHITE_ESCRITA_THEME,
    BLACK_LABEL_UX,
    GRAY_BG_BODY,
    GRAY_STRONG_UX,
    TITLE_SIZE,
    GRAY_BG_UX,
    COLOR_LABEL_INPUT,
    BORDER_BUTTON,
    textCompleteStyle,
    textCompleteStyleBlack,
    handleDayMonthYear,
    format,
    BLUE_INFO_UX,
    RED_ERROR_UX,
} from "../../shared/utils";
import { showSnackMessage } from "../../actions/SnackActions";
import InputCurrency from "../Input/InputCurrency";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import api from "../../axios";
import DefaultButton from "../Buttons/DefaultButton";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InformarPagamentoModal = ({
    open,
    setOpen,
    baixaId,
    onConfirmInformarPagamento,
    setAction
}) => {
    const isLargeScreen = useMediaQuery("(min-width:1640px)");

    const [valorParcela, setValorParcela] = useState(0.0);
    const [dataPagamento, setDataPagamento] = useState(null);
    const [formaPagamento, setFormaPagamento] = useState("");
    const [conta, setConta] = useState({});
    const [valorPago, setValorPago] = useState(0.0);
    const [valorJuros, setValorJuros] = useState(0.0);
    const [valorMulta, setValorMulta] = useState(0.0);
    const [valorDesconto, setValorDesconto] = useState(0.0);
    const [valorTotalEmAberto, setValorTotalEmAberto] = useState(0.0);
    const [loading, setLoading] = useState(false);

    const [contasOptions, setContasOptions] = useState([]);

    const dispatch = useDispatch();
    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(
        (state) => state.StoreReducer.estabelecimentoId
    );

    const formaPagamentoOptions = [
        "Boleto via outros bancos",
        "Cashback",
        "Cheque",
        "Cartão de crédito via outros bancos",
        "Cartão de débito via outros bancos",
        "Carteira digital",
        "Crédito da loja",
        "Crédito virtual",
        "Débito Automático",
        "Depósito bancário",
        "Dinheiro",
        "Outros",
        "Pix",
        "Programa de fideliade",
        "Sem pagamento",
        "Transferência bancária",
        "Vale-alimentação",
        "Vale-combustível",
        "Vale-presente",
        "Vale-refeição",
    ];

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = () => {
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
        };
        let contas;
        api.RetrieveContas(dataRequest).then((response) => {
            contas = response.data;
            setContasOptions(contas);
        })
        .catch(() => {
            dispatch(
                showSnackMessage({
                    message: "Algo deu errado! Tente novamente mais tarde",
                    severity: "error",
                })
            );
        });

        dataRequest = {
            baixa_id: baixaId,
        };
        api.GetInformarPagamento(dataRequest).then((response) => {
            setValorParcela(response.data.valor_parcela);
            setDataPagamento(
                moment(response.data.data_pagamento, "YYYY-MM-DD").toDate()
            );
            setFormaPagamento(response.data.forma_pagamento);
            setConta(
                contasOptions.find((conta) => conta.value === response.data.conta)
            );
            setValorPago(response.data.valor_pago);
            setValorJuros(response.data.juros);
            setValorMulta(response.data.multa);
            setValorDesconto(response.data.desconto);
            setValorTotalEmAberto(response.data.valor_pago);
            setLoading(false);
        })
        .catch(() => {
            dispatch(
                showSnackMessage({
                    message: "Algo deu errado! Tente novamente mais tarde",
                    severity: "error",
                })
            );
        });
    };

    const postCreatePagamento = () => {
        let dataRequest = {
            baixa_id: baixaId,
            data_pagamento: moment(dataPagamento).format("YYYY-MM-DD"),
            forma_pagamento: formaPagamento,
            conta_id: conta.value,
            valor_pago: valorPago,
            juros: valorJuros,
            multa: valorMulta,
            desconto: valorDesconto,
        };
        api.PostInformarPagamento(dataRequest)
            .then(() => {
                onConfirmInformarPagamento();
                setOpen(false);
            })
            .catch(() => {
                dispatch(
                    showSnackMessage({
                        message: "Algo deu errado! Tente novamente mais tarde",
                        severity: "error",
                    })
                );
            });
    };


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => {setOpen(false); setAction(null);}}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_BODY,
                },
            }}
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                }}
            >
                <Toolbar>
                    <Typography
                        sx={{
                            color: temaEscuro
                                ? WHITE_ESCRITA_THEME
                                : GRAY_STRONG_UX,
                            fontSize: TITLE_SIZE,
                            fontWeight: "bold",
                            lineHeight: 1,
                            flex: 1,
                        }}
                    >
                        Informar pagamento
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span>
                                <p className="title-container-p">
                                    Informações do pagamento
                                </p>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    backgroundColor: temaEscuro
                                        ? BORDER_TABLE
                                        : BORDER_PAPER,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <span>
                                <p>
                                    Você pode fazer o pagamento total ou parcial
                                    do saldo da parcela. O valor restante ficará
                                    em aberto.
                                </p>
                            </span>
                        </Grid>
                        {loading ? (
                            <Skeleton variant="rectangular" height={200} />
                        ) : (
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <InputCurrency
                                            width={"100%"}
                                            title={"Valor (R$)"}
                                            valor={valorParcela}
                                            setValor={setValorParcela}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FilterDayMonthYear
                                            disableMinDate
                                            isRequired
                                            style={{
                                                backgroundColor:
                                                    temaEscuro && BLUE_THEME,
                                            }}
                                            handleSubmit={handleDayMonthYear}
                                            value={dataPagamento}
                                            setState={setDataPagamento}
                                            size={"small"}
                                            width={"100%"}
                                            iconPosition={"end"}
                                            label={"Data do pagamento"}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            size={"small"}
                                            options={formaPagamentoOptions}
                                            slotProps={{
                                                paper: {
                                                    style: {
                                                        backgroundColor:
                                                            temaEscuro
                                                                ? BLUE_THEME
                                                                : GRAY_BG_UX,
                                                        fontFamily:
                                                            "Inter, sans-serif",
                                                        color: temaEscuro
                                                            ? "#FFF"
                                                            : "#4A5568",
                                                        fontWeight: "500",
                                                    },
                                                },
                                            }}
                                            style={{
                                                width: "100%",
                                            }}
                                            value={formaPagamento}
                                            onChange={(event, newValue) =>
                                                setFormaPagamento(newValue)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        ...(temaEscuro
                                                            ? textCompleteStyleBlack
                                                            : textCompleteStyle),
                                                        backgroundColor:
                                                            temaEscuro
                                                                ? BLUE_THEME
                                                                : GRAY_BG_UX,
                                                        "& label": {
                                                            color: temaEscuro
                                                                ? COLOR_LABEL_INPUT
                                                                : BORDER_BUTTON,
                                                        },
                                                    }}
                                                    label={"Forma de pagamento"}
                                                    InputLabelProps={{
                                                        style: {
                                                            color:
                                                                temaEscuro &&
                                                                params
                                                                    .inputProps
                                                                    .value ===
                                                                    "" &&
                                                                BORDER_BUTTON,
                                                        },
                                                    }}
                                                    placeholder={
                                                        "Forma de pagamento"
                                                    }
                                                    required={false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            size={"small"}
                                            options={contasOptions}
                                            slotProps={{
                                                paper: {
                                                    style: {
                                                        backgroundColor:
                                                            temaEscuro
                                                                ? BLUE_THEME
                                                                : GRAY_BG_UX,
                                                        fontFamily:
                                                            "Inter, sans-serif",
                                                        color: temaEscuro
                                                            ? "#FFF"
                                                            : "#4A5568",
                                                        fontWeight: "500",
                                                    },
                                                },
                                            }}
                                            style={{
                                                width: "100%",
                                            }}
                                            value={conta}
                                            onChange={(event, newValue) =>
                                                setConta(newValue)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        ...(temaEscuro
                                                            ? textCompleteStyleBlack
                                                            : textCompleteStyle),
                                                        backgroundColor:
                                                            temaEscuro
                                                                ? BLUE_THEME
                                                                : GRAY_BG_UX,
                                                        "& label": {
                                                            color: temaEscuro
                                                                ? COLOR_LABEL_INPUT
                                                                : BORDER_BUTTON,
                                                        },
                                                    }}
                                                    label={"Conta"}
                                                    InputLabelProps={{
                                                        style: {
                                                            color:
                                                                temaEscuro &&
                                                                params
                                                                    .inputProps
                                                                    .value ===
                                                                    "" &&
                                                                BORDER_BUTTON,
                                                        },
                                                    }}
                                                    placeholder={"Conta"}
                                                    required={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputCurrency
                                            width={"100%"}
                                            title={"Valor pago (R$)"}
                                            valor={valorPago}
                                            setValor={setValorPago}
                                            max={valorTotalEmAberto}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputCurrency
                                            width={"100%"}
                                            title={"Juros (R$)"}
                                            valor={valorJuros}
                                            setValor={setValorJuros}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputCurrency
                                            width={"100%"}
                                            title={"Multa (R$)"}
                                            valor={valorMulta}
                                            setValor={setValorMulta}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputCurrency
                                            width={"100%"}
                                            title={"Desconto (R$)"}
                                            valor={valorDesconto}
                                            setValor={setValorDesconto}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <Stack direction="column">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={"15px"}
                                                    >
                                                        Valor em aberto
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "baseline",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={"15px"}
                                                        fontWeight={"bold"}
                                                        marginRight={"5px"}
                                                        sx={{color: RED_ERROR_UX}}
                                                    >
                                                        R$
                                                    </Typography>
                                                    <Typography
                                                        fontSize={"20px"}
                                                        fontWeight={"bold"}
                                                        sx={{color: RED_ERROR_UX}}
                                                    >
                                                        {format(
                                                            valorTotalEmAberto -
                                                                valorPago
                                                        )}
                                                    </Typography>
                                                </div>
                                            </Stack>
                                            <Stack
                                                direction="column"
                                                marginLeft={"20px"}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={"15px"}
                                                    >
                                                        Total Pago
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "baseline",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={"15px"}
                                                        fontWeight={"bold"}
                                                        marginRight={"5px"}
                                                        sx={{color: BLUE_INFO_UX}}
                                                    >
                                                        R$
                                                    </Typography>
                                                    <Typography
                                                        fontSize={"20px"}
                                                        fontWeight={"bold"}
                                                        sx={{color: BLUE_INFO_UX}}
                                                    >
                                                        {format(
                                                            valorPago
                                                        )}
                                                    </Typography>
                                                </div>
                                            </Stack>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </div>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderTop: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    top: "auto",
                    bottom: 0,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                }}
            >
                <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <DefaultButton
                        title={"Voltar"}
                        onClick={() => {setOpen(false); setAction(null);}}
                    />

                    <Grid sx={{ display: "flex" }}>
                        <DefaultButton
                            title={"Confirmar pagamento"}
                            disabled={!dataPagamento || !conta}
                            onClick={() => postCreatePagamento()}
                            borderRadius={{
                                borderRadius: "5px 0 0 5px",
                            }}
                            variant="yellow"
                        />
                    </Grid>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

export default InformarPagamentoModal;
