import React from "react";
import { useSelector } from "react-redux";
import { formatterCurrency, BLUE_THEME, YELLOW_BG_UX, GRAY_LABEL_UX_THEME } from "../../shared/utils";
import Grid from "@mui/material/Grid";
import BancoBrasil from "../../containers/Contas/LogoBancos/BB.png";
import Inter from "../../containers/Contas/LogoBancos/Inter.png";
import Bradesco from "../../containers/Contas/LogoBancos/Bradesco.png";
import Santander from "../../containers/Contas/LogoBancos/Santander.png";
import Sicoob from "../../containers/Contas/LogoBancos/Sicoob.png";
import Itau from "../../containers/Contas/LogoBancos/Itau.png";
import Checkbox from "@mui/material/Checkbox";


const BlocoLancamentoBanco = ({
    conciliacao,
    idx,
    handleIgnore,
    apelidoConta,
    setCheck,
}) => {
    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);

    const renderBanco = (bancoNome) => {
        const bancos = {
            "Banco do Brasil": BancoBrasil,
            Inter: Inter,
            Bradesco: Bradesco,
            Santander: Santander,
            Sicoob: Sicoob,
            Itaú: Itau,
        };

        if (bancos[bancoNome]) {
            return (
                <img
                    src={bancos[bancoNome]}
                    alt={bancoNome}
                    style={{ height: 40, width: 41 }}
                />
            );
        }

        return (
            <div
                style={{
                    height: "40px",
                    padding: "0px 10px",
                    backgroundColor: YELLOW_BG_UX,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                    borderRadius: "5px",
                }}
            >
                {bancoNome.toUpperCase()}
            </div>
        );
    };

    return (
        <Grid container spacing={2} data-testid="bloco-banco">
            {idx === 0 && (
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                        }}
                    >
                        <Grid item>
                            {renderBanco(conciliacao.extrato.banco_nome)}
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item xs={12}>
                                    <a
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Lançamentos importados
                                    </a>
                                </Grid>
                                <Grid item xs={12}>
                                    <a style={{ fontSize: "12px" }}>
                                        {apelidoConta}
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 4,
                }}
            >
                <Grid
                    container
                    sx={{
                        backgroundColor: temaEscuro
                            ? BLUE_THEME
                            : GRAY_LABEL_UX_THEME,
                        backgroundImage: temaEscuro && "none",
                        p: 3,
                        width: "100%",
                        height: 270.5,
                    }}
                >
                    <Grid item xs={1}>
                        <Checkbox
                            checked={conciliacao.check}
                            onChange={(event) => setCheck(idx, event.target.checked)}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={5.5}>
                        <Grid container>
                            <Grid item xs={12}>
                                <a
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {conciliacao.extrato.data_lancamento}
                                </a>
                            </Grid>
                            <Grid item xs={12}>
                                <a style={{ fontSize: "12px" }}>
                                    Segunda-feira
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={5.5}
                        sx={{ display: "flex", justifyContent: "end" }}
                    >
                        <a>
                            {formatterCurrency(
                                conciliacao.extrato.valor_lancamento,
                                2
                            )}
                        </a>
                    </Grid>
                    <Grid item xs={12}>
                        <a>{conciliacao.extrato.descricao}</a>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                        }}
                    >
                        <a
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => handleIgnore(conciliacao, idx)}
                        >
                            Ignorar
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BlocoLancamentoBanco;
