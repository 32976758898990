import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import {
    paperStyle,
    PAPER_PADDING_THEME,
    LINE_TABLE,
    BORDER_PAPER,
    BORDER_BLACK_THEME_CARD,
    BLUE_THEME,
    BORDER_BUTTON,
    RED_ERROR_UX,
    BORDER_TABLE,
    WHITE_ESCRITA_THEME,
    RED_INFO,
    GRAY_BG_UX, getCurrentDate, GRAY_STRONG_UX, TITLE_SIZE, GRAY_BG_BODY, BLACK_LABEL_UX,
    verifyRouterTransferencia,
    handleDayMonthYear,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import DefaultButton from "../../components/Buttons/DefaultButton";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import api from "../../axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import Anexos from "../../components/Tabs/Anexos";
import InputCurrency from "../../components/Input/InputCurrency";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { changeOpenLancamento} from "../../actions/StoreActions";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Transferencia = (props) => {

    const {
        setOpenCompletarTransfDialog=null,
        openCompletarTransfDialog=false,
        lancamento=null,
        indexConciliacao=null,
        handleAddMovimentacao=null,
        conta=null,
        contaPara=null
    } = props;

    const currentMonthDate = moment(getCurrentDate()).toDate();

    const dateParts = lancamento ? lancamento.data_lancamento.split("/") : null;
    const dataLancamento = dateParts ? moment(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`, "DD/MM/YYYY").toDate() : null;

    const dispatch = useDispatch();
    const nav = useNavigate();
    const { state } = useLocation();
    const isLargeScreen = useMediaQuery("(min-width:1440px)");
    const openLancamento = useSelector(state => state.StoreReducer.openLancamento);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(openCompletarTransfDialog === true ? openCompletarTransfDialog : (openLancamento === "transferencia"));
    const [allContas, setAllContas] = useState([]);
    const [contaOrigem, setContaOrigem] = useState("");
    const [contaDestino, setContaDestino] = useState("");
    const [valor, setValor] = useState(state?.valor || 0);
    const [dataTransferencia, setDataTransferencia] = useState(state?.data || currentMonthDate);
    const [descricao, setDescricao] = useState(state?.descricao || "");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [prevContaOrigem, setPrevContaOrigem] = useState("");
    const [prevContaDestino, setPrevContaDestino] = useState("");
    const [prevValor, setPrevValor] = useState(0);
    const [prevAnexos, setPrevAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [changesList, setChangesList] = useState([]);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useHotkeys("ctrl+z", () => {
        const change = changesList.pop();

        switch (change) {
            case "contaOrigem":
                setContaOrigem(prevContaOrigem);
                break;
            case "contaDestino":
                setContaDestino(prevContaDestino);
                break;
            case "valor":
                setValor(prevValor);
                break;
            case "anexos":
                setAnexos(prevAnexos);
                break;
            default:
                break;
        }
    });

    useEffect(() => {
      if (estabelecimentoId !== 0){
        getContas();
      }
    }, [estabelecimentoId]);

    
    useEffect(() => {
        if (contaOrigem !== null && contaDestino !== null){
            setDescricao(`Origem: ${contaOrigem} / Destino: ${contaDestino}`);
        }
    }, [contaOrigem, contaDestino, state]);

    useEffect(() => {
        if (dataLancamento) {
            setDataTransferencia(dataLancamento);
            setDescricao(lancamento.descricao);
            setValor(Math.abs(lancamento.valor_lancamento));
            setContaOrigem(conta.label);
            setContaDestino(contaPara.label);
        }
    }, [lancamento]);
    
    const closeModal = () => {
        setOpen(false);
        if (openCompletarTransfDialog) {
            setOpenCompletarTransfDialog(false);
        }
        dispatch(changeOpenLancamento(""));
    };

    const checkData = () => {
        if (contaOrigem === "" || contaDestino === ""){
            setError(true);
            setErrorMessage("As contas de origem e destino são obrigatórias");
        }
        else if (contaOrigem === contaDestino){
            setError(true);
            setErrorMessage("As contas de origem e destino não podem ser iguais");
        }
        else if (valor <= 0){
            setError(true);
            setErrorMessage("O valor da transferência deve ser maior que zero");
        }
        else if (dataTransferencia === ""){
            setError(true);
            setErrorMessage("A data de transferência é obrigatória");
        }
        else {
            setError(false);
            setErrorMessage("");
            postTranferencia();
        }
    };

    const getContas = () => {
        setLoading(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
        };

        api.GetContasTransferencia(dataRequest).then((response) => {
            setLoading(false);
            setAllContas(response.data);
        }).catch(() => {
            setLoading(false);
        });
    };

    const postTranferencia = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("conta_origem", contaOrigem);
        formData.append("conta_destino", contaDestino);
        formData.append("valor", valor);
        formData.append("data_transferencia", moment(dataTransferencia).format("DD/MM/YYYY"));
        formData.append("descricao", descricao);

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }

        api.PostTransferencia(formData).then((response) => {
            setLoading(false);
            closeModal();
            if (lancamento) {
                handleAddMovimentacao(indexConciliacao, lancamento.id, {
                    id: response.data.id,
                    apelido_conta: conta.label,
                    codigo_referencia: "Sem NFE",
                    data_movimentacao:
                        moment(dataTransferencia).format("DD/MM/YYYY"),
                    desconto: 0,
                    descricao: descricao,
                    nome_categoria: null,
                    nome_fornecedor: null,
                    tipo_movimentacao: "Transferência",
                    valor_total: valor,
                });
            }
            nav("/extrato_movimentacoes", {state: {reload: verifyRouterTransferencia()}});
        }).catch(() => {
            setLoading(false);
        });
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_BODY,
                },
            }}
            data-testid="transferencia-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? PAPER_PADDING_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        Nova Transferência entre contas
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    {
                        loading ? (
                            <Skeleton variant="rectangular" width="100%" height={"90vh"} />
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <span>
                                        <p className="title-container-p">
                                            Informações da gerais
                                        </p>
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : WHITE_ESCRITA_THEME}}/>
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex", alignItems: "start", gap: 2, flexWrap: "wrap", justifyContent: "start"}}>
                                    <FormControl size="small" sx={{width: "250px"}} required>
                                        <InputLabel 
                                            id="demo-simple-select-label"
                                            sx={{ 
                                                color: temaEscuro && BORDER_BUTTON, fontSize: "15px",
                                                "& .MuiFormLabel-asterisk": {
                                                    color: RED_INFO,
                                                }
                                            }}
                                        >
                                            Conta de origem
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Conta de origem"
                                            value={contaOrigem}
                                            sx={{
                                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                width: "250px",
                                                "& .MuiInputBase-input": {
                                                    color: temaEscuro && WHITE_ESCRITA_THEME,
                                                },
                                            }}
                                            onChange={(e) => {
                                                setChangesList([...changesList, "contaOrigem"]);
                                                setPrevContaOrigem(contaOrigem);
                                                setContaOrigem(e.target.value);
                                            }}
                                            data-testid="select-conta-origem"
                                        >
                                            {allContas.map((cv) => {
                                                return (
                                                    <MenuItem data-testid="menu-item" key={cv} value={cv}>
                                                        {cv}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl size="small" sx={{width: "250px"}} required>
                                        <InputLabel 
                                            id="demo-simple-select-label"
                                            sx={{ 
                                                color: temaEscuro && BORDER_BUTTON, fontSize: "15px",
                                                "& .MuiFormLabel-asterisk": {
                                                    color: RED_INFO,
                                                }
                                            }}
                                        >
                                            Conta de destino
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Conta de destino"
                                            value={contaDestino}
                                            sx={{
                                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                                width: "250px",
                                                "& .MuiInputBase-input": {
                                                    color: temaEscuro && WHITE_ESCRITA_THEME,
                                                },
                                            }}
                                            data-testid="select-conta-destino"
                                            onChange={(e) => {
                                                setChangesList([...changesList, "contaDestino"]);
                                                setPrevContaDestino(contaDestino);
                                                setContaDestino(e.target.value);
                                            }}
                                        >
                                            {allContas.map((cv) => {
                                                return (
                                                    <MenuItem data-testid="menu-item" key={cv} value={cv}>
                                                        {cv}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    <InputCurrency
                                        valor={valor}
                                        setValor={setValor}
                                        setPrevValor={setPrevValor}
                                        changesList={changesList}
                                        setChangesList={setChangesList}
                                        title="Saldo"
                                        dataTestId="input-faturamento-bruto"
                                    />
                                    <FilterDayMonthYear
                                        style={{backgroundColor: temaEscuro && BLUE_THEME, ...styles.textStyle}}
                                        handleSubmit={handleDayMonthYear}
                                        setState={setDataTransferencia}
                                        value={dataTransferencia}
                                        size={"small"}
                                        width={"266px"}
                                        iconPosition={"end"}
                                        label={"Data de transferência"}
                                        isContaPoupanca
                                        testId="data-transferencia"
                                    />
                                    <TextField
                                        required
                                        label = "Descrição"
                                        value={descricao}
                                        placeholder="Descrição da transferência"
                                        sx={{...styles.textStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "266px",
                                            "& .MuiFormLabel-asterisk": {
                                                    color: RED_INFO,
                                            }
                                            }}
                                        onChange={(event) => {
                                            setDescricao(event.target.value);
                                        }}
                                        InputLabelProps={{
                                            style: {color: temaEscuro && BORDER_BUTTON},
                                        }}
                                        multiline
                                        rows={2}
                                        size="small"
                                        data-testid="observacoes-textfield"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: 2}}>
                                    <span><p className="title-container-p">Anexos</p></span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : WHITE_ESCRITA_THEME}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Anexos
                                        anexos={anexos}
                                        setAnexos={setAnexos}
                                        changesList={changesList}
                                        setChangesList={setChangesList}
                                        setPrevAnexos={setPrevAnexos}
                                        isTransferencia
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex", gap: 2, alignItems: "center", justifyContent: "end", marginTop: 3}}>
                                    <DefaultButton
                                        title="Voltar"
                                        onClick={() => dispatch(changeOpenLancamento(""))}
                                        testId="voltar-button"
                                    />
                                    <DefaultButton
                                        disabled={loading || contaOrigem === "" || contaDestino === "" || valor === 0 || dataTransferencia === ""}
                                        title="Salvar"
                                        onClick={checkData}
                                        testId="salvar-button"
                                        variant="yellow"
                                    />
                                </Grid>
                                {
                                    error && (
                                        <Grid item xs={12} sx={{fontSize: "14px", color: RED_ERROR_UX, display: "flex", alignItems: "center", justifyContent: "end", mt: 1}}>
                                            {errorMessage}
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Paper>
            </div>
        </Dialog>
    );
};

const styles = {
    textStyle: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "28px"
    },
    numberInputStyle: {
        margin: 0,
        padding: "0 0 0 .7rem",
        width: "266px",
        height: "38px",
        borderRadius: "4px"
    },
};

export default Transferencia;