import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "./DefaultButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";
import EdicaoLoteModal from "../Modal/EdicaoLoteModal";
import { Tooltip } from "@mui/material";


export default function OptionsEXMSelectedItems(props) {

    const estabelecimentoID = useSelector(state => state.StoreReducer.estabelecimentoId);

    const {data, exmItems, getExtratoMovimentacoes, deleteMovimentacoesLote, tab, disabled} = props;

    const [isSameType, setIsSameType] = useState(false);
    const [canEditConta, setCanEditConta] = useState(false);
    const [canEditPrevisao, setCanEditPrevisao] = useState(false);
    const [movimentacaoType, setMovimentacaoType] = useState("");
    const [openEdicaoModal, setOpenEdicaoModal] = useState(false);
    
    useEffect(() => {
        let selectedItems = data.filter(item => exmItems.includes(item.id));
        if (selectedItems.length !== 0) {
            setMovimentacaoType(selectedItems[0].tipo_movimentacao);

            let itemTypes = selectedItems.every(item => (item.tipo_movimentacao === selectedItems[0].tipo_movimentacao) && (selectedItems[0].situacao !== "Transferido"));
            setIsSameType(itemTypes);
            
            let canEditConta = selectedItems.every(item => (item.situacao !== "Transferido") && (item.conciliado !== true) && (!item.categoria_nome.includes("Saldo inicial")));
            setCanEditConta(canEditConta);
            
            let canEditPrevisao = selectedItems.every(item => (item.situacao !== "Transferido") && (item.conciliado !== true) && (!item.categoria_nome.includes("Saldo inicial")) && (item.situacao !== "Pago") && (item.situacao !== "Recebido"));
            setCanEditPrevisao(canEditPrevisao);
        }
    }, [exmItems]);

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);

    const updateMovimentacaoType = (type) => {
        const dataRequest = {
            selected_movimentacoes: exmItems,
            type: type,
            estabelecimento_id: estabelecimentoID,
            tab: tab,
        };

        api.UpdateTypeMovimentacoes(dataRequest).then(response => {
            getExtratoMovimentacoes();
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({message: "Não foi possível atualizar as movimentações selecionadas.", severity: "error"}));
        });
    };


    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <DefaultButton
                title={exmItems.length > 1 ? "Ações em lote" : "Ações"}
                startIcon={moreVertIcon}
                onClick={handleClick}
                testId="options-menu-acoes"
                className={props.className}
                disabled={disabled}
                size="small"
            />
                <EdicaoLoteModal
                    openModal={openEdicaoModal}
                    setOpenModal={setOpenEdicaoModal}
                    exmItems={exmItems}
                    getExtratoMovimentacoes={getExtratoMovimentacoes}
                    movimentacaoType={movimentacaoType}
                    isEXM
                    selectedTab={tab}
                />
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        onClick={() => deleteMovimentacoesLote()}
                    >
                        Excluir
                    </MenuItem>
                    {
                        isSameType && (
                            <React.Fragment>
                                <MenuItem
                                    onClick={() => setOpenEdicaoModal(true)}
                                >
                                    Editar
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Pago")}
                                >
                                    Marcar como Pago
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Em Aberto")}
                                >
                                    Marcar Em Aberto
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Agendado")}
                                >
                                    Marcar como Agendado
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Não Agendado")}
                                >
                                    Desmarcar como Agendado
                                </MenuItem>
                                {
                                    tab !== "visao_competencia" && (
                                        <React.Fragment>
                                            {
                                                !canEditConta ? (
                                                    <Tooltip
                                                        title="Apenas movimentações não conciliadas e não transferidas podem ser alteradas."
                                                        arrow
                                                        sx={{
                                                            "& .MuiPopper-root": {
                                                                backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                            }
                                                        }}
                                                    >
                                                        <span>
                                                            <MenuItem
                                                                disabled
                                                                onClick={() => props?.handleEditEmLoteModal("conta")}
                                                            >
                                                                Alterar conta
                                                            </MenuItem>
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <MenuItem
                                                        onClick={() => props?.handleEditEmLoteModal("conta")}
                                                    >
                                                        Alterar conta
                                                    </MenuItem>
                                                )
                                            }
                                            {
                                                !canEditPrevisao ? (
                                                    <Tooltip
                                                        title="Apenas movimentações não conciliadas, não transferidas e Em Aberto ou Em Atraso podem ser alteradas."
                                                        arrow
                                                        sx={{
                                                            "& .MuiPopper-root": {
                                                                backgroundColor: temaEscuro && BLACK_TABLE_THEME
                                                            }
                                                        }}
                                                    >
                                                        <span>
                                                            <MenuItem
                                                                disabled
                                                                onClick={() => props?.handleEditEmLoteModal("vencimento")}
                                                            >
                                                                {tab === "movimentacoes" ? "Alterar data prevista" : "Alterar vencimento e/ou previsão"}
                                                            </MenuItem>
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <MenuItem
                                                        onClick={() => props?.handleEditEmLoteModal("vencimento")}
                                                    >
                                                        {tab === "movimentacoes" ? "Alterar data prevista" : "Alterar vencimento e/ou previsão"}
                                                    </MenuItem>
                                                )
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </Menu>
        </div>
    );
}