import {
	CHANGE_STORE,
	CHANGE_MODIFIED,
	CHANGE_ESTABELECIMENTO,
	CHANGE_OPEN_ACCORDION_SELECTED,
	CHANGE_ESTABELECIMENTOS,
	CHANGE_LOJAS,
	CHANGE_STORE_NAME,
	CHANGE_OPEN_COMPONENT,
	CHANGE_OPEN_LANCAMENTO,
	CHANGE_ID_LANCAMENTO_CREATED,
	CHANGE_NOTA_ID
} from "./types";

export const changeStore = (storeId) => {
	return {
		type: CHANGE_STORE,
		payload: storeId
	};
};

export const changeStoreName = (storeName) => {
	return {
		type: CHANGE_STORE_NAME,
		payload: storeName
	};
};

export const changeEstabelecimento = (estabelecimentoId) => {
	return {
		type: CHANGE_ESTABELECIMENTO,
		payload: estabelecimentoId
	};
};

export const changeEstabelecimentos = (estalecimentos) => {
	return {
		type: CHANGE_ESTABELECIMENTOS,
		payload: estalecimentos
	};
};

export const changeLojas = (lojas) => {
	return {
		type: CHANGE_LOJAS,
		payload: lojas
	};
};

export const changeModified = (dataModified) => {
	return {
		type: CHANGE_MODIFIED,
		payload: dataModified
	};
};

export const changeOpenAccordionSelected = (selected) => {
	return {
		type: CHANGE_OPEN_ACCORDION_SELECTED,
		payload: selected
	};
};

export const changeOpenComponent = (open) => {
	return {
		type: CHANGE_OPEN_COMPONENT,
		payload: open
	};
};

export const changeOpenLancamento = (local) => {
	return {
		type: CHANGE_OPEN_LANCAMENTO,
		payload: local
	};
};

export const changeIdLancamentoCreated = (ids) => {
	return {
		type: CHANGE_ID_LANCAMENTO_CREATED,
		payload: ids
	};
};

export const changeNotaId = (nota_id) => {
	return {
		type: CHANGE_NOTA_ID,
		payload: nota_id
	};
};