import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
    DASH_KPI_NEGATIVE_COLOR,
    DASH_KPI_POSITIVE_COLOR,
    GRAY_LABEL_UX,
    styleText,
    WHITE_ESCRITA_THEME,
    YELLOW_BG_UX,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import InputSearch from "../Input/InputSearch";
import BuscarLancamentos from "../../containers/Contas/BuscarLancamentos/BuscarLancamentos";
import BlocoConciliacao from "./BlocoConciliacao";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConciliacaoContaKPI from "../Dash/ConciliacaoContaKPI";
import DefaultButton from "../Buttons/DefaultButton";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";


const ConciliacoesConta = (props) => {

    const dispatch = useDispatch();
    const {
        conta,
        contasOptions,
        periodo,
        arrowCounter,
        customPeriodo,
        getValorPendente,
        getSaldoAtual,
        getDetalhesConta,
        throwDispatchErrorMessage
    } = props;

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [todosKPI, setTodosKPI] = useState(0);
    const [recebidosKPI, setRecebidosKPI] = useState(0);
    const [pagamentosKPI, setPagamentosKPI] = useState(0);
    const [selectedKPI, setSelectedKPI] = useState("todos");
    const [conciliacoesConta, setConciliacoesConta] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoriasOptions, setCategoriasOptions] = useState([]);
    const [openModalBuscarLanc, setOpenModalBuscarLanc] = useState(false);

    const [selectedLancamento, setSelectedLancamento] = useState(null);

    const [maxLoadConciliacoes, setMaxLoadConciliacoes] = useState(20);
    const [numberCheckedConciliacoes, setNumberCheckedConciliacoes] = useState(0);
    const [allChecked, setAllChecked] = useState(false);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        const isCustomPeriodoValid = customPeriodo[0] !== null && customPeriodo[1] !== null;
        const shouldFetchConciliacoes =
            estabelecimentoId !== 0 &&
            (
                (periodo === "custom_periodo" && isCustomPeriodoValid) ||
                (periodo !== "custom_periodo")
            );

        if (shouldFetchConciliacoes) {
            getConciliacoesConta();
        }
    }, [periodo, arrowCounter, customPeriodo, selectedKPI]);

    useEffect(() => {
        setNumberCheckedConciliacoes(conciliacoesConta.filter((conciliacao) => conciliacao.check).length);
    }, [conciliacoesConta]);

    useEffect(() => {
        api.GetCategorias({estabelecimento_id: estabelecimentoId}).then(response => {
            setCategoriasOptions(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    }, []);

    useEffect(() => {
        setConciliacoesConta(
            conciliacoesConta.map((conciliacao, idx) => {
                if (idx < maxLoadConciliacoes)
                    conciliacao.check = allChecked;
                return conciliacao;
            })
        );
    }, [allChecked]);

    const getConciliacoesConta = (clear = false) => {
        if (!conta) {
            return;
        }
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: conta.value,
            periodo: periodo,
            plus_minus: arrowCounter,
            selected_kpi: selectedKPI,
        };
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        if (searchValue !== "" && !clear) {
            dataRequest.search_value = searchValue;
        }
        api.GetConciliacaoConta(dataRequest).then(response => {
            let data = response.data;
            if (!data) {
                dispatch(showSnackMessage({ message: "Nenhuma conciliação a ser feita para esta data!", severity: "warning" }));
                return;
            }
            setConciliacoesConta(data.conciliacao);
            setTodosKPI(data.total_kpi);
            setRecebidosKPI(data.recebidos_kpi);
            setPagamentosKPI(data.pagamentos_kpi);
            
            setLoading(false);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
            setLoading(false);
        });
    };

    const handleConciliar = (extratoId, movimentacoesIds) => {
        setLoading(true);
        let dataRequest = {
            extrato_id: extratoId,
            movimentacoes_ids: movimentacoesIds,
            conta: conta,
        };

        api.conciliarLancamentos(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Conciliação realizada com sucesso!", severity: "success" }));
            getConciliacoesConta();
            getValorPendente();
            getDetalhesConta(true);
        }).catch((error) => {
            setLoading(false);
            if (error.response.status === 403) {
                dispatch(showSnackMessage({message: "Atenção! Você não possui permissão para esta ação.", severity: "info"}));
            } else {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            }
        });

    };


    const handleSingleCriarConciliar = (extratoId, tipoMovimentacao, contaDestino, categoria, descricao) => {
        setLoading(true);
        let dataRequest = {
            conciliacao: [
                {
                    extrato_id: extratoId,
                    movimentacao_id: null,
                    descricao: descricao,
                    selected_mov_type: tipoMovimentacao,
                    selected_conta_destino: contaDestino,
                    selected_categoria: categoria,
                },
            ]
        };
        api.criarConciliarLancamento(dataRequest).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Conciliação criada com sucesso!", severity: "success" }));
            getConciliacoesConta();
            getValorPendente();
            getSaldoAtual();
            getDetalhesConta(true);
        }).catch((error) => {
            if (error.response.status === 403) {
                dispatch(showSnackMessage({message: "Atenção! Você não possui permissão para esta ação.", severity: "info"}));
            } else {
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            }
        });
    };

    const handleBatchCriarConciliar = () => {
        setLoading(true);

        const isInvalid = conciliacoesConta
            .filter((item) => item.check)
            .some((item) => {
                return (
                    (item.tipo === "movimentacao" && item.categoria === null) ||
                    (item.tipo === "transferencia" && item.conta_destino === null)
                );
            });
        if (isInvalid) {
            setLoading(false);
            dispatch(showSnackMessage({ message:"Por favor, preencha todos os campos antes de conciliar!", severity: "info"}));
            return;
        }

        const dataRequest = conciliacoesConta.filter((item) => item.check).map((item) => {
            return {
                extrato_id: item.extrato.id,
                movimentacao_id: item.movimentacao ? item.movimentacao.id : null,
                descricao: item.descricao,
                selected_mov_type: item.tipo,
                selected_conta_destino: item.conta_destino?.value,
                selected_categoria: item.categoria?.value,
            };
        });
        api.criarConciliarLancamento({conciliacao: dataRequest}).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({ message: "Conciliação criada com sucesso!", severity: "success" }));
            getConciliacoesConta();
            setAllChecked(false);
            getValorPendente();
            getSaldoAtual();
            getDetalhesConta(true);
        }).catch((error) => {
            if (error.response.status === 403) {
                setLoading(false);
                dispatch(showSnackMessage({message: "Atenção! Você não possui permissão para esta ação.", severity: "info"}));
            } else {
                setLoading(false);
                dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
            }
        });
    };

    const handleRemoveRow = (conciliacao, idx) => {
        setLoading(true);
        api.ignoreLancamento({extrato_id: conciliacao.extrato.id}).then(() => {
            setConciliacoesConta(
                conciliacoesConta.filter((item, index) => index !== idx)
            );
            setTodosKPI(todosKPI - 1);
            if (conciliacao.extrato.valor_lancamento > 0) {
                setRecebidosKPI(recebidosKPI - 1);
            }
            if (conciliacao.extrato.valor_lancamento < 0) {
                setPagamentosKPI(pagamentosKPI - 1);
            }
            setLoading(false);
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
            setLoading(false);
        });
    };

    const handleAddMovimentacao = (index, extratoId, movimentacao) => {
        setConciliacoesConta(
            conciliacoesConta.map((conciliacao, idx) => {
                if (idx === index) {
                    conciliacao.movimentacao = movimentacao;
                }
                return conciliacao;
            }
        ));
        getSaldoAtual();
    };

    const handleUnlinkMovimentacao = (idx, unlinkAll = false) => {
        setConciliacoesConta(
            conciliacoesConta.map((conciliacao, index) => {
                if (index === idx || unlinkAll) {
                    conciliacao.movimentacao = null;
                }
                return conciliacao;
            })
        );
    };

    const handleClearFilters = () => {
        setSearchValue("");
        getConciliacoesConta(true);
    };

    const redirctToIgnorados = () => {
        navigate("/ignorados", {state: { conta_id: conta.value }});
    };
    
    const handleLoadMoreConciliacoes = () => {
        setMaxLoadConciliacoes(maxLoadConciliacoes + 20);
    };

    const setCategoria = (index, categoria) => {
        setConciliacoesConta((prevConciliacoes) =>
            prevConciliacoes.map((conciliacao, idx) =>
                idx === index ? { ...conciliacao, categoria: categoria } : conciliacao
            )
        );
    };

    const setConta = (index, conta) => {
        setConciliacoesConta((prevConciliacoes) =>
            prevConciliacoes.map((conciliacao, idx) =>
                idx === index ? { ...conciliacao, conta_destino: conta } : conciliacao
            )
        );
    };

    const setTipo = (index, tipo) => {
        setConciliacoesConta((prevConciliacoes) =>
            prevConciliacoes.map((conciliacao, idx) =>
                idx === index ? { ...conciliacao, tipo: tipo } : conciliacao
            )
        );
    };

    const setDescricao = (index, descricao) => {
        setConciliacoesConta((prevConciliacoes) =>
            prevConciliacoes.map((conciliacao, idx) =>
                idx === index ? { ...conciliacao, descricao: descricao } : conciliacao
            )
        );
    };

    const setCheck = (index, check) => {
        setConciliacoesConta((prevConciliacoes) =>
            prevConciliacoes.map((conciliacao, idx) =>
                idx === index ? { ...conciliacao, check: check } : conciliacao
            )
        );
    };

    return (
        <React.Fragment>
            {openModalBuscarLanc && selectedLancamento && (
            <BuscarLancamentos
                lancamento={selectedLancamento}
                conta={conta}
                contas={contasOptions}
                openModalBuscarLanc={openModalBuscarLanc}
                setOpenModalBuscarLanc={setOpenModalBuscarLanc}
                getConciliacoesConta={getConciliacoesConta}
            />)}
            <Grid container spacing={7} data-testid="conciliacoes-container">
                <Grid item xs={12}>
                    <Grid container rowSpacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <InputSearch
                                        callFunction
                                        EXM
                                        functionProps={getConciliacoesConta}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        label={"Pesquisar"}
                                    />
                                </Grid>
                                <Grid item sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                                    <DeleteForeverIcon sx={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}/>
                                    <a style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}} onClick={() => handleClearFilters()}>Limpar filtros</a>
                                </Grid>
                                <Grid item sx={{marginLeft: "auto"}}>
                                    <DefaultButton
                                        onClick={conta ? () => redirctToIgnorados() : throwDispatchErrorMessage}
                                        title="Ver lançamentos ignorados"
                                        testId="btn-ignorados"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{mb: 6}}>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <ConciliacaoContaKPI
                                        title={"Todos"}
                                        data={todosKPI}
                                        handleClick={() => setSelectedKPI("todos")}
                                        selectedKPI={selectedKPI === "todos"}
                                        color={YELLOW_BG_UX}
                                        testId="todos-kpi"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ConciliacaoContaKPI
                                        title={"Recebidos"}
                                        data={recebidosKPI}
                                        handleClick={() => setSelectedKPI("recebidos")}
                                        selectedKPI={selectedKPI === "recebidos"}
                                        color={DASH_KPI_POSITIVE_COLOR}
                                        testId="todos-recebidos"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ConciliacaoContaKPI
                                        title={"Pagamentos"}
                                        data={pagamentosKPI}
                                        handleClick={() => setSelectedKPI("pagamentos")}
                                        selectedKPI={selectedKPI === "pagamentos"}
                                        color={DASH_KPI_NEGATIVE_COLOR}
                                        testId="todos-pagamentos"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid 
                            item 
                            xs={12} 
                            sx={{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX,
                                fontWeight: "500",
                                backgroundColor: "transparent",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: 2,
                                border: temaEscuro ? `1px solid ${YELLOW_BG_UX}` : `2px solid ${YELLOW_BG_UX}`,
                                paddingTop: "0px !important",
                            }}
                        >
                                <Checkbox
                                    checked={allChecked}
                                    onChange={(event) => setAllChecked(event.target.checked)}
                                    color="primary"
                                />
                                <span>{numberCheckedConciliacoes} recebimento(s) selecionado(s)</span>
                                <Box sx={{ display: "flex", gap: 2, ml: 2 }}>
                                    <DefaultButton
                                        onClick={() => handleBatchCriarConciliar()}
                                        title="Conciliar"
                                        testId="conciliar-lote-button"
                                        size="small"
                                        disabled={numberCheckedConciliacoes === 0}
                                        sx={{ ml: 2 }}
                                        variant="yellow"
                                    />
                                    <DefaultButton
                                        onClick={() => handleUnlinkMovimentacao(null, true)}
                                        title="Desvincular todos"
                                        testId="desvincular-todos-button"
                                        size="small"
                                        disabled={numberCheckedConciliacoes === 0}
                                        sx={{ ml: 2 }}
                                        variant="blue"
                                    />
                                </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{paddingTop: "20px !important"}}>
                {loading ? (
                        <Grid item xs={12}>
                            <Skeleton
                                variant="rectangular"
                                height={"500px"}
                                width="100%"
                            />
                        </Grid>
                    ) : (
                        <Grid container spacing={1}>
                            {conciliacoesConta
                                .slice(0, maxLoadConciliacoes)
                                .map((conciliacao, index) => {
                                    return (
                                        <BlocoConciliacao
                                            key={index}
                                            conciliacao={conciliacao}
                                            index={index}
                                            handleRemoveRow={handleRemoveRow}
                                            conta={conta}
                                            categoriasOptions={
                                                categoriasOptions
                                            }
                                            contasOptions={contasOptions}
                                            setCategoria={setCategoria}
                                            setConta={setConta}
                                            setTipo={setTipo}
                                            setDescricao={setDescricao}
                                            setCheck={setCheck}
                                            setSelectedLancamento={
                                                setSelectedLancamento
                                            }
                                            setOpenModalBuscarLanc={
                                                setOpenModalBuscarLanc
                                            }
                                            handleCriarConciliar={
                                                handleSingleCriarConciliar
                                            }
                                            handleConciliar={handleConciliar}
                                            handleAddMovimentacao={
                                                handleAddMovimentacao
                                            }
                                            handleUnlinkMovimentacao={
                                                handleUnlinkMovimentacao
                                            }
                                            allChecked={allChecked}
                                        />
                                    );
                                })}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <DefaultButton
                        onClick={handleLoadMoreConciliacoes}
                        title="Carregar mais"
                        testId="carregar-mais-button"
                        disabled={
                            loading ||
                            maxLoadConciliacoes >=
                                conciliacoesConta.length
                        }
                    />
                </div>
            </Grid>

        </React.Fragment>
    );
};

export default ConciliacoesConta;