import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import BlocoLancamentoBanco from "./BlocoLancamentoBanco";
import BlocoLancamentoVXMF from "./BlocoLancamentoVXMF";
import ConfirmDialog from "../Modal/ConfirmDialog";
import DefaultButton from "../Buttons/DefaultButton";
import Movimentacao from "../../containers/EXM/Movimentacao";
import Transferecia from "../../containers/EXM/Transferencia";

const BlocoConciliacao = ({
    conciliacao,
    index,
    handleRemoveRow,
    handleCriarConciliar,
    handleConciliar,
    handleAddMovimentacao,
    conta,
    categoriasOptions,
    contasOptions,
    setCategoria,
    setConta,
    setTipo,
    setDescricao,
    setCheck,
    setSelectedLancamento,
    setOpenModalBuscarLanc,
    handleUnlinkMovimentacao,
}) => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openCompletarInfoDialog, setOpenCompletarInfoDialog] = useState(false);
    const [openCompletarTransfDialog, setOpenCompletarTransfDialog] = useState(false);

    const handleCompletarDialog = () => {
        if (conciliacao.tipo === "movimentacao") {
            setOpenCompletarInfoDialog(true);
        } else {
            setOpenCompletarTransfDialog(true);
        }
    };

    return (
        <React.Fragment key={index}>
            <ConfirmDialog
                title="Criar novo lançamento e conciliar"
                description="Um novo lançamento será criado no VXMF e será conciliado. "
                buttonTitle="Criar e conciliar"
                callback={() => {
                    handleCriarConciliar(
                        conciliacao.extrato.id,
                        conciliacao.tipo,
                        conciliacao.conta_destino?.value,
                        conciliacao.categoria?.value,
                        conciliacao.descricao
                    );
                    setOpenConfirmDialog(false);
                }}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
            />
            {openCompletarInfoDialog && (
                <Movimentacao 
                    type={conciliacao.extrato.valor_lancamento > 0 ? "receita" : "despesa"}
                    lancamento={conciliacao.extrato}
                    conta={conta}
                    categoria={conciliacao.categoria}
                    indexConciliacao={index}
                    handleAddMovimentacao={handleAddMovimentacao}
                    openCompletarInfoDialog={openCompletarInfoDialog}
                    setOpenCompletarInfoDialog={setOpenCompletarInfoDialog}
                />
            )}
            {openCompletarTransfDialog && (
                <Transferecia
                    openCompletarTransfDialog={openCompletarTransfDialog}
                    setOpenCompletarTransfDialog={setOpenCompletarTransfDialog}
                    lancamento={conciliacao.extrato}
                    conta={conta}
                    contaPara={conciliacao.conta}
                    indexConciliacao={index}
                    handleAddMovimentacao={handleAddMovimentacao}
                />
            )}
            <Grid
                item
                xs={5}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <BlocoLancamentoBanco
                    conciliacao={conciliacao}
                    idx={index}
                    handleIgnore={handleRemoveRow}
                    apelidoConta={conta.label}
                    setCheck={setCheck}
                />
            </Grid>
            <Grid
                item
                xs={1.5}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <DefaultButton
                    title={"Conciliar"}
                    size="small"
                    onClick={() =>
                        conciliacao.movimentacao === null
                            ? setOpenConfirmDialog(true)
                            : handleConciliar(conciliacao.extrato.id, [
                                  conciliacao.movimentacao.id,
                              ])
                    }
                    disabled={
                        (conciliacao.tipo === "movimentacao"
                            ? !conciliacao.categoria
                            : !conciliacao.conta_destino) &&
                        conciliacao.movimentacao === null
                    }
                    testId="btn-conciliar"
                    
                    variant="yellow"
                />
            </Grid>
            <Grid item xs={5.5}>
                <BlocoLancamentoVXMF
                    conciliacao={conciliacao}
                    idx={index}
                    categorias={categoriasOptions}
                    contas={contasOptions}
                    setSelectedLancamento={setSelectedLancamento}
                    setOpenModalBuscarLanc={setOpenModalBuscarLanc}
                    setCategoria={setCategoria}
                    setConta={setConta}
                    setTipo={setTipo}
                    setDescricao={setDescricao}
                    handleCompletarInfo={handleCompletarDialog}
                    handleUnlinkMovimentacao={handleUnlinkMovimentacao}
                />
            </Grid>
        </React.Fragment>
    );
};

export default BlocoConciliacao;
