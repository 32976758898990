import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
    BLUE_THEME, BORDER_BLACK_THEME_CARD, BORDER_BUTTON, fileTypesAccepted, GRAY_BORDER_UX,
    GRAY_PDF,
    GRAY_STRONG_UX,
    LINE_TABLE,
    ROW_MAIN,
    WHITE_TABLE,
    WHITE_THEME_BLACK
} from "../../shared/utils";
import FilterSingleOption from "../Filter/FilterSingleOption";
import DefaultButton from "../Buttons/DefaultButton";
import InputSearch from "../Input/InputSearch";
import AnexoPreviewModal from "../Modal/AnexoPreviewModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import AnexoUploaderModal from "../Modal/AnexoUploaderModal";
import { FileUploader } from "react-drag-drop-files";
import { Paper, Skeleton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DragAndDrop from "../DragAndDrop/DragAndDrop";


const Anexos = (props) => {
    const {
        anexos,
        setAnexos,
        isTransferencia,
        setPrevAnexos = () => {},
        setChangesList = () => {},
        changesList = [],
        loading = false
    } = props;
    const [openModal, setOpenModal] = useState(false);
    const [openUploaderModal, setOpenUploaderModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [multipleAnexos, setMultipleAnexos] = useState(false);
    const [filesList, setFilesList] = useState([]);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (filesList.length > 0) {
            setMultipleAnexos(false);
            setAnexos(filesList.map((file) => ({
                forma_anexo: "Arquivo",
                arquivo: file,
                tipo_anexo: "Contrato",
                descricao: "",
            })));
        }
    }, [filesList]);

    useEffect(() => {
        if (selectedFile !== null) {
            setMultipleAnexos(false);
        }
    }, [selectedFile]);

    const handleClick = () => {
        setOpenUploaderModal(true);
    };

    const nullifyFile = (row, idx) => {
        setAnexos(anexos.map((anexo_aux, idx_aux) => {
            if (idx_aux === idx) {
                anexo_aux.arquivo = null;
            }
            return anexo_aux;
        }));
    };

    const handleRemoveAnexo = (idx) => {
        anexos.splice(idx, 1);
        setAnexos([...anexos]);
    };

    return (
        <React.Fragment>
            <AnexoPreviewModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                file={selectedFile}
            />
            <AnexoUploaderModal
                openModal={openUploaderModal}
                setOpenModal={setOpenUploaderModal}
                setAnexos={setAnexos}
                changesList={changesList}
                setChangesList={setChangesList}
                anexos={anexos}
                setPrevAnexos={setPrevAnexos}
            />
            <Grid data-testid="anexos-container" container spacing={isTransferencia ? 0 : 2} sx={{ml: isTransferencia ? 0 : 0.5}}>
                {
                    loading ? (
                        <Skeleton
                            sx={{height: "140px", width: "100%", marginLeft: ".8rem"}}
                        />
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12} sx={{padding: "2rem"}}>
                                { multipleAnexos && (
                                    <FileUploader
                                        hoverTitle="Arraste os anexos até aqui"
                                        handleChange={(anexos_event) => {
                                            setFilesList(Array.from(anexos_event));
                                            setMultipleAnexos(false);
                                        }}
                                        multiple={true}
                                        maxSize={10}
                                        types={fileTypesAccepted}
                                    >
                                        <Grid container sx={{display: "flex", gap: 2, cursor: "pointer"}}>
                                            <Paper sx={{width: "30vw",p: ".5rem 3rem .5rem .5rem", display: "flex", boxShadow: "none", backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: temaEscuro ? `2px dashed ${BORDER_BLACK_THEME_CARD}` : `1px dashed ${BORDER_BLACK_THEME_CARD}`, mb: "2rem"}}>
                                                <p style={{margin: 0, color: temaEscuro && BORDER_BUTTON}}>Escolha os anexos ou arraste-os para este espaço</p>
                                            </Paper>
                                        </Grid>
                                    </FileUploader>
                                )
                                }
                                {
                                    anexos.length > 0 && (
                                        <TableContainer className={"table-container"}>
                                            <Table>
                                                <TableHead style={{backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF}}>
                                                    <TableRow>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Forma do anexo
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Anexo
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Tipo de anexo
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Descrição
                                                        </TableCell>
                                                        {
                                                            anexos.length > 1 && (
                                                                <TableCell/>
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        anexos.map((anexo, idx) => {
                                                            return(
                                                                <TableRow key={idx} style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                                    <TableCell align={"center"}>
                                                                        <FilterSingleOption
                                                                            filterOptions={["Arquivo", "Link"]}
                                                                            selectedOption={anexo.forma_anexo}
                                                                            setSelectedOption={(value) => (setAnexos(anexos.map((anexo_aux, idx_aux) => {
                                                                                if (idx_aux === idx) {
                                                                                    anexo_aux.forma_anexo = value;
                                                                                }
                                                                                return anexo_aux;
                                                                            })))}
                                                                            placeholder={"Forma do anexo"}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <Grid container spacing={2}>
                                                                            {
                                                                                !anexo.arquivo && (
                                                                                    <Grid item sx={{display: "flex", alignItems: "flex-end"}}>
                                                                                        <DragAndDrop
                                                                                            title="escolha o anexo"
                                                                                            testId="button-anexar-certificado"
                                                                                            handleClick={handleClick}
                                                                                            anexos={anexos}
                                                                                            setAnexos={setAnexos}
                                                                                            typesAccepted={fileTypesAccepted}
                                                                                        />
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                            <Grid item>
                                                                                {
                                                                                    anexo.arquivo &&
                                                                                        <Grid style={{...styles.styleFieldImport, color: temaEscuro && WHITE_THEME_BLACK, display: "flex", alignItems: "center"}}>
                                                                                            <VisibilityIcon
                                                                                                sx={{cursor: "pointer"}}
                                                                                                fontSize="small"
                                                                                                onClick={() => {
                                                                                                    setSelectedFile(anexo.arquivo);
                                                                                                    setOpenModal(true);
                                                                                                    }
                                                                                                }
                                                                                            />
                                                                                            <span>Arquivo: {anexo.arquivo?.name}</span>
                                                                                            <Grid item sx={{padding: 0, cursor: "pointer"}} onClick={() => nullifyFile(anexo, idx)}>
                                                                                                <ClearIcon
                                                                                                    fontSize="small"
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <FilterSingleOption
                                                                            filterOptions={["Contrato", "Documento Fiscal", "Documento de Cobrança", "Outros"]}
                                                                            selectedOption={anexo.tipo_anexo}
                                                                            setSelectedOption={(value) => (setAnexos(anexos.map((anexo_aux, idx_aux) => {
                                                                                if (idx_aux === idx) {
                                                                                    anexo_aux.tipo_anexo = value;
                                                                                }
                                                                                return anexo_aux;
                                                                            })))}
                                                                            placeholder={"Tipo de anexo"}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <InputSearch
                                                                            functionProps={() => {}}
                                                                            setSearchValue={(value) => (setAnexos(anexos.map((anexo_aux, idx_aux) => {
                                                                                if (idx_aux === idx) {
                                                                                    anexo_aux.descricao = value;
                                                                                }
                                                                                return anexo_aux;
                                                                            })))}
                                                                            searchValue={anexo.descricao}
                                                                            className="input-text"
                                                                            padding={0.5}
                                                                            width={"350px"}
                                                                            label={"Descrição"}
                                                                        />
                                                                    </TableCell>
                                                                    {
                                                                        anexos.length > 1 && (
                                                                            <TableCell
                                                                                align={"center"}
                                                                                style={{padding: 0, cursor: "pointer"}}
                                                                                onClick={() => handleRemoveAnexo(idx)}
                                                                            >
                                                                                <a>X</a>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                </TableRow>
                                                            );
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: isTransferencia ? 2 : 0, display: "flex", gap: "1rem"}}>
                                <DefaultButton
                                    startIcon={<AddIcon/>}
                                    title={"Adicionar anexo"}
                                    size={"small"}
                                    onClick={() => {
                                        setAnexos([...anexos, {
                                            forma_anexo: "Arquivo",
                                            arquivo: null,
                                            tipo_anexo: "Contrato",
                                            descricao: "",
                                        }]);
                                    }}
                                />
                                <DefaultButton
                                    startIcon={<AddIcon/>}
                                    title={"Adicionar múltiplos anexos"}
                                    size={"small"}
                                    onClick={() => {
                                        setMultipleAnexos(true);
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    )
                }
            </Grid>
        </React.Fragment>
    );
};

export default Anexos;

const styles = {
    styleFieldImport: {
        color : GRAY_STRONG_UX
    },

    formControl: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: GRAY_BORDER_UX
            },
        }
    },
};