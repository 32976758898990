import React, { useCallback, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { BLUE_THEME, smallPaperStyle, styleText, WHITE_THEME_BLACK, GRAY_LABEL_UX, COLOR_LABEL_INPUT, BORDER_BUTTON, RED_INFO } from "../../shared/utils";
import ModalTitle from "../Titles/ModalTitle";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import Checkbox from "@mui/material/Checkbox";
import DefaultButton from "../Buttons/DefaultButton";


const EdicaoLoteModal = ({openModal, setOpenModal, exmItems, getExtratoMovimentacoes, movimentacaoType, isEXM, setAnchorEl, selectedTab}) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoID = useSelector(state => state.StoreReducer.estabelecimentoId);

    const [fornecedores, setFornecedores] = React.useState([]);
    const [selectedFornecedor, setSelectedFornecedor] = React.useState("");
    const [selectedDataCompetencia, setSelectedDataCompetencia] = React.useState("");
    const [descricao, setDescricao] = React.useState("");
    const [observacoes, setObservacoes] = React.useState("");
    const [categorias, setCategorias] = React.useState([]);
    const [selectedCategoria, setSelectedCategoria] = React.useState("");
    const [centroCustos, setCentroCustos] = React.useState([]);
    const [selectedCentroCusto, setSelectedCentroCusto] = React.useState("");
    const [agendado, setAgendado] = React.useState(false);

    const dispatch = useDispatch();

    const clearInputs = () => {
        setSelectedFornecedor("");
        setSelectedDataCompetencia("");
        setDescricao("");
        setObservacoes("");
    };

    useEffect(() => {
        if (openModal) {
            getFornecedores();
            getDataEdicaoLote();
        }
    }, [openModal]);

    const getFornecedores = useCallback(() => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoID
        };

        api.GetFornecedores(dataRequest).then(response => {
            setFornecedores(response.data);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao buscar fornecedores.", severity: "error"}));
        });
    }, [openModal]);

    const getDataEdicaoLote = useCallback(() => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoID
        };

        api.GetDataEdicaoLote(dataRequest).then(response => {
            setCategorias(response.data.categorias);
            setCentroCustos(response.data.centro_custos);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao buscar fornecedores.", severity: "error"}));
        });
    }, [openModal]);

    const sendEdicaoLote = () => {
        const dataRequest = {
            selected_movimentacoes: exmItems,
            estabelecimento_id: estabelecimentoID,
            fornecedor: selectedFornecedor.value,
            data_movimentacao: selectedDataCompetencia,
            descricao: descricao,
            observacoes: observacoes,
            categoria: selectedCategoria.value,
            centro_custo: selectedCentroCusto.value,
            tab: selectedTab,
        };

        api.UpdateLoteAction(dataRequest).then(response => {
            dispatch(showSnackMessage({message: response.data.data, severity: "success"}));
            setOpenModal(false);
            getExtratoMovimentacoes();
            clearInputs();
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao realizar edição em lote.", severity: "error"}));
            setOpenModal(false);
            clearInputs();
        });
    };
    
    const edicaoLoteNotas = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoID,
            movimentacoes_id: exmItems,
            categoria_id: selectedCategoria.value,
            agendado: agendado
        };
        api.EdicaoLoteNotas(dataRequest).then(response => {
            setAnchorEl(null);
            setOpenModal(false);
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
        }).catch(() => {
            setAnchorEl(null);
            setOpenModal(false);
            dispatch(showSnackMessage({message: "Erro ao realizar edição em lote.", severity: "error"}));
        });
    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${dia}/${mes}/${ano}`);
    };

    return (
        <Modal
            open={openModal}
            onClose={() => {
                setOpenModal(false);
                setAnchorEl(null);
            }}
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, maxWidth: "40%"}}>
                <ModalTitle title="Edição em Lote" setOpenModal={setOpenModal} />
                    {
                        isEXM ? (
                            <React.Fragment>

                                <Grid container sx={{display: "flex", mt: "1rem", gap: "1rem"}}>
                                    <FilterSingleOption
                                        filterOptions={fornecedores}
                                        selectedOption={selectedFornecedor}
                                        setSelectedOption={setSelectedFornecedor}
                                        placeholder={movimentacaoType == "Despesa" ? "Fornecedor" : "Cliente"}
                                        width={"300px"}
                                    />
                                    <FilterDayMonthYear
                                        handleSubmit={handleDayMonthYear}
                                        value={selectedDataCompetencia}
                                        setState={setSelectedDataCompetencia}
                                        size={"small"}
                                        width={"300px"}
                                        iconPosition={"end"}
                                        label={"Data da compra"}
                                        isEditConta
                                    />
                                    <FilterSingleOption
                                        filterOptions={categorias}
                                        selectedOption={selectedCategoria}
                                        setSelectedOption={setSelectedCategoria}
                                        placeholder="Categoria"
                                        width={"300px"}
                                    />
                                    <FilterSingleOption
                                        filterOptions={centroCustos}
                                        selectedOption={selectedCentroCusto}
                                        setSelectedOption={setSelectedCentroCusto}
                                        placeholder="Centro de custo"
                                        width={"300px"}
                                    />
                                    <TextField
                                        size="small"
                                        label="Descrição"
                                        value={descricao}
                                        multiline
                                        rows={3}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        sx={{width: "300px", backgroundColor: temaEscuro && BLUE_THEME}}
                                    />
                                    <TextField
                                        size="small"
                                        label="Observações"
                                        multiline
                                        rows={3}
                                        value={observacoes}
                                        onChange={(e) => setObservacoes(e.target.value)}
                                        sx={{width: "300px", backgroundColor: temaEscuro && BLUE_THEME}}
                                    />
                                </Grid>
                                <Grid container sx={{display: "flex", justifyContent: "end", mt: "1rem"}}>
                                    <DefaultButton
                                        title="Salvar"
                                        onClick={() => sendEdicaoLote()}
                                        variant="yellow"
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid container sx={{display: "flex", mt: "1rem", gap: "1rem"}}>
                                    <Grid item sx={{display: "flex", gap: 1}}> 
                                        <p className="item-container-label" style={{color: temaEscuro ? RED_INFO : BORDER_BUTTON}}>ATENÇÃO - </p>
                                        <p className="item-container-label" style={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_BUTTON}}>
                                            os campos abaixo são referentes a todas as movimentações relacionadas a essa(s) nota(s).
                                        </p>
                                    </Grid>
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={categorias}
                                            selectedOption={selectedCategoria}
                                            setSelectedOption={setSelectedCategoria}
                                            placeholder="Categoria"
                                            width={"300px"}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <a 
                                            style={{
                                                ...styleText,
                                                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                            }}
                                        >
                                            Agendado
                                        </a>
                                        <Checkbox
                                            checked={agendado}
                                            onChange={(event) => setAgendado(event.target.checked)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{display: "flex", justifyContent: "end", mt: 5}}>
                                    <DefaultButton
                                        title="Salvar"
                                        onClick={() => edicaoLoteNotas()}
                                        disabled={selectedCategoria === "" || selectedCategoria === null}
                                        variant="yellow"
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    }
            </Paper>
        </Modal>
    );
};

export default EdicaoLoteModal;