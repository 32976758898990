import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import {BLUE_THEME, GRAY_PDF, LINE_TABLE, paperStyle} from "../../shared/utils";
import Header from "../../components/Titles/Header";
import { useDispatch, useSelector } from "react-redux";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import DefaultButton from "../../components/Buttons/DefaultButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AnexoPreviewModal from "../../components/Modal/AnexoPreviewModal";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";

const AnexoInteligente = () => {
    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [anexos, setAnexos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSelectFile = async () => {
        const fileHandles = await window.showOpenFilePicker({
            multiple: true
        });
        
        const files = await Promise.all(fileHandles.map(fileHandle => fileHandle.getFile()));
        setAnexos([...anexos, ...files.map(file => file)]);
    };

    const sendAnexosToS3 = () => {
        let formData = new FormData();

        if (anexos.length > 0) {
            anexos.forEach((anexo) => {
                formData.append("anexos", anexo);
            });
        }

        api.PostAnexos(formData).then(() => {
            dispatch(showSnackMessage({"message": "Anexos enviados com sucesso", "severity": "success"}));
            setAnexos([]);
        }
        ).catch(() => {
            dispatch(showSnackMessage({"message": "Erro ao enviar anexos", "severity": "error"}));
        });
    };

    return (
        <div className="main">
            <Header title={"Anexo Inteligente"} />
            <Paper sx={{...paperStyle, backgroundImage: temaEscuro && "none", backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE,}}>
                <AnexoPreviewModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    file={selectedFile}
                />
                <Grid container spacing={4} sx={{display: "flex", justifyContent: "center"}}>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{textAlign: "center"}}>
                            Escolha as DANFEs ou boletos que deseja anexar ao sistema. O Anexo Inteligente irá identificar automaticamente os dados e lança-los no sistema.
                        </Typography>
                        <Divider sx={{mt: 1}}/>
                    </Grid>
                    <Grid item sx={{width: "fit-content"}}>
                        <DragAndDrop
                            multiple
                            title="Escolha os anexos"
                            anexos={anexos}
                            setAnexos={setAnexos}
                            handleClick={handleSelectFile}
                        />
                    </Grid>
                    {
                        anexos.length > 0 && (
                            <Grid item xs={12} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <TableContainer sx={{width: "50%"}}>
                                    <Table>
                                        <TableHead sx={{backgroundColor: !temaEscuro && GRAY_PDF }}>
                                            <TableRow>
                                                <TableCell sx={{textAlign: "center"}}>Vizualização</TableCell>
                                                <TableCell sx={{textAlign: "center"}}>Anexo</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos.map((anexo, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{textAlign: "center"}}>
                                                        <VisibilityIcon
                                                            sx={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                setSelectedFile(anexo);
                                                                setOpenModal(true);
                                                                }
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{textAlign: "center"}}>
                                                        {anexo.name}
                                                    </TableCell>
                                                    <TableCell>
                                                            <CloseIcon
                                                                sx={{cursor: "pointer"}}
                                                                onClick={() => {
                                                                    anexos.splice(index, 1);
                                                                    setAnexos([...anexos]);
                                                                }}
                                                            />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <DefaultButton
                            variant="yellow"
                            title="Enviar anexos"
                            onClick={sendAnexosToS3}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default AnexoInteligente;