import React, { useRef, useState } from "react";
import DefaultButton from "../Buttons/DefaultButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { BORDER_BUTTON, fileTypesAccepted, GRAY_LABEL_UX, RED_ERROR_UX, YELLOW_BG_UX } from "../../shared/utils";
import { useSelector } from "react-redux";

const DragAndDrop = ({ title, testId, handleClick, anexos, setAnexos, typesAccepted = fileTypesAccepted, multiple = false }) => {
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const dragNDrop = useRef(null);

    const [typeError, setTypeError] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        dragNDrop.current.style.borderColor = YELLOW_BG_UX;
    };

    const handleDrop = (e) => {
        e.preventDefault();
        
        if (multiple) {
            const files = e.dataTransfer.files;
            const filesArray = Array.from(files);

            let anexosList = [];

            filesArray.forEach((file) => {
                const fileType = file.type.split("/")[1].toUpperCase();
                
                if (!typesAccepted.includes(fileType)) {
                    setTypeError(true);
                    dragNDrop.current.style.borderColor = RED_ERROR_UX;
                    return;
                }
                anexosList.push(file);
                dragNDrop.current.style.borderColor = temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX;
            });

            setAnexos(anexosList);
        }
        else {
            const file = e.dataTransfer.files[0];
            const fileType = file.type.split("/")[1].toUpperCase();
            
            if (!typesAccepted.includes(fileType)) {
                setTypeError(true);
                dragNDrop.current.style.borderColor = RED_ERROR_UX;
                return;
            }
            
            let anexo = anexos.find((anexo) => anexo.arquivo === null);
            anexo.arquivo = file;
            setAnexos([...anexos]);
        }
    };

    return (
        <div
            ref={dragNDrop}
            style={{border: "1px dashed", borderColor: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX, borderRadius: "5px", width: "100%", height: "100%",padding: 1}}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={() => dragNDrop.current.style.borderColor = temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}
        >
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 3, marginLeft: 3, marginRight: 3}}>
                <DefaultButton
                    testId={testId}
                    title={title}
                    onClick={() => handleClick()}
                    startIcon={<AttachFileOutlinedIcon/>}
                    size="small"
                />
                <span style={{marginLeft: ".5rem", color: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX}}>{typeError ? "tipo de anexo inválido" : "ou arraste-o aqui"}</span>
            </div>
        </div>
    );
};

export default DragAndDrop;