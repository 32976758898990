import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLUE_THEME,
    BORDER_TABLE,
    WHITE_ESCRITA_THEME,
    handleDayMonthYear,
    format,
    smallPaperStyle,
    styleTitleModal,
    GRAY_LABEL_UX,
    formaPagamentoOptions
} from "../../shared/utils";
import { showSnackMessage } from "../../actions/SnackActions";
import InputCurrency from "../Input/InputCurrency";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import api from "../../axios";
import DefaultButton from "../Buttons/DefaultButton";
import Modal from "@mui/material/Modal";
import FilterSingleOption from "../Filter/FilterSingleOption";
import {Stack, Typography} from "@mui/material";


const EditarPrevisao = ({
    open,
    setOpen,
    baixaId,
    onConfirmInformarPagamento,
    valorParcelaAberto
}) => {

    const [valorParcela, setValorParcela] = useState(0.0);
    const [dataPagamento, setDataPagamento] = useState(null);
    const [formaPagamento, setFormaPagamento] = useState("");
    const [conta, setConta] = useState({});
    const [valorJuros, setValorJuros] = useState(0.0);
    const [valorMulta, setValorMulta] = useState(0.0);
    const [valorDesconto, setValorDesconto] = useState(0.0);

    const [contasOptions, setContasOptions] = useState([]);

    const dispatch = useDispatch();
    const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(
        (state) => state.StoreReducer.estabelecimentoId
    );

    useEffect(() => {
        if (open) {
            loadPage();
        }
    }, [open]);

    const loadPage = () => {
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
        };
        let contas;
        api.RetrieveContas(dataRequest).then((response) => {
            contas = response.data;
            setContasOptions(contas);
        })
        .catch(() => {
            dispatch(
                showSnackMessage({
                    message: "Algo deu errado! Tente novamente mais tarde",
                    severity: "error",
                })
            );
        });

        dataRequest = {
            baixa_id: baixaId,
        };
        api.GetInformarPagamento(dataRequest).then((response) => {
            setValorParcela(response.data.valor_parcela);
            setDataPagamento(
                moment(response.data.data_pagamento, "YYYY-MM-DD").toDate()
            );
            setFormaPagamento(response.data.forma_pagamento);
            setConta(
                contasOptions.find((conta) => conta.value === response.data.conta)
            );
            setValorJuros(response.data.juros);
            setValorMulta(response.data.multa);
            setValorDesconto(response.data.desconto);
        })
        .catch(() => {
            dispatch(
                showSnackMessage({
                    message: "Algo deu errado! Tente novamente mais tarde",
                    severity: "error",
                })
            );
        });
    };

    const handleEditPrevisao = () => {
        let dataRequest = {
            baixa_id: baixaId,
            data_pagamento: moment(dataPagamento).format("YYYY-MM-DD"),
            forma_pagamento: formaPagamento,
            conta_id: conta.value,
            juros: valorJuros,
            multa: valorMulta,
            desconto: valorDesconto,
            valor_pago: 0.0,
        };
        api.PutChangePrevisao(dataRequest)
            .then(() => {
                onConfirmInformarPagamento();
                setOpen(false);
            })
            .catch(() => {
                dispatch(
                    showSnackMessage({
                        message: "Algo deu errado! Tente novamente mais tarde",
                        severity: "error",
                    })
                );
            });
    };


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="info-modal"
        >
            <Paper sx={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, maxWidth: "60%"}}>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, marginBottom: 2}}>
                        <h3 style={{marginTop: 0, ...styleTitleModal, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX, fontWeight: "700"}}>Nova previsão de vencimento</h3>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center", marginBottom: 5}}>
                        <InputCurrency
                            title={"Valor aberto (R$)"}
                            valor={valorParcela}
                            setValor={setValorParcela}
                            disabled
                            width={"200px"}
                        />
                        <FilterDayMonthYear
                            disableMinDate
                            isRequired
                            handleSubmit={handleDayMonthYear}
                            value={dataPagamento}
                            setState={setDataPagamento}
                            size={"small"}
                            iconPosition={"end"}
                            width={"300px"}
                            label={"Previsão de pagamento"}
                        />
                        <FilterSingleOption
                            filterOptions={formaPagamentoOptions}
                            selectedOption={formaPagamento}
                            setSelectedOption={setFormaPagamento}
                            placeholder={"Forma de pagamento"}
                            testId="filter-forma-pagamento"
                            width={"300px"}
                        />
                        <FilterSingleOption
                            filterOptions={contasOptions}
                            selectedOption={conta}
                            setSelectedOption={setConta}
                            placeholder={"Conta"}
                            testId="filter-conta"
                            width={"300px"}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center"}}>
                        <InputCurrency
                            width={"200px"}
                            title={"Juros (R$)"}
                            valor={valorJuros}
                            setValor={setValorJuros}
                        />
                        <InputCurrency
                            width={"200px"}
                            title={"Multa (R$)"}
                            valor={valorMulta}
                            setValor={setValorMulta}
                        />
                        <InputCurrency
                            width={"200px"}
                            title={"Desconto (R$)"}
                            valor={valorDesconto}
                            setValor={setValorDesconto}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 5}}>
                        <Stack direction="column">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent:
                                        "flex-end",
                                }}
                            >
                                <Typography
                                    fontSize={"15px"}
                                >
                                    Saldo em aberto
                                </Typography>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    justifyContent:
                                        "flex-end",
                                }}
                            >
                                <Typography
                                    fontSize={"15px"}
                                    fontWeight={"bold"}
                                    marginRight={"5px"}
                                >
                                    R$
                                </Typography>
                                <Typography
                                    fontSize={"25px"}
                                    fontWeight={"bold"}
                                >
                                    {format(valorParcelaAberto + valorJuros + valorMulta - valorDesconto)}
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 5, display: "flex", gap: 2, alignItems: "center", justifyContent: "end", borderTop: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`, paddingTop: 3}}>
                        <DefaultButton 
                            title="Cancelar"
                            onClick={() => setOpen(false)}
                        />
                        <DefaultButton
                            title="Confirmar"
                            onClick={() => handleEditPrevisao()}
                            variant="yellow"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default EditarPrevisao;
