import { Divider, Grid, Modal, Paper } from "@mui/material";
import React, { useState } from "react";
import { BLUE_LIGHT_UX_THEME, BLUE_THEME, BORDER_PAPER, BORDER_TABLE, getCurrentDate, GRAY_LABEL_UX, handleDayMonthYear, LINE_TABLE, paperStyle, smallPaperStyle, WHITE_ESCRITA_THEME } from "../../shared/utils";
import { useDispatch, useSelector } from "react-redux";
import FilterSingleOption from "../Filter/FilterSingleOption";
import DefaultButton from "../Buttons/DefaultButton";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";

const EditContaVencimentoLote = ({ openModal, setOpenModal, type, contas, exmItems, tab, getExtratoMovimentacoes, selectedTab }) => {
    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const currentMonthDate = moment(getCurrentDate()).toDate();

    const [newConta, setNewConta] = useState("");
    const [dataPrevisao, setDataPrevisao] = useState(currentMonthDate);
    const [dataVencimento, setDataVencimento] = useState(currentMonthDate);

    const clearFields = () => {
        setNewConta("");
        setDataPrevisao(currentMonthDate);
        setDataVencimento(currentMonthDate);
        setOpenModal(false);
    };

    const handleEditEmLote = () => {
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            selected_movimentacoes: exmItems,
            tab: selectedTab,
        };

        if (type === "conta") {
            dataRequest["conta"] = newConta.label;
        }
        else if (type === "vencimento" && tab === "movimentacoes") {
            dataRequest["data_previsao"] = moment(dataPrevisao).format("DD-MM-YYYY");
        }
        else if (type === "vencimento" && tab !== "movimentacoes") {
            dataRequest["data_vencimento"] = moment(dataVencimento).format("DD-MM-YYYY");
            dataRequest["data_previsao"] = moment(dataPrevisao).format("DD-MM-YYYY");
        }

        api.UpdateLoteAction(dataRequest).then(() => {
            dispatch(showSnackMessage({message: "Parcela(s) alterada(s) com sucesso!", severity: "success"}));
            setOpenModal(false);
            getExtratoMovimentacoes();
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao alterar parcela(s)!", severity: "error"}));
        });
    };

    const getTitle = () => {
        if (tab === "movimentacoes" && type !== "conta") {
            return "Editar data de previsão do(s) lançamento(s)";
        }
        else if (tab !== "movimentacoes" && type !== "conta") {
            return "Alterar vencimento e/ou previsão";
        }
        else {
            return "Alterar conta de parcela(s)";
        }
    };

    const getWarningTitle = () => {
        if (tab === "movimentacoes") {
            return "Edição da previsão de pagamento de múltiplos lançamentos";
        }
        else {
            return "Edição de vencimento e/ou previsão em lote";
        }
    };

    const getWarningMessage = () => {
        if (tab === "movimentacoes") {
            return <span>Esta edição será realizada apenas para lançamentos com situação <strong>Em Aberto</strong></span>;
        }
        else {
            return <span>O vencimento será alterado <strong>somente para parcelas em aberto</strong>, enquanto a previsão será alterada para parcelas em aberto ou recebido parcial.</span>;
        }
    };

    return (
        <Modal
            open={openModal}
            onClose={() => clearFields()}
        >
            <Paper sx={{...smallPaperStyle, maxWidth: "50vw", width: "40vw", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                <Grid item xs={10} className="start">
                    <span
                        style={{
                            fontWeight: "700",
                            fontSize: "18px",
                            color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX
                        }}
                    >
                        {getTitle()}
                    </span>
                </Grid>
                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, mt: 2}} />
                    {
                        type !== "conta" && (
                            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", border: `1px solid ${BLUE_LIGHT_UX_THEME}`, borderWidth: "1px 1px 1px 6px", mb: 2}}>
                                <div style={{display: "flex", gap: "1rem", alignItems: "start", justifyContent: "space-between"}}>
                                    <div style={{display: "flex", gap: "1rem", alignItems: "start"}}>
                                        <InfoIcon sx={{color: BLUE_LIGHT_UX_THEME, ml: 1, fontSize: "26px"}} />
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <span style={{fontWeight: "bold", fontSize: "15px", marginBottom: ".5rem"}}>{getWarningTitle()}</span>
                                            <span style={{fontSize: "14px"}}>{getWarningMessage()}</span>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        )
                    }
                <Grid sx={{marginTop: 5}}>
                    {
                        type === "conta" ? (
                            <FilterSingleOption
                                required
                                placeholder="Conta"
                                filterOptions={contas}
                                setSelectedOption={setNewConta}
                                selectedOption={newConta}
                                width={"235px"}
                            />
                        ) : (
                            <Grid item sx={{display: "flex", gap: "1rem"}}>
                                {
                                    (tab !== "movimentacoes") && (tab !== "visao_competencia") && (
                                        <FilterDayMonthYear
                                            isRequired
                                            style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                            handleSubmit={handleDayMonthYear}
                                            value={dataVencimento}
                                            setState={setDataVencimento}
                                            size={"small"}
                                            width={"250px"}
                                            iconPosition={"end"}
                                            label={"Data de vencimento"}
                                            disableMinDate
                                        />
                                    )
                                }
                                <FilterDayMonthYear
                                    isRequired
                                    style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                    handleSubmit={handleDayMonthYear}
                                    value={dataPrevisao}
                                    setState={setDataPrevisao}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data de previsão"}
                                    disableMinDate
                                />
                            </Grid>
                        )
                    }
                </Grid>
                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, mb: "1rem", mt: "3rem"}} />
                <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                    <DefaultButton
                        title="Cancelar"
                        onClick={() => clearFields()}
                    />
                    <DefaultButton
                        variant="yellow"
                        title="Confirmar"
                        onClick={() => handleEditEmLote()}
                    />
                </Grid>
            </Paper>
        </Modal>
    );
};

export default EditContaVencimentoLote;