import React, { useState, useEffect } from "react";
import {changeIdLancamentoCreated, changeStore} from "../../actions/StoreActions";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import {
    BLACK_TABLE,
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BORDER_TABLE,
    formatterPercentage,
    formatterThousand,
    getCurrentDate,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    GRAY_LABEL_UX_THEME,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    styleText,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK,
    YELLOW_BG_UX,
    BLACK_LABEL_UX,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    handleDayMonthYear,
    formaPagamentoOptions,
    BLACK_TABLE_PERFIL
} from "../../shared/utils";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import api from "../../axios";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import moment from "moment";
import InputSearch from "../../components/Input/InputSearch";
import Anexos from "../../components/Tabs/Anexos";
import RateiosReceita from "../../components/Tabs/RateiosReceita";
import CreateOptionModal from "../../components/Modal/CreateOptionModal";
import RateioCentroCustoModal from "../../components/Modal/RateioCentroCustoModal";
import {cobrarSempreOptionsMes, cobrarSempreOptionsSemana} from "./Enums";
import ParcelasModal from "../../components/Modal/ParcelasModal";
import DefaultButton from "../../components/Buttons/DefaultButton";
import {showSnackMessage} from "../../actions/SnackActions";
import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import InputCurrency from "../../components/Input/InputCurrency";
import SaveOptions from "../../components/Buttons/SaveOptions";
import { useHotkeys } from "react-hotkeys-hook";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import {changeOpenLancamento} from "../../actions/StoreActions";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Movimentacao = (props) => {

    const {
        type,
        setOpenCompletarInfoDialog=null,
        openCompletarInfoDialog=false,
        conta=null,
        lancamento=null,
        indexConciliacao=null,
        handleAddMovimentacao=null,
        categoria=null,
    } = props;

    const dispatch = useDispatch();
    const {state} = useLocation();

    const dateParts = lancamento ? lancamento.data_lancamento.split("/") : null;
    const dataLancamento = dateParts ? moment(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`, "DD/MM/YYYY").toDate() : null;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const openLancamento = useSelector(state => state.StoreReducer.openLancamento);

    const isLargeScreen = useMediaQuery("(min-width:1440px)");
    const [open, setOpen] = useState(openCompletarInfoDialog === true ? openCompletarInfoDialog : (openLancamento === "receita" || "despesa"));
    const [componente, setComponente] = useState("");
    const [locationId, setLocationId] = useState(0);
	const currentMonthDate = moment(getCurrentDate()).toDate();
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [optionCreateModal, setOptionCreateModal] = useState("");
    const [openRateioCentroCustoModal, setOpenRateioCentroCustoModal] = useState(false);
    const [categoriaRateioCentroCusto, setCategoriaRateioCentroCusto] = useState("");
    const [valorCategoriaRateioCentroCusto, setValorCategoriaRateioCentroCusto] = useState(0);
    const [indexRateioCentroCusto, setIndexRateioCentroCusto] = useState(0);

    const [clienteOptions, setClientesOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [contasOptions, setContasOptions] = useState([]);

    const [selectedCliente, setSelectedCliente] = useState(null);
    const [selectedDataCompra, setSelectedDataCompra] = useState(state?.data || currentMonthDate);
    const [descricaoCompra, setDescricaoCompra] = useState(state?.descricao || "");
    const [valorCompra, setValorCompra] = useState(state?.valor || 0);

    const [hasRateio, setHasRateio] = useState(false);
    const [rateios, setRateios] = useState([{
        categoria: null,
        valor: 0,
        percentual: 0,
    }]);

    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [codigoReferencia, setCodigoReferencia] = useState("");

    const [repetirCompra, setRepetirCompra] = useState(false);
    const [configRecorrencia, setConfigRecorrencia] = useState([
        {"label": "Diário: A cada 10 dia(s), 3 vez(es)", "value": "diario-10-3"},
        {"label": "Mensal: A cada 1 mês(es), 10 vez(es)", "value": "mensal-1-10"},
        {"label": "Diário: A cada 1 dia(s), 37 vez(es)", "value": "diario-1-37"},
    ]);
    const optionsParcelamento = ["À vista", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const [configRepeticao, setConfigRepeticao] = useState({"label": "Diário: A cada 10 dia(s), 3 vez(es)", "value": "diario-10-3"});
    const [cobrarSempreOption, setCobrarSempreOption] = useState(cobrarSempreOptionsMes);
    const [selectedCobrarSempre, setSelectedCobrarSempre] = useState(null);
    const [selectedPrimeiroVencimento, setSelectedPrimeiroVencimento] = useState(currentMonthDate);

    const [selectedParcelamento, setSelectedParcelamento] = useState("À vista");
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [openParcelasModal, setOpenParcelasModal] = useState(false);
    const [parcelas, setParcelas] = useState([]);

    const [selectedDataVencimento, setSelectedDataVencimento] = useState(state?.data || currentMonthDate);
    const [selectedConta, setSelectedConta] = useState(conta || null);

    const [hasRecebido, setHasRecebido] = useState(false);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);

    const [prevSelectedCliente, setPrevSelectedCliente] = useState(null);
    const [prevValorCompra, setPrevValorCompra] = useState(0);
    const [prevHasRateio, setPrevHasRateio] = useState(false);
    const [prevSelectedCategoria, setPrevSelectedCategoria] = useState(null);
    const [prevSelectedCentroCusto, setPrevSelectedCentroCusto] = useState(null);
    const [prevRepetirCompra, setPrevRepetirCompra] = useState(false);
    const [prevAnexos, setPrevAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [prevParcelamento, setPrevParcelamento] = useState(null);
    const [prevFormaPagamento, setPrevFormaPagamento] = useState(null);
    const [prevConta, setPrevConta] = useState(null);
    const [prevRecebido, setPrevRecebido] = useState(false);
    const [prevConfigRepeticao, setPrevConfigRepeticao] = useState(null);
    const [prevCobrarSempre, setPrevCobrarSempre] = useState(null);

    const [changesList, setChangesList] = useState([]);

    const [loading, setLoading] = useState(false);

    useHotkeys("ctrl+z", () => {
        const change = changesList.pop();

        switch (change) {
            case "clienteFornecedor":
                setSelectedCliente(prevSelectedCliente);
                break;
            case "valor":
                setValorCompra(prevValorCompra);
                break;
            case "categoria":
                setSelectedCategoria(prevSelectedCategoria);
                break;
            case "centroCusto":
                setSelectedCentroCusto(prevSelectedCentroCusto);
                break;
            case "hasRateio":
                setHasRateio(prevHasRateio);
                break;
            case "repetirCompra":
                setRepetirCompra(prevRepetirCompra);
                break;
            case "parcelamento":
                setSelectedParcelamento(prevParcelamento);
                break;
            case "anexos":
                setAnexos(prevAnexos);
                break;
            case "formaPagamento":
                setSelectedFormaPagamento(prevFormaPagamento);
                break;
            case "conta":
                setSelectedConta(prevConta);
                break;
            case "recebido":
                setHasRecebido(prevRecebido);
                break;
            case "configRepeticao":
                setConfigRepeticao(prevConfigRepeticao);
                break;
            case "cobrarSempre":
                setSelectedCobrarSempre(prevCobrarSempre);
                break;
            default:
                break;
        }
    });

    useEffect(() => {
        if (dataLancamento) {
            setSelectedDataCompra(dataLancamento);
            setSelectedDataVencimento(dataLancamento);
            setDescricaoCompra(lancamento.descricao);
            setValorCompra(lancamento.valor_lancamento);
            setSelectedCategoria(categoria);
        }
    }, [lancamento]);

    useEffect(() => {
        if (type.includes("despesa")) {
            setComponente("Despesa");
        } else {
            setComponente("Receita");
        }
    }, [location]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        setLocationId(parseInt(query.get("estabelecimento_id")));
        if (locationId !== 0 && locationId != null && !isNaN(locationId)) {
            dispatch(changeStore(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        getInputsOptions();
    }, [estabelecimentoId]);

    useEffect(() => {
        if (selectedParcelamento) {
            handleParcelas();
        }
    }, [selectedParcelamento, valorCompra]);

    useEffect(() => {
        if (configRepeticao) {
            if (configRepeticao.value.includes("diario")) {
                setSelectedPrimeiroVencimento(moment(selectedDataCompra).add(1, "days").toDate());
            } else if (configRepeticao.value.includes("semanal")) {
                setCobrarSempreOption(cobrarSempreOptionsSemana);
                let dateAux = 1;
                if (selectedCobrarSempre) {
                    dateAux = selectedCobrarSempre.value;
                }
                let nextWeekday = moment().day(dateAux);
                if (moment().day() >= dateAux) {
                    nextWeekday.add(7, "days");
                }
                setSelectedPrimeiroVencimento(nextWeekday.toDate());
            } else if (configRepeticao.value.includes("mensal")) {
                setCobrarSempreOption(cobrarSempreOptionsMes);
                let dateAux = 1;
                if (selectedCobrarSempre) {
                    dateAux = selectedCobrarSempre.value;
                }
                let nextMonthday = moment().date(dateAux);
                if (moment().date() >= dateAux) {
                    nextMonthday.add(1, "months");
                }
                setSelectedPrimeiroVencimento(nextMonthday.toDate());
            } else if (configRepeticao.value.includes("anual")) {
                setCobrarSempreOption(cobrarSempreOptionsMes);
                let dateAux = 1;
                if (selectedCobrarSempre) {
                    dateAux = selectedCobrarSempre.value;
                }
                let nextMonthday = moment().date(dateAux);
                if (moment().date() >= dateAux) {
                    nextMonthday.add(1, "months");
                }
                setSelectedPrimeiroVencimento(nextMonthday.toDate());
            }
        }
    }, [configRepeticao, selectedDataCompra, selectedCobrarSempre]);

    useEffect(() => {
        if (openLancamento?.tipo !== null && openLancamento?.tipo !== undefined) {
            getClonarDetails();
        }
    }, [openLancamento]);

    const getClonarDetails = () => {

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            id: openLancamento?.id,
            tab: openLancamento?.tab,
        };

        api.GetDetailsClonar(dataRequest).then(response => {
            const data = response.data[0];
            setSelectedCliente(data.cliente_fornecedor ? data.cliente_fornecedor : null);
            setSelectedDataCompra(moment(data.data_compra, "DD/MM/YYYY").toDate());
            setDescricaoCompra(data.descricao);
            setValorCompra(data.valor_baixa);
            setSelectedCategoria(data.categoria ? data.categoria : null);
            setSelectedCentroCusto(data.centro_custo ? data.centro_custo : null);
            setCodigoReferencia(data.codigo_referencia);
            setSelectedParcelamento(data.parcelamento ? data.parcelamento : "À vista");
            setSelectedDataVencimento(moment(data.vencimento, "DD/MM/YYYY").toDate());
            setSelectedFormaPagamento(data.forma_pagamento_baixa ? data.forma_pagamento_baixa : null);
            setSelectedConta(data.conta_pagamento ? data.conta_pagamento : null);
            setHasRecebido(!data.previsao_baixa);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };
    
    const postCreateReceita = (_closeModal = false) => {
        setLoading(true);
        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        if (selectedCliente !== null) {
            if (openLancamento?.id) {
                const itemSelected = clienteOptions.find(option => option.label == selectedCliente?.label || selectedCliente);
                formData.append("cliente_id", itemSelected.value);
            } else {
                formData.append("cliente_id", selectedCliente.value);
            }
        }
        formData.append("data_compra", moment(selectedDataCompra).format("DD-MM-YYYY"));
        formData.append("descricao", descricaoCompra);
        formData.append("valor_total", valorCompra);
        formData.append("has_rateio", hasRateio);
        if (hasRateio) {
            formData.append("rateios", JSON.stringify(rateios));
        } else {
            formData.append("centro_custo_id", selectedCentroCusto?.value || 0);
        }
        if (selectedCategoria && !(rateios.length > 1)) {
            if (openLancamento?.id) {
                const itemSelected = categoriaOptions.find(option => option.label == selectedCategoria?.label || selectedCategoria);
                formData.append("categoria_id", itemSelected.value);
            } else {
                formData.append("categoria_id", selectedCategoria.value);
            }
        }
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("repetir_compra", repetirCompra);
        if (repetirCompra) {
            formData.append("repetir_compra", repetirCompra);
            formData.append("config_repeticao", configRepeticao.value);
            if (!configRepeticao.value.includes("diario")) {
                formData.append("cobrar_sempre", selectedCobrarSempre.label);
            }
            formData.append("primeiro_vencimento", moment(selectedPrimeiroVencimento).format("DD-MM-YYYY"));
        } else {
            if (selectedParcelamento !== "À vista") {
                for (let parcela of parcelas) {
                    parcela.data_vencimento = moment(parcela.data_vencimento).format("DD-MM-YYYY");
                }
                formData.append("parcelamento", selectedParcelamento);
                formData.append("parcelas", JSON.stringify(parcelas));
            }
            else {
                formData.append("valor_pago", valorCompra);
            }
            formData.append("data_vencimento", moment(selectedDataVencimento).format("DD-MM-YYYY"));
        } 
        if (openLancamento?.id) {
            const itemSelected = formaPagamentoOptions.find(option => option == selectedFormaPagamento?.label || selectedFormaPagamento);
            formData.append("forma_pagamento", itemSelected);
        } else {
            formData.append("forma_pagamento", selectedFormaPagamento);
        }
        if (selectedConta != null) {
            if (openLancamento?.id) {
                const itemSelected = contasOptions.find(option => option.label == selectedConta?.label || selectedConta);
                formData.append("conta_recebimento", itemSelected.value);
            } else {
                formData.append("conta_recebimento", selectedConta.value);
            }
        }
        formData.append("recebido", hasRecebido);
        formData.append("observacoes", observacoes);
        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
        formData.append("tipo_movimentacao", componente);
        api.PostGenerateMovimentacao(formData).then((response) => {
            setLoading(false);
            dispatch(showSnackMessage({message: `${componente} gerado(a) com sucesso!`, severity: "success"}));
            const idLancamento = response.data.ids;
            dispatch(changeIdLancamentoCreated(idLancamento));
            if (_closeModal) {
                closeModal();
            }
            if (lancamento) {
                handleAddMovimentacao(indexConciliacao, lancamento.id, {
                    id: response.data.ids[0],
                    apelido_conta: selectedConta.label,
                    codigo_referencia: codigoReferencia
                        ? codigoReferencia
                        : "Sem NFE",
                    data_pagamento:
                        moment(selectedDataCompra).format("DD/MM/YYYY"),
                    desconto: 0,
                    descricao: descricaoCompra,
                    nome_categoria: selectedCategoria ? [selectedCategoria.label] : [],
                    nome_fornecedor: selectedCliente?.label,
                    tipo_movimentacao: componente,
                    valor_vxmf: valorCompra,
                    juros_multa: 0,
                });
            }
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const getInputsOptions = () => {

        if (estabelecimentoId !== 0) {
            setLoading(true);
            let dataRequest = {
                estabelecimento_id: estabelecimentoId,
                papel_pessoa: componente === "Receita" ? "Cliente" : "Fornecedor",
            };
            api.GetFornecedores(dataRequest).then(response => {
                setClientesOptions(response.data);
                setLoading(false);

            });
            api.GetCategorias({...dataRequest, "tipo": type.includes("despesa") ? "Despesa" : "Receita"}).then(response => {
                setCategoriaOptions(response.data);
                setLoading(false);
            });
            api.GetCentroCustos(dataRequest).then(response => {
                setCentroCustoOptions(response.data);
                setLoading(false);
            });
            api.RetrieveContas(dataRequest).then(response => {
                setContasOptions(response.data);
                setLoading(false);
            });
        }
    };

    const handleParcelas = (skipIndex = false, skipRow = {}, alteredField = "") => {
        let _parcelas = [];
        let valorTotal = 0;
        let percentTotal = 0;
        let _totalValue = valorCompra;
        let parcelasQuantidade = selectedParcelamento === "À vista" ? 1 : selectedParcelamento;
        let parcelamento = selectedParcelamento === "À vista" ? 1 : selectedParcelamento;
        for (let i = 0; i < parcelamento; i++) {
            if ((skipIndex || typeof skipIndex === "number") && i === skipIndex) {
                if (alteredField === "valor") {
                    if (skipRow.valor >= valorCompra || skipRow.valor === 0) {
                        skipRow.error = true;
                        return;
                    }
                    _totalValue = valorCompra - skipRow.valor;
                    skipRow.percentual = parseFloat(formatterPercentage((skipRow.valor * 100) / valorCompra, 2));
                    percentTotal += skipRow.percentual;
                    skipRow.error = false;
                } else {
                    if (skipRow.percentual >= 100 || skipRow.percentual === 0) {
                        skipRow.error = true;
                        return;
                    }
                    percentTotal += parseFloat(skipRow.percentual);
                    skipRow.valor = parseFloat(formatterThousand(valorCompra * (skipRow.percentual / 100), true));
                    _totalValue -= skipRow.valor;
                    skipRow.error = false;
                }
                parcelasQuantidade = parcelamento - 1;
                _parcelas.push(skipRow);
                continue;
            }
            let valorParcela = 0;
            let percentParcela = 0;
            if (i === parcelamento - 1) {
                valorParcela = formatterThousand(_totalValue - valorTotal, true);
                percentParcela = formatterPercentage(100 - percentTotal, 2);
            } else {
                valorParcela = formatterThousand(_totalValue / parseFloat(parcelasQuantidade), true);
                percentParcela = formatterPercentage((valorParcela * 100) / valorCompra, 2);
            }
            let parcelaData = moment(selectedDataVencimento || moment(getCurrentDate()).toDate()).add(i, "months");
            _parcelas.push({
                "valor": parseFloat(valorParcela),
                "percentual": parseFloat(percentParcela),
                "data_vencimento": parcelaData.toDate(),
                "descricao": ""
            });
            valorTotal += parseFloat(valorParcela);
            percentTotal += parseFloat(percentParcela);
        }
        setParcelas(_parcelas);
    };

    const handleNavExtrato = () => {
        dispatch(changeOpenLancamento(""));
    };

    const checkDisabled = () => {
        return (
            loading ||
            !selectedDataCompra ||
            !descricaoCompra ||
            valorCompra === 0 ||
            (!selectedCategoria && !hasRateio) ||
            !selectedConta ||
            (repetirCompra && !configRepeticao) ||
            (repetirCompra && !configRepeticao.value.includes("diario") && !selectedCobrarSempre) ||
            (repetirCompra && !selectedPrimeiroVencimento) ||
            (!repetirCompra && !selectedParcelamento)

        );
    };

    const cleanReceitaInputs = () => {
        setLoading(false);
        setSelectedCliente(null);
        setSelectedDataCompra(currentMonthDate);
        setDescricaoCompra("");
        setValorCompra(0);
        setHasRateio(false);
        setRateios([{
            categoria: null,
            valor_total: 0,
            porcentagem: 0,
            centro_custo: null,
        }]);
        setSelectedCategoria(null);
        setSelectedCentroCusto(null);
        setCodigoReferencia("");
        setRepetirCompra(false);
        setConfigRepeticao(null);
        setSelectedCobrarSempre(null);
        setSelectedPrimeiroVencimento(currentMonthDate);
        setSelectedParcelamento(null);
        setSelectedFormaPagamento(null);
        setOpenParcelasModal(false);
        setParcelas([]);
        setSelectedDataVencimento(currentMonthDate);
        setSelectedConta(null);
        setHasRecebido(false);
        setObservacoes("");
        setAnexos([
            {
                forma_anexo: "Arquivo",
                arquivo: null,
                tipo_anexo: "Contrato",
                descricao: "",
            },
        ]);
    };

    const closeModal = () => {
        setOpen(false);
        if (openCompletarInfoDialog) {
            setOpenCompletarInfoDialog(false);
        }
        dispatch(changeOpenLancamento(""));
    };

    return (
        <React.Fragment>
            <CreateOptionModal
                openModal={openCreateModal}
                setOpenModal={setOpenCreateModal}
                option={optionCreateModal}
                getInputsOptions={getInputsOptions}
                configRecorrencia={configRecorrencia}
                setConfigRecorrencia={setConfigRecorrencia}
            />
            <RateioCentroCustoModal
                openModal={openRateioCentroCustoModal}
                setOpenModal={setOpenRateioCentroCustoModal}
                categoria={categoriaRateioCentroCusto}
                valorCategoria={valorCategoriaRateioCentroCusto}
                indexRateioCentroCusto={indexRateioCentroCusto}
                rateios={rateios}
                setRateios={setRateios}
                centroCustoOptions={centroCustoOptions}
            />
            <ParcelasModal
                openModal={openParcelasModal}
                setOpenModal={setOpenParcelasModal}
                formaPagamento={selectedFormaPagamento}
                valorPagamento={valorCompra}
                condicaoPagamento={selectedParcelamento}
                parcelas={parcelas}
                setParcelas={setParcelas}
                handleParcelas={handleParcelas}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={() => closeModal()}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                        backgroundImage: "none"
                    },
                }}
                data-testid="movimentacao-container"
            >
                <AppBar
                    sx={{
                        backgroundColor: temaEscuro
                            ? BLUE_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        borderBottom: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                        position: "relative",
                        boxShadow: "none"
                    }}
                >
                    <Toolbar>
                        <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                            Nova {componente}
                        </Typography>
                        <IconButton
                            autoFocus
                            color="inherit"
                            onClick={() => closeModal()}
                            aria-label="close"
                            sx={{
                                color: !temaEscuro && BLACK_LABEL_UX
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ overflow: "auto", maxHeight: "100vh" }}>
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro
                                ? PAPER_PADDING_THEME
                                : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro
                                ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${
                                isLargeScreen ? "120px" : "20px"
                            } 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span>
                                    <p className="title-container-p" data-testid="informacoes-compra-title">
                                        Informações da compra
                                    </p>
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item sx={{mr: "2rem"}}>
                                        <FilterSingleOption
                                            filterOptions={clienteOptions}
                                            selectedOption={selectedCliente}
                                            setSelectedOption={(e) => {
                                                setChangesList([...changesList, "clienteFornecedor"]);
                                                setPrevSelectedCliente(selectedCliente);
                                                setSelectedCliente(e);
                                            }}
                                            placeholder={componente === "Receita" ? "Cliente" : "Fornecedor"}
                                            width={"350px"}
                                            canCreate
                                            setOpenCreateModal={setOpenCreateModal}
                                            setOptionCreateModal={setOptionCreateModal}
                                            testId="cliente-input"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterDayMonthYear
                                            isRequired
                                            disableMinDate
                                            style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                            handleSubmit={handleDayMonthYear}
                                            value={selectedDataCompra}
                                            setState={setSelectedDataCompra}
                                            size={"small"}
                                            width={"250px"}
                                            iconPosition={"end"}
                                            label={"Data da compra"}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => {}}
                                            setSearchValue={setDescricaoCompra}
                                            searchValue={descricaoCompra}
                                            label={"Descrição"}
                                            width={"575px"}
                                            isRequired
                                            testId="descricao-input"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputCurrency
                                            title={"Valor (R$)"}
                                            valor={valorCompra}
                                            setValor={setValorCompra}
                                            changesList={changesList}
                                            setChangesList={setChangesList}
                                            setPrevValor={setPrevValorCompra}
                                            dataTestId="valor-input"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            data-testid="rateio-checkbox"
                                            checked={hasRateio}
                                            icon={<CircleOutlinedIcon/>}
                                            checkedIcon={<CircleIcon/>}
                                            onChange={(event) => {
                                                setChangesList([...changesList, "hasRateio"]);
                                                setPrevHasRateio(hasRateio);
                                                setHasRateio(event.target.checked);
                                            }}
                                        />
                                        <a style={{
                                            ...styleText,
                                            color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                        }}>Habilitar rateio</a>
                                    </Grid>
                                    <Grid item sx={{mr: "2rem"}}>
                                        <FilterSingleOption
                                            isRequired={!hasRateio}
                                            sx={{borderRadius: 0}}
                                            filterOptions={categoriaOptions}
                                            selectedOption={selectedCategoria}
                                            setSelectedOption={(e) => {
                                                setChangesList([...changesList, "categoria"]);
                                                setPrevSelectedCategoria(selectedCategoria);
                                                setSelectedCategoria(e);
                                            }}
                                            placeholder={"Categoria"}
                                            width={"350px"}
                                            disabled={hasRateio}
                                            canCreate
                                            setOpenCreateModal={setOpenCreateModal}
                                            setOptionCreateModal={setOptionCreateModal}
                                            testId="categoria-input"
                                        />
                                    </Grid>
                                    <Grid item sx={{mr: "2rem"}}>
                                        <FilterSingleOption
                                            filterOptions={centroCustoOptions}
                                            selectedOption={selectedCentroCusto}
                                            setSelectedOption={(e) => {
                                                setChangesList([...changesList, "centroCusto"]);
                                                setPrevSelectedCentroCusto(selectedCentroCusto);
                                                setSelectedCentroCusto(e);
                                            }}
                                            placeholder={"Centro de custo"}
                                            width={"350px"}
                                            canCreate
                                            setOpenCreateModal={setOpenCreateModal}
                                            setOptionCreateModal={setOptionCreateModal}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputSearch
                                            functionProps={() => {}}
                                            setSearchValue={setCodigoReferencia}
                                            searchValue={codigoReferencia}
                                            className="input-text"
                                            label={"Código de referência"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {hasRateio && (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <a>Informar categoria e centro de custo</a>
                                    </Grid>
                                    <Grid item xs={12} sx={{
                                        border: 0,
                                        borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                        mb: 2.5,
                                        ml: 2,
                                    }}/>
                                    <RateiosReceita
                                        rateios={rateios}
                                        setRateios={setRateios}
                                        categoriaOptions={categoriaOptions}
                                        centroCustoOptions={centroCustoOptions}
                                        valorCompra={valorCompra}
                                        setOpenCreateModal={setOpenCreateModal}
                                        setOptionCreateModal={setOptionCreateModal}
                                        setOpenRateioCentroCustoModal={setOpenRateioCentroCustoModal}
                                        setCategoriaRateioCentroCusto={setCategoriaRateioCentroCusto}
                                        setValorCategoriaRateioCentroCusto={setValorCategoriaRateioCentroCusto}
                                        setIndexRateioCentroCusto={setIndexRateioCentroCusto}
                                    />
                                </React.Fragment>

                            )}
                            <Grid item xs={repetirCompra ? 2 : 12}>
                                <a style={{
                                    ...styleText,
                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                }}>Repetir lançamento?</a>
                                <Checkbox
                                    data-testid="repetir-compra-checkbox"
                                    checked={repetirCompra}
                                    icon={<CircleOutlinedIcon/>}
                                    checkedIcon={<CircleIcon/>}
                                    onChange={(event) => {
                                        setChangesList([...changesList, "repetirCompra"]);
                                        setPrevRepetirCompra(repetirCompra);
                                        setRepetirCompra(event.target.checked);
                                    }}
                                />
                            </Grid>
                            {
                                repetirCompra && (
                                    <Grid item xs={10} data-testid="repetir-compra-container">
                                        <FilterSingleOption
                                            isRequired
                                            testId="repetir-compra-select"
                                            filterOptions={configRecorrencia}
                                            selectedOption={configRepeticao}
                                            setSelectedOption={(e) => {
                                                setChangesList([...changesList, "configRepeticao"]);
                                                setPrevConfigRepeticao(configRepeticao);
                                                setConfigRepeticao(e);
                                            }}
                                            placeholder={"Configuração de repetição"}
                                            width={"450px"}
                                            disableClearable
                                            canCreate
                                            setOpenCreateModal={setOpenCreateModal}
                                            setOptionCreateModal={setOptionCreateModal}
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sx={{marginTop: 2}} data-testid="condicao-pagamento-title" >
                                <span>
                                    <p className="title-container-p">Condição de pagamento</p>
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    {
                                        repetirCompra ? (
                                            <React.Fragment>
                                                {
                                                    !configRepeticao.value.includes("diario") && (
                                                        <Grid item>
                                                            <FilterSingleOption
                                                                isRequired
                                                                filterOptions={cobrarSempreOption}
                                                                selectedOption={selectedCobrarSempre}
                                                                setSelectedOption={(e) => {
                                                                    setChangesList([...changesList, "cobrarSempre"]);
                                                                    setPrevCobrarSempre(selectedCobrarSempre);
                                                                    setSelectedCobrarSempre(e);
                                                                }}
                                                                placeholder={"Cobrar sempre no"}
                                                                width={"200px"}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                <Grid item>
                                                    <FilterDayMonthYear
                                                        isRequired
                                                        disableMinDate
                                                        style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                        handleSubmit={handleDayMonthYear}
                                                        value={selectedPrimeiroVencimento}
                                                        setState={setSelectedPrimeiroVencimento}
                                                        size={"small"}
                                                        width={"250px"}
                                                        iconPosition={"end"}
                                                        label={"Primeiro vencimento"}
                                                        forceRerender
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Grid item>
                                                    <FilterSingleOption
                                                        isRequired
                                                        filterOptions={optionsParcelamento}
                                                        selectedOption={selectedParcelamento}
                                                        setSelectedOption={(e) =>{
                                                            setChangesList([...changesList, "parcelamento"]);
                                                            setPrevParcelamento(selectedParcelamento);
                                                            setSelectedParcelamento(e);
                                                        }}
                                                        placeholder={"Parcelamento"}
                                                        width={"200px"}
                                                        testId="parcelamento-input"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FilterDayMonthYear
                                                        isRequired
                                                        disableMinDate
                                                        style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                                        handleSubmit={handleDayMonthYear}
                                                        value={selectedDataVencimento}
                                                        setState={setSelectedDataVencimento}
                                                        size={"small"}
                                                        width={"250px"}
                                                        iconPosition={"end"}
                                                        label={"Vencimento"}
                                                    />
                                                </Grid>

                                            </React.Fragment>
                                        )
                                    }
                                    <Grid item>
                                        <FilterSingleOption
                                            filterOptions={formaPagamentoOptions}
                                            selectedOption={selectedFormaPagamento}
                                            setSelectedOption={(e) => {
                                                setChangesList([...changesList, "formaPagamento"]);
                                                setPrevFormaPagamento(selectedFormaPagamento);
                                                setSelectedFormaPagamento(e);
                                            }}
                                            placeholder={"Forma de pagamento"}
                                            testId="forma-pagamento-input"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterSingleOption
                                            isRequired
                                            disabled={conta !== null}
                                            filterOptions={contasOptions}
                                            selectedOption={selectedConta}
                                            setSelectedOption={(e) => {
                                                setChangesList([...changesList, "conta"]);
                                                setPrevConta(selectedConta);
                                                setSelectedConta(e);
                                            }}
                                            placeholder={
                                            hasRecebido ?
                                                (componente === "Receita" ? "Conta de recebimento *" : "Conta de pagamento *")
                                                :
                                                (componente === "Receita" ? "Conta de recebimento" : "Conta de pagamento")
                                            }
                                            testId="conta-input"
                                        />
                                    </Grid>
                                    {
                                        !repetirCompra && (
                                            <Grid item>
                                                <Checkbox
                                                    checked={hasRecebido}
                                                    onChange={(event) => {
                                                        setChangesList([...changesList, "recebido"]);
                                                        setPrevRecebido(hasRecebido);
                                                        setHasRecebido(event.target.checked);
                                                    }}
                                                />
                                                <a style={{
                                                    ...styleText,
                                                    color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
                                                    fontSize: "12px",
                                                }}>{componente === "Receita" ? "Recebido" : "Pago"}</a>
                                            </Grid>
                                        )
                                    }
                                    {
                                        !repetirCompra && selectedParcelamento !== "À vista" && (
                                            <Grid item xs={12}>
                                                <DefaultButton
                                                    testId={"editar-parcelas-button"}
                                                    title={"Editar parcelas"}
                                                    onClick={() => setOpenParcelasModal(true)}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px", marginTop: 3}}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={(e, value) => setSelectedTab(value)}
                                    TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                    className="buttonTabs"
                                >
                                    <Tab
                                        data-testid="observacoes-tab"
                                        value={"observacoes"}
                                        label="Observações"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                    />
                                    <Tab
                                        data-testid="anexos-tab"
                                        value={"anexo"}
                                        label="Anexo"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                    />
                                </Tabs>
                            </Grid>
                            {selectedTab === "observacoes" ? (
                                <Grid item xs={12}>
                                    <TextField
                                        data-testid="observacoes-textfield"
                                        label={"Observações"}
                                        id="input-observacoes-textfield"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        maxRows={6}
                                        value={observacoes}
                                        onChange={(event) => {
                                            setObservacoes(event.target.value);
                                        }}
                                        sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                    />
                                </Grid>
                            ) : (
                                <Anexos
                                    anexos={anexos}
                                    setAnexos={setAnexos}
                                    setPrevAnexos={setPrevAnexos}
                                />
                            )}
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2}}>
                                <DefaultButton
                                    title={"Voltar"}
                                    onClick={handleNavExtrato}
                                />
                                <Grid sx={{display: "flex"}}>
                                    <DefaultButton
                                        title="Salvar"
                                        testId="save-button"
                                        onClick={() => postCreateReceita(true)}
                                        disabled={checkDisabled()}
                                        loading={loading}
                                        borderRadius={{borderRadius: "5px 0 0 5px"}}
                                        
                                        variant="yellow"
                                    />
                                    <SaveOptions
                                        disabled={checkDisabled()}
                                        action={postCreateReceita}
                                        cleanStates={cleanReceitaInputs}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default Movimentacao;