import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "./DefaultButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import {useNavigate} from "react-router-dom";


export default function OptionsDetalheConta(props) {

    const {setOpenModalConciliacao, row, setItemSelected} = props;

    const nav = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);


    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const handleOpenModalEditMovimentacao = (row) => {
        // row.id => id da baixa
        const tabAux = row.tipo_movimentacao === "Despesa" ? "contas_pagar" : "contas_receber";
        nav("/extrato_movimentacoes", { state:
            {detalhe: 
                { 
                    id_lancamento: row.parcela_id,
                    tipoLancamento: row.tipo_movimentacao,
                    situacaoMov: row.situacao,
                    tab: tabAux
                } 
            } 
        });
    };
    
    return (
        <React.Fragment>
            <DefaultButton
                title="Ações"
                startIcon={moreVertIcon}
                onClick={handleClick}
                testId="options-menu"
                className={props.className}
                size="small"
            />
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >   
                {
                    row.descricao_banco?.includes("Não foi conciliado com lançamento bancário") ? (
                        <MenuItem
                            // onClick={() => handleOpenModalEditMovimentacao(row)}
                        >
                            Editar
                        </MenuItem>
                    ) : (
                        <React.Fragment>
                            <MenuItem
                                onClick={() => {
                                    setOpenModalConciliacao(true);
                                    setItemSelected(row);
                                }}
                                data-testid="desconciliar-button"
                            >
                                Desfazer conciliação
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleOpenModalEditMovimentacao(row)}
                            >
                                Editar
                            </MenuItem>
                        </React.Fragment>
                    )
                }
                </Menu>
        </React.Fragment>
    );
}