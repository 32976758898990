import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {
    formatterCurrency,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    WHITE_THEME_BLACK,
    PAPER_PADDING_THEME,
    RED_INFO,
    INFO_THEME,
    GRAY_BORDER_TABLE,
    COLOR_LABEL_INPUT,
    WHITE_TABLE,
    BORDER_TABLE,
    ITENS_PAINEL,
    YELLOW_BG_UX,
    DARK_YELLOW_TEXT_UX,
    DARK_YELLOW_BG_UX,
    LIGHT_YELLOW_TEXT_UX,
    LIGHT_YELLOW_BG_UX,
    manualCurrencyFormatter, LINE_TABLE
} from "../../shared/utils";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import { changeOpenAccordionSelected } from "../../actions/StoreActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import DataGrid from "../DataGrid/DataGrid";
import Box from "@mui/material/Box";
import OptionsDetalheConta from "../Buttons/OptionsDetalheConta";
import DesfazerConciliacao from "../Modal/DesfazerConciliacao";
import {showSnackMessage} from "../../actions/SnackActions";
import api from "../../axios";


const MovimentacoesConta = (props) => {
    
    const dispatch = useDispatch();

    const {bancoLogo, apelidoConta, saldoConta, detalhesData, isCartaoCredito, isContaCorrente, getDetalhesConta} = props;

    const [openModalConciliacao, setOpenModalConciliacao] = useState(false);
    const [itemSelected, setItemSelected] = useState("");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const openAccordionSelected = useSelector(state => state.StoreReducer.openAccordionSelected);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    const handleChange = (panel) => (event, isExpanded) => {
        dispatch(changeOpenAccordionSelected(isExpanded ? panel : ""));
    };

    const getBackgroundConciliacao = (conciliacao) => {
        if (conciliacao === "Manual" || conciliacao === "Automático") {
            return GRAY_LABEL_UX;
        } else if (conciliacao === "Não Conciliado") {
            return RED_INFO;
        }
    };

    const columns = [
        { field: "descricao_vxmf", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        { 
            field: "valor_vxmf",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10}}>
                        <span style={{color: params.row.valor_vxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>
                            {formatterCurrency(params.row.valor_vxmf, 2)}
                        </span>
                    </div>
                );
            },
        },
        { field: "descricao_banco", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        { 
            field: "valor_banco",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{color: params.row.valor_banco < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>
                        {formatterCurrency(params.row.valor_banco, 2)}
                    </span>
                );
            },
        },
        {
            field: "agrupamento",
            headerName: "",
            flex: 1,
            align: "center",
            headerAlign: "center",
            zIndex: 1000,
            colSpan: 2,
            renderCell: (params) => {
                if (
                    params.rowNode.type === "group" && params.field === params.rowNode.groupingField
                ) {
                    return "";
                }
                return (
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <span>
                            <b>Lançamentos agrupados referentes à: {params.value.categoria} com valor de </b>
                            <a style={{color: params.value.valor < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>R${params.value.valor}</a>
                        </span>
                    </Box>
                );
            }
        },
        {
            field: "is_conciliado",
            headerName: "Conciliação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{backgroundColor: getBackgroundConciliacao(params.row.is_conciliado), padding: 8, borderRadius: "3px", color: temaEscuro ? WHITE_TABLE : GRAY_BG_UX}}>
                        {params.row.is_conciliado}
                    </span>
                );
            }
        },
        {
            field: "acoes",
            headerName: "",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <OptionsDetalheConta 
                        setOpenModalConciliacao={setOpenModalConciliacao}
                        row={params.row}
                        setItemSelected={setItemSelected}
                    />
                );
            }
        },
    ];

    const columnsCartaoCredito = [
        { field: "descricao_vxmf", headerName: "Descrição", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "categorias",
            headerName: "Categoria",
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const categorias = params.value;
                return (
                    <span
                        style={{
                            backgroundColor: GRAY_LABEL_UX,
                            padding: 8,
                            borderRadius: "3px",
                            color: temaEscuro ? LINE_TABLE : WHITE_TABLE
                        }}
                        title={categorias.length > 1 ? categorias.join(", ") : categorias}
                    >
                        {categorias.length > 1 ? `${categorias.length} categorias` : categorias}
                    </span>
                );
            },
        },
        { field: "parcelas", headerName: "Parcela", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "valor_vxmf",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10}}>
                        <span style={{color: params.row.valor_vxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>
                            {formatterCurrency(params.row.valor_vxmf, 2)}
                        </span>
                    </div>
                );
            },
        },
        {
            field: "acoes",
            headerName: "",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <OptionsDetalheConta
                        setOpenModalConciliacao={setOpenModalConciliacao}
                        row={params.row}
                        setItemSelected={setItemSelected}
                    />
                );
            }
        },
    ];

    const columnGroupingModel = [
        {
            groupId: "Lançado no VXMF",
            children: [ {field: "extrato_id"}, { field: "descricao_vxmf" }, { field: "valor_vxmf" }],
            headerAlign: "center",
            renderHeaderGroup: (params) => (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                    <span style={{color: temaEscuro ?? WHITE_TABLE, fontWeight: "bold"}}>{params.groupId}</span>
                </div>
            )
        },
        {
            groupId: "Movimentado no banco",
            headerAlign: "center",
            children: [{ field: "descricao_banco" }, { field: "valor_banco" }, { field: "conciliacao" }],
            renderHeaderGroup: (params) => (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                    <span style={{color: temaEscuro ?? WHITE_TABLE, fontWeight: "bold"}}>{params.groupId}</span>
                </div>
            )
        },
    ];

    const desfazerConciliacao = (state) => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            id_mov: itemSelected.id,
            state: state
        };

        api.PostDesfazerConciliacao(dataRequest).then(() => {
            getDetalhesConta();
            dispatch(showSnackMessage({ message: "Operação realizado com sucesso!", severity: "success" }));
        }).catch(() => {
            dispatch(showSnackMessage({ message: "Algo deu errado! Tente novamente mais tarde", severity: "error" }));
        });
    };

    return (
        <React.Fragment>
            <DesfazerConciliacao 
                open={openModalConciliacao} 
                setOpen={setOpenModalConciliacao}
                itemSelected={itemSelected}
                desfazerConciliacao={desfazerConciliacao}
            />
            <Grid container spacing={3} data-testid="container-movimentacoes">
                {!isCartaoCredito && !isContaCorrente && (
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <img src={bancoLogo} />
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <a style={{fontSize: "18px", fontWeight: "bold"}}>{apelidoConta}</a>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <a style={{fontSize: "12px"}}>Saldo atual no Menu financeiro: {formatterCurrency(saldoConta, 2)}</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {/*<Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>*/}
                {/*    <DefaultButton title={"Acessar fluxo de caixa"} />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <React.Fragment>
                        {detalhesData.map((item) => (
                            <Accordion
                                key={item.data}
                                expanded={openAccordionSelected === item.data}
                                onChange={handleChange(item.data)}
                                sx={{ 
                                    marginBottom: 1, 
                                    backgroundColor: "transparent", 
                                    border: !isCartaoCredito && (item.total_vxmf !== item.total_banco) ? `1px solid ${YELLOW_BG_UX}` : `1px solid ${temaEscuro ? COLOR_LABEL_INPUT : GRAY_BORDER_TABLE}`,
                                    borderRadius: "5px",
                                    boxShadow: "none"
                                }}
                                data-testid="movimentacao-accordion"
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{color: !temaEscuro && GRAY_BG_UX }} />}
                                    sx={{ 
                                        backgroundColor: temaEscuro ? PAPER_PADDING_THEME : "transparent",
                                    }}
                                >
                                    <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Grid item xs={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", gap: 1}}>
                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>{item.data}</Typography>
                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX, width: "200px"}}>{item.dia_semana}</Typography>
                                            {!isCartaoCredito && (item.total_vxmf !== item.total_banco) && (
                                                <Typography sx={{
                                                    color: temaEscuro ? DARK_YELLOW_TEXT_UX : LIGHT_YELLOW_TEXT_UX,
                                                    backgroundColor: temaEscuro ? DARK_YELLOW_BG_UX : LIGHT_YELLOW_BG_UX,
                                                    fontSize: "12px",
                                                    padding: "2px",
                                                    borderRadius: "5px"
                                                }}>
                                                    Saldo com diferença de {formatterCurrency(item.total_diferenca, 2)}
                                                </Typography>
                                            )}
                                        </Grid>
                                        {item.total_vxmf !== item.total_banco ?  
                                            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignItems: "center", marginRight: 2, gap: 3}}>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end", gap: 1}}>
                                                    <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX}}>
                                                        VXMF 
                                                    </Typography>
                                                    <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold"}}>
                                                        {formatterCurrency(item.total_vxmf, 2)}
                                                    </Typography>
                                                </div>
                                                {!isCartaoCredito && (
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end", gap: 1}}>
                                                        <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX}}>
                                                            Banco
                                                        </Typography>
                                                        <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold"}}>
                                                            {formatterCurrency(item.total_banco, 2)}
                                                        </Typography>
                                                    </div>
                                                    )
                                                }
                                            </Grid>
                                        :
                                            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignItems: "center", marginRight: 2, gap: 1}}>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end", gap: 1}}>
                                                <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_LABEL_UX}}>
                                                    VXMF = Banco
                                                </Typography>
                                                <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX, fontWeight: "bold"}}>
                                                    {formatterCurrency(item.total_vxmf, 2)}
                                                </Typography>
                                                </div>
                                            </Grid>
                                        }
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: "0px 0px 0px 0px",
                                        backgroundColor: temaEscuro && PAPER_PADDING_THEME,
                                    }}
                                >
                                    <div style={{width: "100%"}}>
                                        {openAccordionSelected === item.data && 
                                            <DataGrid
                                                columns={!isCartaoCredito ? columns : columnsCartaoCredito}
                                                data={item.detalhes}
                                                row={(row) => row.id}
                                                columnGroupingModel={!isCartaoCredito ? columnGroupingModel : null}
                                                columnGroupHeaderHeight={!isCartaoCredito ? 36 : null}
                                                rowGroupingColumns={!isCartaoCredito ? ["agrupamento"] : null}
                                            />
                                        }
                                    </div>
                                    <Grid container sx={{padding: 2, marginTop: 2}}>
                                        <Grid item xs={6}>
                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX, fontSize: "18px", fontWeight: "bold"}}>
                                                Totais do dia
                                            </Typography>
                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX, fontSize: "14px", fontWeight: "bold"}}>
                                                {item.data}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>                                    
                                            <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
                                                {!isCartaoCredito ? (
                                                    <React.Fragment>
                                                        <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                                VXMF (R$)
                                                            </Typography>
                                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                                {manualCurrencyFormatter(item.total_vxmf, 2)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                                Banco (R$)
                                                            </Typography>
                                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                                {manualCurrencyFormatter(item.total_banco, 2)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                                Diferença (R$)
                                                            </Typography>
                                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                                {manualCurrencyFormatter(item.total_diferenca, 2)}
                                                            </Typography>
                                                        </Grid>
                                                    </React.Fragment>
                                                ) : (
                                                    <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                        <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                            Saldo final do dia (R$)
                                                        </Typography>
                                                        <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                            {manualCurrencyFormatter(item.total_vxmf, 2)}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </React.Fragment>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default MovimentacoesConta;