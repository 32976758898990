import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItems from "./listItems";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useDispatch, useSelector} from "react-redux";
import {Outlet} from "react-router-dom";
import api from "../../axios";
import {
	changeToken,
	changeEmail,
	changeIsAdmin,
	logoutUser,
	changeName,
	changeResetarSenha,
	changeTema,
	changeDeviceToken,
	changeIsCommonUser,
	changeIsOperator,
	changeIsApprover,
	changeNotifications,
	changeImagePerfil,
} from "../../actions/AuthActions";
import {
	changeModified,
	changeEstabelecimento,
	changeStore,
	changeLojas,
	changeStoreName,
} from "../../actions/StoreActions";
import Loading from "../Loading/Loading";
import {
	GRAY_LABEL_UX_THEME,
	BLACK_TABLE_PERFIL,
	DASH_BAR_COLOR,
	BLUE_THEME,
	LINE_TABLE,
	GRAY_DATE_UX,
	GRAY_BG_UX,
	BORDER_BUTTON,
	enumCommonUser,
	enumOperator,
	enumApprover,
	translateAutocomplete,
	WHITE_TABLE,
	BORDER_PAPER,
	BORDER_BLACK_THEME_CARD,
	styleDarkButton,
	styleButton,
	YELLOW_BG_UX,
} from "../../shared/utils";
import "./NavBar.css";
import ReactGA from "react-ga4";
import Tour from "reactour";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { steps } from "./TourNavbar";
import Paper from "@mui/material/Paper";
import {getFirebaseToken} from "../../firebase";
import {showSnackMessage} from "../../actions/SnackActions";
import MenuMobile from "./MenuMobile";
import Button from "@mui/material/Button";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ModalSelectedLoja from "../Modal/ModalSelectedLoja";
import ModalChangeLoja from "../Modal/ModalChangeLoja";
import { useHotkeys } from "react-hotkeys-hook";
import CreateOptionModal from "../Modal/CreateOptionModal";
import CertificadoExpiradoModal from "../Modal/CertificadoExpiradoModal";
import UpdateNewsModal from "../Modal/UpdateNewsModal";
import OpenModaisEXM from "../../components/OpenModaisEXM/OpenModaisEXM";
import {changeOpenLancamento} from "../../actions/StoreActions";


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open"})(
	({ theme, open, temaescuro }) => ({
		"& .MuiDrawer-paper": {
			backgroundColor: (temaescuro === "true") ? BLUE_THEME : LINE_TABLE,
			position: "relative",
			whiteSpace: "nowrap",
			width: drawerWidth,
			borderRadius: "0px 20px 20px 0px",
			border: (temaescuro === "true") ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: "border-box",
			...(!open && {
				overflowX: "hidden",
				transition: theme.transitions.create("width", {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up("sm")]: {
					width: theme.spacing(9),
				},
			}),
		},
	}),
);


const NavBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [open, setOpen] = useState(true);
	const [openTour, setOpenTour] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [loadingEstabelecimentos, setLoadingEstabelecimentos] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [activeButton, setActiveButton] = useState("dashboard");
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [openModalChangeLoja, setOpenModalChangeLoja] = useState(false);
	const [inputLoja, setInputLoja] = useState("");

	const name = useSelector(state => state.AuthReducer.name);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isDesktop = useSelector(state => state.AuthReducer.isDesktop);
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const storeName = useSelector(state => state.StoreReducer.storeName);
	const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
	const openLancamento = useSelector(state => state.StoreReducer.openLancamento);

	const excludedSelectors = [".button_2", ".button_12", ".button_13", ".button_14"];
	const filteredSteps = isAdmin ? steps : steps.filter(step => !excludedSelectors.includes(step.selector));
	const [openSubCadastros, setOpenSubCadastros] = useState(false);
	const [openSubCompras, setOpenSubCompras] = useState(false);
	const [openSubFinanceiro, setOpenSubFinanceiro] = useState(false);
	const [estabelecimento, setEstabelecimento] = useState();
	const [estabelecimentos, setEstabelecimentos] = useState([]);

	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [optionCreateModal, setOptionCreateModal] = useState("");
	
	const [openCertificateWarning, setOpenCertificateWarning] = useState(false);
	const [certificadosExpirados, setCertificadosExpirados] = useState([]);

	const [openUpdateModal, setOpenUpdateModal] = useState(false);

	const token = Cookies.get("tk");

	// IOS does not support the Notification API https://caniuse.com/?search=Notification
	const isNotificationSupported = () => (
		"Notification" in window && "serviceWorker" in navigator && "PushManager" in window
	);

	const handleChangeClick = (local) => {
		setActiveButton(local);
		if (!isDesktop) {
			setIsMenuOpen(false);
		}
	};

	useEffect(() => {
		if (estabelecimentoId !== 0 && location.pathname !== "/certificados" && location.pathname !== "/upload_certificado") {
			let dataRequest = {
				type: "certificado_expirado",
				estabelecimento_id: estabelecimentoId
			};
			
			api.GetCertificados(dataRequest).then(response => {
				let certificados_expirados = response.data.certificados_expirados;
				if(certificados_expirados.length > 0){
					setCertificadosExpirados(certificados_expirados);
					setOpenCertificateWarning(true);
				}
			}).catch((error) => {
				if (error.response.status === 403) {
					dispatch(showSnackMessage({message: "Atenção! Você não possui permissão para esta ação.", severity: "info"}));
				} else {
					dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
				}
			});
		}
	}, [estabelecimentoId, location]);

	useEffect(() => {
		if (token && storeId) {
			setLoadingEstabelecimentos(true);
			api.GetLojas().then(response => {
				let data = response.data;
				const lojas = [...new Set(data.map((obj) => obj.loja_nome))];
				dispatch(changeLojas(lojas));
				setLoadingEstabelecimentos(false);
			}).catch(() => {
				setLoadingEstabelecimentos(false);
			});
		}
	}, []);

	useEffect(() => {
		if (storeId !== null && !isNaN(storeId)) {
			checkNotificationPermission();
			checkLogged();
		}
	}, [storeId]);

	useEffect(() => {
		let path = location.pathname;
		let title = path.replace("/", "");
		ReactGA.send({ hitType: "pageview", page: path, title: title });
		ReactGA.event({
			category: title,
			action: `Acessou a página ${path}`,
			label: name,
		});
	}, [location]);

	useEffect(() => {
        setEstabelecimento(estabelecimentos[0]);
    }, [estabelecimentos]);


	const handleChangeLoja = () => {
		if (inputLoja !== storeName) {
			setOpenModalChangeLoja(true);
			setOpenModal(false);
		} else {
			setOpenModal(false);
			dispatch(showSnackMessage({
				message: "Você já está logado nessa loja!",
				severity: "info"
			}));
		}
	};

	const changeLoja = () => {
		setLoadingEstabelecimentos(true);
		const dataRequest = { loja_nome: inputLoja };
		api.EnterWithLoja(dataRequest)
			.then(response => {
				const data = response.data;
				dispatch(changeStore(data.loja_id));
				dispatch(changeStoreName(data.loja_nome));
				handleChangeClick("dashboard");
				setOpenModalChangeLoja(false);
				setLoadingEstabelecimentos(false);
				navigate("/dashboard");
			})
			.catch(() => {
				logout();
				setLoadingEstabelecimentos(false);
				dispatch(showSnackMessage({
					message: "Algo deu errado! Tente novamente mais tarde",
					severity: "error"
				}));
        });
	};

	const checkNotificationPermission = () => {
		if(isNotificationSupported()){
			if (Notification.permission !== "granted") {
				dispatch(showSnackMessage({
					message: "Por favor, aceite a permissão para receber notificações nesse site!",
					severity: "warning"
				}));
				Notification.requestPermission().then(()=> {
					dispatch(showSnackMessage({
						message: "Permissão habilitada com sucesso!",
						severity: "success"
					}));
				});
			}
		}

	};

	const updateDeviceToken = (token) => {
		const data = {
			device_token: token
		};
		api.PutUpdateDeviceToken(data).then(response => {
			dispatch(changeDeviceToken(response.data.device_token));
		}).catch(() => {
			// error
		});
	};

	const getDeviceToken = (previousToken = null) => {
		getFirebaseToken().then((currentToken) => {
			if(currentToken){
				dispatch(changeDeviceToken(currentToken));
				if(token?.length && previousToken !== currentToken) {
					updateDeviceToken(currentToken);
				}
			}
		}).catch((e) => {
			// eslint-disable-next-line no-console
			console.error(`error when trying to get device token: ${e}`);
		});
	};

	const checkLogged = () => {
		const dataRequest = {
			loja_id: storeId
		};
		if (token?.length) {
			api.UserInfo(dataRequest).then(response => {
				let resetarSenha = response.data.resetar_senha;
				let isAdmin = response.data.admin;
				let email = response.data.email;
				let name = response.data.nome;
				let deviceToken = response.data.device_token;
				// let lojaPadrao = response.data.loja_padrao;
				// let lojaNome = response.data.loja_nome;
				let _temaEscuro = response.data.tema_escuro;
				let userGroup = response.data.user_group;
				let notifications = response.data.notifications;
				let estabelecimentos = response.data.estabelecimentos;
				let imagePerfil = response.data.s3_path_image;
				let updatesModal = response.data.mostrar_atualizacoes;
				
				setEstabelecimentos(estabelecimentos);
				dispatch(changeEstabelecimento(estabelecimentos[0].id));
				if (userGroup.includes(enumCommonUser)) {
					dispatch(changeIsCommonUser(true));
				}
				if (userGroup.includes(enumOperator)) {
					dispatch(changeIsOperator(true));
				}
				if (userGroup.includes(enumApprover)) {
					dispatch(changeIsApprover(true));
				}

				dispatch(changeNotifications(notifications));

				ReactGA.set({ userId: email });
				ReactGA.gtag("set", "user_properties", {
					name: name,
				});
				if(deviceToken != null){
					getDeviceToken(deviceToken);
				}else{
					getDeviceToken();
				}
				dispatch(changeName(name));
				dispatch(changeEmail(email));
				dispatch(changeIsAdmin(isAdmin));
				dispatch(changeTema(_temaEscuro));
				dispatch(changeToken(token));
				dispatch(changeImagePerfil(imagePerfil));
				setOpenUpdateModal(updatesModal);
				if(resetarSenha){
					dispatch(changeResetarSenha(resetarSenha));
					navigate("/mudar_senha");
				}

			}).catch(() => {
				logout();
			});
		}
		else{
			getDeviceToken();
			dispatch(logoutUser());
		}
	};

	const handleChange = (obj) => {
		dispatch(changeModified(""));
		setEstabelecimento(obj);
		dispatch(changeEstabelecimento(obj.id));
	};

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const logout = () =>{
		api.Logout().then(() => {
			dispatch(logoutUser());
			window.location.reload();
		}).catch(async () => {
			dispatch(logoutUser());
			window.location.reload();
		});
	};

	const changeOpen = () => {
		setStartAt(0);
		setOpenTour(!openTour);
		setOpenSubCadastros(true);
		setOpenSubCompras(true);
		setOpenSubFinanceiro(true);
	};

	useHotkeys("alt+r, alt+d, alt+t, alt+p, alt+l, alt+w, alt+f, alt+c", (keyboardEvent, shortcutInfo) => keyboardShortCutAction(shortcutInfo.hotkey.replaceAll(" ", ""), keyboardEvent));

	const keyboardShortCutAction = (shortcut, keyboardEvent) => {
		keyboardEvent.preventDefault();
		switch (shortcut) {
			case "alt+r":
				dispatch(changeOpenLancamento("receita"));
				break;
			case "alt+d":
			case "alt+p":
				dispatch(changeOpenLancamento("despesa"));
				break;
			case "alt+t":
				dispatch(changeOpenLancamento("transferencia"));
				break;
			case "alt+l":
				setOpenCreateModal(true);
				setOptionCreateModal("cliente");
				break;
			case "alt+w":
				setOpenCreateModal(true);
				setOptionCreateModal("transportadora");
				break;
			case "alt+f":
				setOpenCreateModal(true);
				setOptionCreateModal("fornecedor");
				break;
			case "alt+c":
				dispatch(changeOpenLancamento("compra"));
				break;
			default:
				break;
		}
	};

	return (
		<React.Fragment>
			<ModalSelectedLoja
				openModal={openModal}
				setOpenModal={setOpenModal}
				inputLoja={inputLoja}
				setInputLoja={setInputLoja}
				handleChangeLoja={handleChangeLoja}
			/>
			{ token && storeName ? (
				<React.Fragment>
					<ModalChangeLoja
						openModal={openModalChangeLoja}
						setOpenModal={setOpenModalChangeLoja}
						inputLoja={inputLoja}
						handleChangeLoja={changeLoja}
					/>
					<CreateOptionModal
						openModal={openCreateModal}
						setOpenModal={setOpenCreateModal}
						option={optionCreateModal}
						getCadastroInfo={() => ""}
						getInputsOptions={() => ""}
					/>
					<CertificadoExpiradoModal
						openModal={openCertificateWarning}
						setOpenModal={setOpenCertificateWarning}
						infoTitle="CERTIFICADO(S) PRESTES A EXPIRAR"
						certificadosExpirados={certificadosExpirados}
					/>
					<UpdateNewsModal
						openModal={openUpdateModal}
						setOpenModal={setOpenUpdateModal}
					/>
					<OpenModaisEXM openEXM={openLancamento} />
					{
						isDesktop ? (
							<Box sx={{ display: "flex", height: "100vh", backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME}}>
								<Drawer variant="permanent" open={open} temaescuro={temaEscuro?.toString()} data-testid="navbar">
									<Tour
										steps={filteredSteps}
										isOpen={openTour}
										onRequestClose={changeOpen}
										accentColor={DASH_BAR_COLOR}
										startAt={startAt}
										rounded={5}
										{...( temaEscuro ? {className: "tour"} : {})}
										badgeContent={(curr, tot) => `${curr}/${tot}`}
									/>
									<Box sx={{...styles.box, marginY: 1}}>
										{
											open ? (
												// #botao tour e botao fecha
												<React.Fragment>
													<IconButton onClick={toggleDrawer} data-testid="icon-drawer" style={{...styles.drawerButton, ...styles.drawerBtOpen, color: temaEscuro && GRAY_DATE_UX}}>
														<ChevronLeftIcon />
													</IconButton>
													<IconButton  style={{...styles.drawerButtonTour, ...styles.drawerBtOpen, padding: "5px", color: temaEscuro && GRAY_DATE_UX}} onClick={changeOpen}>
														<LightbulbIcon style={{ fontSize: "14px"}}/>
													</IconButton>
												</React.Fragment>
											) : (
												<React.Fragment>
													<IconButton onClick={toggleDrawer} style={{...styles.drawerButton, ...styles.drawerBtClosed, color: temaEscuro && GRAY_DATE_UX}}>
														<ChevronRightIcon />
													</IconButton>
												</React.Fragment>
											)
										}
									</Box>
									<Box
										sx={{
											...styles.box,
											gap: 2,
											padding: 1,
											marginTop: 11,
	
										}}
										style={{visibility: open ? "visible" : "hidden" }} //muda cor loja quadrado
									>	
										<Button
											size="small"
											variant="contained"
											style={(temaEscuro ? styleDarkButton : styleButton)}
											sx={{width: "100%"}}
											startIcon={<SyncAltIcon sx={{color: YELLOW_BG_UX}} />}
											onClick={() => setOpenModal(true)}
										>
											Alterar loja
										</Button>
										<FormControl fullWidth sx={{marginBottom: 2}}>
											{loadingEstabelecimentos ? (
												<Loading />
											) : (
												estabelecimentos.length ? (
													<Autocomplete
														size="small"
														disablePortal
														disableClearable
														data-testid="navbar-change-store"
														PaperComponent={({ children }) => (
															<Paper
																style={{
																	backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
																	color: temaEscuro ? WHITE_TABLE : BLUE_THEME,
																	fontWeight: "500",
																	border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
																}}
															>
																{children}
															</Paper>
														)}
														onChange={(event, newValue) => {
															handleChange(newValue);
														}}
														isOptionEqualToValue={(option, value) => option.value === value.value}
														value={estabelecimento || null}
														options={estabelecimentos}
														noOptionsText={translateAutocomplete}
														renderInput={(params) =>
															<TextField
																{...params}
																label="Estabelecimentos"
																InputLabelProps={{
																	style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON}
																}}
																sx={{
																	"& .MuiInputLabel-root": {
																		color: temaEscuro && BORDER_BUTTON
																	}
																}}
															/>
														}
													/>
												) : (
													<p>Nenhum estabelecimento encontrado</p>
												)
	
											)
											}
										</FormControl>
									</Box>
									<Box>
										<List component="nav">
											<ListItems
												open={open}
												className={filteredSteps.map((x) => {return x.selector.replace(".", "");})}
												setOpenSubCadastros={setOpenSubCadastros}
												setOpenSubCompras={setOpenSubCompras}
												setOpenSubFinanceiro={setOpenSubFinanceiro}
												openSubCadastros={openSubCadastros}
												openSubCompras={openSubCompras}
												openSubFinanceiro={openSubFinanceiro}
												activeButton={activeButton}
												setActiveButton={setActiveButton}
												handleChangeClick={handleChangeClick}
											/>
										</List>
									</Box>
								</Drawer>
								<Box
									component="main"
									sx={{
										backgroundColor: (theme) =>
											theme.palette.mode === (temaEscuro ? "temaEscuro" : "light")
												? GRAY_LABEL_UX_THEME
												: BLACK_TABLE_PERFIL,
										flexGrow: 1,
										overflowX: "auto",
										overflowY: "unset"
									}} //MUDA O FUNDO DE TODAS AS ABAS
								>
									<Outlet />
								</Box>
							</Box>
						) : (
							<MenuMobile 
								store={estabelecimento}
								loadingLojas={loadingEstabelecimentos}
								filteredSteps={filteredSteps}
								handleChangeClick={handleChangeClick}
								activeButton={activeButton}
								isMenuOpen={isMenuOpen}
								setIsMenuOpen={setIsMenuOpen}
							/>
						)
					}
					
				</React.Fragment>
			) : (
				<Outlet />
			)}
		</React.Fragment>
	);
};

const styles = {
	box: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	drawerButton: {
		padding: "2px",
		background: "#ECC94B",
		position: "fixed",
		zIndex: 1,
		top: "20px"
	},
	drawerButtonTour:{
		position: "fixed",
		background: "#ECC94B",
		marginTop: "110px",
		marginLeft: "1.5px",
		zIndex: 1
	},
	drawerBtOpen: {
		// navbar"s width is 240px and button is 28px (28/2 = 14) -> 240 - 14 = 226
		left: "226px",
		animation: "append-animate .2s linear"
	},
	drawerBtClosed: {
		// navbar"s width is 72px and button is 28px (28/2 = 14) -> 72 - 14 = 58
		left: "58px",
		animation: "close-animate .2s linear"
	},
	perfil: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		height: "80px",
		background: "rgba(9, 36, 75, 0.03)", //OK
	},
	emailText: {
		fontSize: 12,
		fontWeight: 500,
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	nameText: {
		fontSize: "1rem",
		fontWeight: 500,
		lineHeight: 1.5,
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	ellipsis: {
		textOverflow: "ellipsis"
	}
};

export default NavBar;