import React, { useEffect, useState } from "react";
import api from "../../axios";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { 
    BLUE_THEME,
    checkSituacao,
    formatterCurrency, 
    LINE_TABLE, 
    PAPER_PADDING_THEME, 
    paperStyle, 
    BORDER_BLACK_THEME_CARD, 
    BORDER_PAPER, 
    WHITE_ESCRITA_THEME, 
    handleDayMonthYear, 
    GRAY_LABEL_UX, 
    RED_INFO, 
    BLUE_INFO_UX, 
    DASH_KPI_POSITIVE_COLOR, 
    GRAY_LABEL_UX_THEME, 
    YELLOW_BG_UX, 
    BLACK_LABEL_UX,
    GRAY_BG_UX,
    DASH_KPI_DATA_COLOR,
    BLACK_TABLE,
    BLACK_TABLE_PERFIL,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    formatterPercentage,
    GRAY_PDF
} from "../../shared/utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch, useSelector } from "react-redux";
import {showSnackMessage} from "../../actions/SnackActions";
import Divider from "@mui/material/Divider";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterDayMonthYear from "../../components/Filter/FilterDayMonthYear";
import InputSearch from "../../components/Input/InputSearch";
import DataGrid from "../../components/DataGrid/DataGrid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Skeleton, TableContainer, TableHead } from "@mui/material";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import DefaultButton from "../../components/Buttons/DefaultButton";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetalheNota = (props) => {

    const {movimentacaoUUID, open, setOpen, setRecalculatePagePrevious} = props;

    const dispatch = useDispatch();
    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const [fornecedor, setFornecedor] = useState("");
    const [categoriaDespesa, setCategoriaDespesa] = useState("");
    const [produtoServicos, setProdutoServicos] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const [totalProdutos, setTotalProdutos] = useState(0);
    const [totalLiquidoProdutos, setTotalLiquidoProdutos] = useState(0);
    const [totalBrutoProdutos, setTotalBrutoProdutos] = useState(0);
    const [totalDescontoProdutos, setTotalDescontoProdutos] = useState(0);
    const [totalFreteProdutos, setTotalFreteProdutos] = useState(0);
    const [totalBrutoPago, setTotalBrutoPago] = useState(0);
    const [totalLiquidoPago, setTotalLiquidoPago] = useState(0);
    const [totalDescontoPago, setTotalDescontoPago] = useState(0);
    const [totalLiquidoEmAberto, setTotalLiquidoEmAberto] = useState(0);
    const [totalBrutoEmAberto, setTotalBrutoEmAberto] = useState(0);
    const [totalDescontoEmAberto, setTotalDescontoEmAberto] = useState(0);
    const [totalLiquidoEmAtraso, setTotalLiquidoEmAtraso] = useState(0);
    const [totalBrutoEmAtraso, setTotalBrutoEmAtraso] = useState(0);
    const [totalDescontoEmAtraso, setTotalDescontoEmAtraso] = useState(0);
    const [totalLiquidoAPagar, setTotalLiquidoAPagar] = useState(0);
    const [totalBrutoAPagar, setTotalBrutoAPagar] = useState(0);
    const [totalDescontoAPagar, setTotalDescontoAPagar] = useState(0);
    const [observacoesComplementares, setObservacoesComplementares] = useState("");
    const [loading, setLoading] = useState(true);
    const [fornecedorOptions, setFornecedorOptions] = useState([]);
    const [inputError, setInputError] = useState(false);
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [selectedDataCompetencia, setSelectedDataCompetencia] = useState("");
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [codigoReferenciaPrevious, setCodigoReferenciaPrevious] = useState("");
    const [rateios, setRateios] = useState([{}]);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        if (open) {
            getNotaDetails();
        }
    }, [open]);

    const getNotaDetails = () => {
        setLoading(true);
        let dataRequest = {
            mv_uuid: `NFe ${movimentacaoUUID}`,
            estabelecimento_id: estabelecimentoId
        };

        api.GetNotaDetails(dataRequest).then((response) => {
            let dataResponse = response.data;
            setFornecedor(dataResponse.informacoes_compra.fornecedor);
            setCodigoReferencia(dataResponse.informacoes_compra.codigo_referencia);
            setCodigoReferenciaPrevious(dataResponse.informacoes_compra.codigo_referencia);
            setCategoriaDespesa(dataResponse.informacoes_compra.categoria);
            setSelectedDataCompetencia(moment(dataResponse.informacoes_compra.data_compra, "DD/MM/YYYY").toDate());
            setProdutoServicos(dataResponse.informacoes_compra.all_produto_servico);
            setParcelas(dataResponse.condicao_pagamento.parcelas);
            setTotalProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_produtos);
            setTotalLiquidoProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_liquido);
            setTotalBrutoProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_bruto);
            setTotalDescontoProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_desconto);
            setTotalFreteProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_frete);
            setFornecedorOptions(dataResponse.informacoes_compra.input_fornecedor_cliente);
            setCategoriaOptions(dataResponse.informacoes_compra.input_categoria);
            setTotalBrutoPago(dataResponse.condicao_pagamento.pago.total_bruto);
            setTotalLiquidoPago(dataResponse.condicao_pagamento.pago.total_liquido);
            setTotalDescontoPago(dataResponse.condicao_pagamento.pago.total_desconto);
            setTotalLiquidoEmAberto(dataResponse.condicao_pagamento.em_aberto.total_liquido);
            setTotalBrutoEmAberto(dataResponse.condicao_pagamento.em_aberto.total_bruto);
            setTotalDescontoEmAberto(dataResponse.condicao_pagamento.em_aberto.total_desconto);
            setTotalLiquidoEmAtraso(dataResponse.condicao_pagamento.em_aberto.em_atraso_summary.total_liquido);
            setTotalBrutoEmAtraso(dataResponse.condicao_pagamento.em_aberto.em_atraso_summary.total_bruto);
            setTotalDescontoEmAtraso(dataResponse.condicao_pagamento.em_aberto.em_atraso_summary.total_desconto);
            setTotalLiquidoAPagar(dataResponse.condicao_pagamento.em_aberto.a_pagar_summary.total_liquido);
            setTotalBrutoAPagar(dataResponse.condicao_pagamento.em_aberto.a_pagar_summary.total_bruto);
            setTotalDescontoAPagar(dataResponse.condicao_pagamento.em_aberto.a_pagar_summary.total_desconto);
            setObservacoesComplementares(dataResponse.observacoes_complementares);
            setRateios(dataResponse.rateios);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleEditCompra = () => {
        const isInvalid =
            !fornecedor ||
            !selectedDataCompetencia ||
            !categoriaDespesa ||
            !codigoReferencia;

        if (isInvalid) {
            setInputError(true);
            return;
        }

        setLoading(true);

        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("fornecedor", fornecedor);
        formData.append("data_competencia", moment(selectedDataCompetencia).format("DD/MM/YYYY"));
        formData.append("categoria_movimentacao", categoriaDespesa);
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("codigo_referencia_previous", codigoReferenciaPrevious);
        formData.append("observacoes", observacoesComplementares);
            
        api.PutNotaDetails(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Compra editada com sucesso!", severity: "success"}));
            closeModal();
            setRecalculatePagePrevious(true);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const columns = [
        {
            field: "nome",
            headerName: "Porduto/Serviço",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "tipo",
            headerName: "Tipo",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "detalhes",
            headerName: "Detalhes",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "quantidade",
            headerName: "Quantidade",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor_unitario",
            headerName: "Valor unitário",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.valor_unitario < 0 && RED_INFO,
                        fontWeight: params.row.valor_unitario < 0 ? "bold" : "normal"
                    }}>
                        {formatterCurrency(params.row.valor_unitario, 2)}
                    </span>

                );
            }
        },
        {
            field: "valor_total",
            headerName: "Subtotal",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.valor_total < 0 && RED_INFO,
                        fontWeight: params.row.valor_total < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.valor_total, 2)}
                </span>

                );
            }
        },
    ];

    const columnsCondicaoPagamento = [
        {
            field: "data",
            headerName: "Data",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "parcela",
            headerName: "Parcela",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const rowIndex = params.api.getSortedRowIds().indexOf(params.id) + 1;
                const totalRows = params.api.getRowsCount();
                return `${rowIndex}/${totalRows}`;
            }
        },
        {
            field: "forma_pagamento",
            headerName: "Forma de pagamento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "conta",
            headerName: "Conta",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.valor < 0 && RED_INFO,
                        fontWeight: params.row.valor < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.valor, 2)}
                </span>

                );
            }
        },
        {
            field: "pago",
            headerName: "Pago",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.pago < 0 && RED_INFO,
                        fontWeight: params.row.pago < 0 ? "bold" : "normal"
                    }}>
                        {formatterCurrency(params.row.pago, 2)}
                    </span>
                );
            }
        },
        {
            field: "em_aberto",
            headerName: "Em aberto",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.em_aberto < 0 && RED_INFO,
                        fontWeight: params.row.em_aberto < 0 ? "bold" : "normal"
                    }}>
                        {formatterCurrency(params.row.em_aberto, 2)}
                    </span>
                );
            }
        },
        {
            field: "situacao",
            headerName: "Situação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return checkSituacao(temaEscuro, params.row.situacao);
            }
        },
    ];

    const closeModal = () => {
        setInputError(false);
        setOpen(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                    backgroundImage: "none"
                },
            }}
            data-testid="movimentacao-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? BLUE_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        Editar compra
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }} data-testid="exm-container-editar-pagar-receber">
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                    data-testid="perfil-screen"
                >
                    {loading ? (
                        [...Array(5).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span><p className="title-container-p">Informações da compra</p></span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}} data-testid="grid-inputs">
                                <FilterSingleOption
                                    filterOptions={fornecedorOptions}
                                    selectedOption={fornecedor}
                                    setSelectedOption={setFornecedor}
                                    placeholder={"Fornecedor"}
                                    width={"250px"}
                                    inputError={inputError}
                                />
                                <InputSearch
                                    functionProps={() => {}}
                                    setSearchValue={setCodigoReferencia}
                                    searchValue={codigoReferencia}
                                    label={"Código de referência"}
                                    width={"325px"}
                                    testId="codigo-input"
                                    inputError={inputError}
                                />
                                <FilterSingleOption
                                    filterOptions={categoriaOptions}
                                    selectedOption={categoriaDespesa}
                                    setSelectedOption={setCategoriaDespesa}
                                    placeholder="Categoria"
                                    width={"250px"}
                                    inputError={inputError}
                                />
                                <FilterDayMonthYear
                                    handleSubmit={handleDayMonthYear}
                                    value={selectedDataCompetencia}
                                    setState={setSelectedDataCompetencia}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data competência"}
                                    inputError={inputError}
                                    disableMinDate
                                />
                            </Grid>
                            <Grid item xs={12} sx={{maxHeight: 450}}>
                                <DataGrid
                                    rows={(row) => row.id}
                                    columns={columns}
                                    data={produtoServicos}
                                    disableRowSelectionOnClick
                                    NoneFooter
                                    compact
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion sx={{ backgroundColor: "transparent", flex: 1}}> 
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                        sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                    >
                                        <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 4, marginRight: 2}}>
                                            <Grid item>
                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto", textAlign: "left"}}>
                                                    <p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX }}>Total da compra</p>
                                                    <p className="item-container-p" style={{fontSize: "12px", fontWeight: "500"}}>Total de produtos: {totalProdutos}</p>
                                                </span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                    <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total líquido</p>
                                                    <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: totalLiquidoProdutos >= 0 ? DASH_KPI_POSITIVE_COLOR : RED_INFO}}>{formatterCurrency(totalLiquidoProdutos, 2)}</p>
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                        <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                            <TableBody sx={{
                                                ".MuiTableCell-root": {
                                                    borderBottom: "none",
                                                    padding: "0 0 .5rem 4rem"
                                                }}}
                                            >
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Total bruto:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoProdutos, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Frete:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalFreteProdutos, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Desconto:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoProdutos, 2)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                    data-testid="perfil-screen"
                >
                    {loading ? (
                        [...Array(5).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={50}/>
                            );
                        })
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span><p className="title-container-p">Condição de pagamento</p></span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs={12} sx={{maxHeight: 450}}>
                                <DataGrid
                                    rows={(row) => row.id}
                                    columns={columnsCondicaoPagamento}
                                    data={parcelas}
                                    disableRowSelectionOnClick
                                    NoneFooter
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion sx={{ backgroundColor: "transparent", flex: 1}}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                        sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                    >
                                        <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 4, marginRight: 2}}>
                                            <Grid item>
                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                    <p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX }}>Pago</p>
                                                </span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                    <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total pago</p>
                                                    <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: totalLiquidoProdutos >= 0 ? DASH_KPI_POSITIVE_COLOR : RED_INFO}}>{formatterCurrency(totalLiquidoPago, 2)}</p>
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                        <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                            <TableBody sx={{
                                                ".MuiTableCell-root": {
                                                    borderBottom: "none",
                                                    padding: "0 0 .5rem 4rem"
                                                }}}
                                            >
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcelas:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoPago, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Descontos:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoPago, 2)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion sx={{ backgroundColor: "transparent", flex: 1}}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                        sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                    >
                                        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 4, marginRight: 2}}>
                                            <Grid item>
                                                <span><p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX}}>Em aberto</p></span>
                                            </Grid>
                                            <Grid item>
                                                <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: 4, marginRight: 2}}>
                                                    <Grid item>
                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                            <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total a pagar (R$)</p>
                                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: DASH_KPI_POSITIVE_COLOR}}>{formatterCurrency(totalLiquidoAPagar, 2)}</p>
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{fontSize: 25, fontWeight: "bold", color: !temaEscuro && GRAY_BG_UX}}>+</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                            <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total em atraso (R$)</p>
                                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: RED_INFO}}>{formatterCurrency(totalLiquidoEmAtraso, 2)}</p>
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{fontSize: 25, fontWeight: "bold", color: !temaEscuro && GRAY_BG_UX}}>=</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                            <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Total em aberto (R$)</p>
                                                            <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: BLUE_INFO_UX}}>{formatterCurrency(totalLiquidoEmAberto, 2)}</p>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                        <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                            <TableBody sx={{
                                                ".MuiTableCell-root": {
                                                    borderBottom: "none",
                                                    padding: "0 0 .5rem 9.5rem"
                                                }}}
                                            >
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcelas:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoAPagar, 2)}</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoEmAtraso, 2)}</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoEmAberto, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="right" sx={{color: "#9B9B9B"}}>Descontos:</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoAPagar, 2)}</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoEmAtraso, 2)}</TableCell>
                                                    <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoEmAberto, 2)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                    data-testid="btns-observacoes-anexos"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px"}}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                className="buttonTabs"
                            >
                                <Tab
                                    value={"observacoes"}
                                    label="Observações"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": {
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                {
                                    (rateios.length > 1 && rateios[0].categoria !== null) && (
                                        <Tab
                                            data-testid="categoria-tab"
                                            value={"categoria_info"}
                                            label="Informações de categoria"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                            {selectedTab === "observacoes" ? (
                                <Grid item xs={12}>
                                    <TextField
                                        data-testid="observacoes-textfield"
                                        label={"Observações"}
                                        id="input-observacoes-textfield"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        maxRows={6}
                                        value={observacoesComplementares}
                                        onChange={(event) => {
                                            setObservacoesComplementares(event.target.value);
                                        }}
                                        sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                    />
                                </Grid>
                            ) : (
                                    rateios.map((rateioGroup, index) => {
                                        return (
                                            <Accordion key={rateioGroup.movimentacao_id} sx={{ backgroundColor: "transparent", flex: 1, mt: 1}}>
                                                <AccordionSummary
                                                    expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                                    sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                                >
                                                    <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 4, marginRight: 2}}>
                                                        <Grid item>
                                                            <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                                <p className="title-container-p" style={{color: !temaEscuro && GRAY_BG_UX }}>Parcela {index+1}/{rateios.length}</p>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                                    <TableContainer sx={{".MuiTableCell-root": {
                                                    padding: ".8rem 1rem",
                                                    fontWeight: "400",
                                                    textAlign: "center"
                                                    }}}>
                                                        <Table>
                                                            <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Categoria
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Valor
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Porcentagem
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rateioGroup.rateios.map((rateio, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {rateio.categoria__nome}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {formatterCurrency(rateio.valor, 2)}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {formatterPercentage(rateio.percentual, 2)}%
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })
                                )
                            }
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2, margin: `0px ${isLargeScreen ? "120px" : "20px"} 100px ${isLargeScreen ? "120px" : "20px"}`}}>
                        <DefaultButton
                            onClick={() => closeModal()}
                            title="Cancelar"
                        />
                        <DefaultButton
                            title={"Salvar"}
                            testId="btn-salvar"
                            onClick={() => handleEditCompra()}
                            variant="yellow"
                        />
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default DetalheNota;