import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { BLACK_BUTTON, BLUE_INFO_UX, BORDER_BUTTON, GRAY_LABEL_UX, GREEN_SUCCESS_UX, RED_TABLE_THEME, YELLOW_BG_UX } from "../../shared/utils";
import CircularProgress from "@mui/material/CircularProgress";

const DefaultButton = (props) => {
    const navigate = useNavigate();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const variant = {
        backgroundColor: "transparent",
        color: temaEscuro ? RED_TABLE_THEME : GRAY_LABEL_UX,
        borderColor: temaEscuro ? BORDER_BUTTON : GRAY_LABEL_UX,
    };

    if (props?.variant === "yellow") {
        variant.backgroundColor = YELLOW_BG_UX;
        variant.color = BLACK_BUTTON;
        variant.borderColor = YELLOW_BG_UX;
    }
    else if (props?.variant === "blue") {
        variant.backgroundColor = BLUE_INFO_UX;
        variant.color = BLACK_BUTTON;
        variant.borderColor = BLUE_INFO_UX;
    }
    else if (props?.variant === "green") {
        variant.backgroundColor = GREEN_SUCCESS_UX;
        variant.color = BLACK_BUTTON;
        variant.borderColor = GREEN_SUCCESS_UX;
    }

    const redirect = () => {
        if (props?.state) {
            navigate(props.path, { state: props.state});
        } else {
            navigate(props.path);
        }
    };

    return (
        <Button
            className={props?.className}
            size={props.size ? props.size : "normal"}
            type={props.type ? props.type : "button"}
            variant="contained"
            data-testid={props?.testId}
            onClick={props?.isRedirect ? redirect : props.onClick}
            style={props?.disabled ? null : {
                backgroundColor: variant.backgroundColor,
                color: variant.color,
                border: `1px solid ${variant.borderColor}`,
                fontWeight: "600",
                boxShadow: "none",
            }}
            startIcon={props?.startIcon}
            disabled={props?.disabled}
            sx={props?.borderRadius && props.borderRadius}
            title={props?.tooltip}
        >
            {
                props?.loading ? <CircularProgress size={24} color="inherit" /> : props?.title
            }
        </Button>
    );
};

export default React.memo(DefaultButton);