import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import StoreReducer from "./StoreReducer";
import SnackReducer from "./SnackReducer";

const rootReducer = combineReducers({
    AuthReducer,
    SnackReducer,
    StoreReducer,
});

export default rootReducer;
