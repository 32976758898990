import React, {useState, useEffect} from "react";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    YELLOW_BG_UX,
    GRAY_BG_UX,
    GRAY_LABEL_UX_THEME,
    format,
    DASH_KPI_DATA_COLOR,
    BLACK_TABLE,
    styleText,
    WHITE_THEME_BLACK,
    RED_INFO,
    BLACK_LABEL_UX,
    handleDayMonthYear,
    GRAY_PDF,
    formatterCurrency,
    formatterPercentage,
    ROW_MAIN,
    WHITE_TABLE,
    BLUE_INFO_UX,
    TITLE_SIZE,
    BLACK_TABLE_PERFIL,
    GRAY_STRONG_UX,
    checkSituacao,
} from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../../../axios";
import Anexos from "../../../components/Tabs/Anexos";
import {showSnackMessage} from "../../../actions/SnackActions";
import InputSearch from "../../../components/Input/InputSearch";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import InputCurrency from "../../../components/Input/InputCurrency";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import InformarPagamentoModal from "../../../components/Modal/InformarPagamentoModal";
import Paper from "@mui/material/Paper";
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputNumber from "../../../components/Input/InputNumber";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditarMovimentacao = (props) => {

    const { idMovimentacao, situacaoMov, tipoLancamentoMov, open, setOpen, recalculatePagePrevious } = props;

    const nav = useNavigate();
    const dispatch = useDispatch();

    const id_movimentacao = idMovimentacao;
    const situacao = situacaoMov;
    const tipoLancamento = tipoLancamentoMov;
    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        if (open) {
            getInfoDespesa();
        }
    }, [open]);

    const [fornecedor, setFornecedor] = useState("");
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [selectedDataCompetencia, setSelectedDataCompetencia] = useState("");
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [valor, setValor] = useState(0);
    const [valorIsPago, setValorIsPago] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null);
    const [selectedParcela, setSelectedParcela] = useState(1);
    const [selectedVencimento, setSelectedVencimento] = useState("");
    const [selectedIntervaloDias, setSelectedIntervaloDias] = useState(30);
    const [selectedConta, setSelectedConta] = useState(null);
    const [selectedPrevisaoPagamento, setSelectedPrevisaoPagamento] = useState("");
    const [desconto, setDesconto] = useState(0);
    const [contaPagamento, setContaPagamento] = useState("");
    const [firstVencimento, setFirstVencimento] = useState("");
    const [tipoMovimentacao, setTipoMovimentacao] = useState("");
    const [inputError, setInputError] = useState(false);
    const [pago, setPago] = useState(false);
    const [agendado, setAgendado] = useState(false);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [valorFrete, setValorFrete] = useState(0);
    const [valorTarifaBancaria, setValorTarifaBancaria] = useState(0);
    const [baixas, setBaixas] = useState([]);
    const [isCompra, setIsCompra] = useState(false);

    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [fornecedorOptions, setFornecedorOptions] = useState([]);
    const [clientesOptions, setClientesOptions] = useState([]);
    const [contasOptions, setContasOptions] = useState([]);

    const [openInformarPagamento, setOpenInformarPagamento] = useState(false);

    const parcelaOptions = ["À vista", "1", "2", "3", "4", "5", "12"];
    const formaPagamentoOptions = ["Cartão de crédito", "Dinheiro", "Pix"];

    const [loadingAnexos, setLoadingAnexos] = useState(true);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([]);
    
    const [parcelas, setParcelas] = useState();

    const [rateios, setRateios] = useState([{
        categoria: null,
        valor_total: 0,
        porcentagem: 0,
        centro_custo: null,
    }]);

    useEffect(() => {
        if (Number(selectedParcela) > 1) {
            const updatedParcelas = Array.from({ length: Number(selectedParcela) }, (_, index) => {

                const valorParcela = valor / Number(selectedParcela);
                const percentualParcela = (valorParcela / valor) * 100;

                return {
                    numero: index + 1,
                    vencimento: (parcelas && parcelas.length > 0) ? moment(firstVencimento, "YYYY-MM-DD").add(index * selectedIntervaloDias, "days") : moment(firstVencimento, "DD/MM/YYYY").add(index * selectedIntervaloDias, "days"),
                    valor: valor / Number(selectedParcela),
                    formaPagamento: "",
                    percentual: percentualParcela.toFixed(2),
                    descricao: "",
                };
            });
            setParcelas(updatedParcelas);
        }
    }, [selectedParcela, selectedIntervaloDias, selectedVencimento]);

    useEffect(() => {
        if (selectedPrevisaoPagamento && !pago && moment(selectedPrevisaoPagamento).isBefore(moment(), "day")) {
            setAgendado(true);
        } else {
            setAgendado(false);
        }
    }, [selectedPrevisaoPagamento]);

    useEffect(() => {
        setSelectedPrevisaoPagamento(selectedVencimento);
    }, [selectedVencimento]);

    useEffect(() => {
        if (selectedTab === "anexo") {
            getAnexos();
        }
    }, [selectedTab]);

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            baixa_id: id_movimentacao,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        });
    };

    const handleParcelaChange = (index, field, value) => {
        setParcelas(prevParcelas =>
            prevParcelas.map((parcela, i) =>
                i === index ? { ...parcela, [field]: value } : parcela
            )
        );
    };

    const getInfoDespesa = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            lancamento_id: id_movimentacao,
            state: tipoLancamento
        };
        api.RetrieveContas({estabelecimento_id: estabelecimentoId}).then((response) => {
            const contasApelidos = response.data.map(conta => conta.label);
            setContasOptions(contasApelidos);
        });
        api.GetInfoMovimentacao(dataRequest).then((response) => {
            const data = response.data;
            if (data.fornecedor) {
                setFornecedor(data.fornecedor.nome);
            }
            setDescricao(data.movimentacao.descricao);
            setSelectedCategoria(data.categoria.nome);
            setRateios(data.rateios);
            setCategoriaOptions(data.categorias);
            setValor(data.movimentacao.valor_total);
            setValorIsPago(data.valor_pago);
            setFornecedorOptions(data.fornecedores);
            setSelectedDataCompetencia(moment(data.movimentacao.data_movimentacao, "DD/MM/YYYY").toDate());
            setSelectedFormaPagamento(data.movimentacao.forma_pagamento);
            setCentroCustoOptions(data.centros_custos);
            setCodigoReferencia(data.movimentacao.codigo_referencia);
            setSelectedConta(data.conta.apelido);
            setSelectedVencimento(moment(data.movimentacao.vencimento, "DD/MM/YYYY").toDate());
            setSelectedPrevisaoPagamento(moment(data.movimentacao.data_pagamento, "DD/MM/YYYY").toDate());
            setContaPagamento(data.conta_padrao);
            setPago(data.pago);
            setAgendado(data.agendado);
            setValorFrete(data.frete);
            setValorTarifaBancaria(data.tarifa_bancaria);
            if (data.parcelas && data.parcelas.length > 0) {
                setParcelas(data.parcelas.map(parcela => ({
                    numero: parcela.numero,
                    vencimento: parcela.data_vencimento,
                    valor: parcela.valor,
                    percentual: parcela.percentual,
                    descricao: parcela.observacoes,
                })));
                setFirstVencimento(data.parcelas[0].data_vencimento);
            } else {
                setFirstVencimento(data.movimentacao.vencimento);
                setParcelas({
                    numero: 0,
                    vencimento: data.movimentacao.vencimento,
                    valor: 0,
                    percentual: 0,
                    descricao: null,
                });
            }
            setSelectedParcela(data.movimentacao.condicao_pagamento);
            setSelectedCentroCusto(data.centro_custo);
            setObservacoes(data.movimentacao.observacoes);
            setClientesOptions(data.clientes);
            setTipoMovimentacao(data.movimentacao.tipo_movimentacao);
            setJuros(data.juros);
            setMulta(data.multa);
            setDesconto(data.desconto);
            setBaixas(data?.baixas);
            if (data.item_compra) {
                setIsCompra(true);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);
    
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
    
            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });
    
            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
            
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const formatDateIfNeeded = (date) => {
        return typeof(date) !== "object" ? date : moment(date).format("DD/MM/YYYY");
    };

    const handleEditDespesa = () => {
        if (!fornecedor || !valor || !selectedCategoria || !selectedParcela || !selectedVencimento || !selectedFormaPagamento || !selectedConta) {
            setInputError(true);
            return;
        }
        setLoading(true);
        setLoadingAnexos(true);
        let formData = new FormData();
        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("movimentacao_id", id_movimentacao);
        formData.append("fornecedor", fornecedor);
        formData.append("data_compra", formatDateIfNeeded(selectedDataCompetencia));
        formData.append("descricao_movimentacao", descricao);
        formData.append("valor_movimentacao", (valor - juros - multa) + desconto);
        formData.append("categoria_movimentacao", selectedCategoria);
        formData.append("centro_custo", selectedCentroCusto);
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("condicao_pagamento", selectedParcela === "À vista" ? 0 : selectedParcela);
        formData.append("vencimento_movimentacao", formatDateIfNeeded(selectedVencimento));
        formData.append("previsao_pagamento", formatDateIfNeeded(selectedPrevisaoPagamento));
        formData.append("forma_pagamento_movimentacao", selectedFormaPagamento);
        formData.append("conta_pagamento_movimentacao", selectedConta);
        formData.append("desconto", desconto);
        formData.append("observacoes", observacoes);
        formData.append("juros", juros);
        formData.append("multa", multa);
        formData.append("frete", valorFrete);
        formData.append("tarifa_bancaria", valorTarifaBancaria);
        formData.append("anexos", JSON.stringify(anexos));

        if (pago) {
            formData.append("data_pagamento", formatDateIfNeeded(selectedPrevisaoPagamento));
            formData.append("pago", pago);
        }
        if (agendado) {
            formData.append("agendado", agendado);
        }
        if (anexos.length > 0) {
            if (anexos[0]?.arquivo !== null){
                formData.append("quantidade_anexo", anexos.length);
                anexos.forEach((anexo, index) => {
                    formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                    formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                    formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                    formData.append(`anexos[${index}][descricao]`, anexo.descricao);
                });
            }
        }

        if (Array.isArray(parcelas) && parcelas.length > 0 && Number(selectedParcela) === parcelas.length) {
            const formattedParcelas = parcelas.map(parcela => ({
                ...parcela,
                vencimento: moment(parcela.vencimento).format("DD/MM/YYYY")
            }));
            formData.append("parcelas", JSON.stringify(formattedParcelas));
        } else {
            // Pagamento à vista
            const formattedParcelas = [{
                numero: 1,
                vencimento: formatDateIfNeeded(selectedVencimento),
                valor: valor,
                percentual: 100,
                descricao: "",
            }];
            formData.append("parcelas", JSON.stringify(formattedParcelas));
        }
        api.EditarMovimentacao(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Despesa editada com sucesso!", severity: "success"}));
            closeModal();
            recalculatePagePrevious();
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const handleOpenModalEditCompra = () => {
        const tabAux = "compras-tab";
        nav("/notas_compras", { state:
            {detalhe: 
                { 
                    movimentacaoUUID: codigoReferencia,
                    tab: tabAux
                } 
            } 
        });
    };

    const closeModal = () => {
        setInputError(false);
        setOpen(false);
        setLoading(true);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => closeModal()}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                    backgroundImage: "none"
                },
            }}
            data-testid="movimentacao-container"
        >
            <AppBar
                sx={{
                    backgroundColor: temaEscuro
                        ? BLUE_THEME
                        : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    borderBottom: temaEscuro
                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                        : `1px solid ${BORDER_PAPER}`,
                    padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                    position: "relative",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                        {tipoMovimentacao === "Despesa" ? "Editar despesa" : "Editar receita"}
                    </Typography>
                    <IconButton
                        autoFocus
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            color: !temaEscuro && BLACK_LABEL_UX
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ overflow: "auto", maxHeight: "100vh" }} data-testid="exm-container-edit">
                {openInformarPagamento && (
                    <InformarPagamentoModal
                        open={openInformarPagamento}
                        setOpen={setOpenInformarPagamento}
                        baixaId={id_movimentacao}  
                        onConfirmInformarPagamento={() => getInfoDespesa()} 
                    />
                )}
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    {loading ? (
                        [...Array(5).keys()].map((i,d) =>{
                            return(
                                <Skeleton key={d} height={30}/>
                            );
                        })
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span><p className="title-container-p">{tipoMovimentacao === "Despesa" ? "Informações da despesa" : "Informações da receita"}</p></span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                <FilterSingleOption
                                    filterOptions={tipoMovimentacao === "Despesa" ? fornecedorOptions : clientesOptions}
                                    selectedOption={fornecedor}
                                    setSelectedOption={setFornecedor}
                                    placeholder={tipoMovimentacao === "Despesa" ? "Fornecedor" : "Cliente"}
                                    width={"250px"}
                                    inputError={inputError}
                                />
                                <FilterDayMonthYear
                                    handleSubmit={handleDayMonthYear}
                                    value={selectedDataCompetencia}
                                    setState={setSelectedDataCompetencia}
                                    size={"small"}
                                    width={"250px"}
                                    iconPosition={"end"}
                                    label={"Data da compra"}
                                    isEditConta
                                    inputError={inputError}
                                />
                                <InputSearch
                                    functionProps={() => {}}
                                    setSearchValue={setDescricao}
                                    searchValue={descricao}
                                    label={"Descrição"}
                                    width={"250px"}
                                />
                                <InputCurrency valor={valor} setValor={setValor} title={"Valor"}/>
                                <FilterSingleOption
                                    filterOptions={categoriaOptions}
                                    selectedOption={selectedCategoria}
                                    setSelectedOption={setSelectedCategoria}
                                    placeholder="Categoria"
                                    width={"250px"}
                                    inputError={inputError}
                                />
                                <FilterSingleOption
                                    filterOptions={centroCustoOptions}
                                    selectedOption={selectedCentroCusto}
                                    setSelectedOption={setSelectedCentroCusto}
                                    placeholder={"Centro de custo"}
                                    width={"250px"}
                                    inputError={inputError}
                                />
                                <InputSearch
                                    functionProps={() => {}}
                                    searchValue={codigoReferencia}
                                    setSearchValue={setCodigoReferencia}
                                    label={"Código de referência"}
                                    isCompra={isCompra}
                                    detalheCompras={handleOpenModalEditCompra}
                                />
                                <InputCurrency valor={valorFrete} setValor={setValorFrete} title={"Frete"}/>
                                <InputCurrency valor={valorTarifaBancaria} setValor={setValorTarifaBancaria} title={"Tarifa bancária"}/>
                                {
                                    !["2", "3", "4", "5", "12"].includes(selectedParcela) && (
                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                            <p style={{margin: 0}}>Saldo em aberto</p>
                                            <p style={{margin: 0, fontSize: 27, fontWeight: "bold"}}>R$ {format(valor - valorIsPago)}</p>
                                        </span>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                {
                    situacao === "Em Aberto" ? (
                        <Paper
                            sx={{
                                ...paperStyle,
                                backgroundColor: temaEscuro
                                    ? PAPER_PADDING_THEME
                                    : LINE_TABLE,
                                backgroundImage: temaEscuro && "none",
                                border: temaEscuro
                                    ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                    : `1px solid ${BORDER_PAPER}`,
                                margin: `20px ${
                                    isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                            }}
                        >
                            {loading ? (
                                [...Array(5).keys()].map((i,d) =>{
                                    return(
                                        <Skeleton key={d} height={30}/>
                                    );
                                })
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <span><p className="title-container-p">Informações detalhadas do pagamento</p></span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer className={"table-container"}>
                                            <Table>
                                                <TableHead style={{backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF}}>
                                                    <TableRow>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Data
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Forma de pagamento
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Conta
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Valor R$
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Juros/Multa R$
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Desconto
                                                        </TableCell>
                                                        <TableCell
                                                            align={"center"}
                                                            style={{
                                                            ...{zIndex: 3},
                                                            ...{fontWeight: "bold"},
                                                            ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_PDF},
                                                            ...{color: temaEscuro && LINE_TABLE},
                                                            ...{padding: 5}}}
                                                        >
                                                            Situação
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        baixas.map((item, idx) => {
                                                            return (
                                                                <TableRow key={idx} style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                                    <TableCell align={"center"}>
                                                                        <span>{item.data_pagamento_aux}</span>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <span>{item.forma_pagamento !== "null" ? item.forma_pagamento : "-"}</span>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <span>{item.conta_aux}</span>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <span>{formatterCurrency(item.valor)}</span>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <span>{formatterCurrency(item.juros + item.multa)}</span>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <span>{formatterCurrency(item.desconto)}</span>
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <span>{checkSituacao(temaEscuro, item.situacao)}</span>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    ) : (
                        <Paper
                            sx={{
                                ...paperStyle,
                                backgroundColor: temaEscuro
                                    ? PAPER_PADDING_THEME
                                    : LINE_TABLE,
                                backgroundImage: temaEscuro && "none",
                                border: temaEscuro
                                    ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                    : `1px solid ${BORDER_PAPER}`,
                                margin: `20px ${
                                    isLargeScreen ? "120px" : "20px"
                                } 20px ${isLargeScreen ? "120px" : "20px"}`,
                            }}
                        >
                            {loading ? (
                                [...Array(5).keys()].map((i,d) =>{
                                    return(
                                        <Skeleton key={d} height={40}/>
                                    );
                                })
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <span><p className="title-container-p">Condições de pagamento</p></span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                    </Grid>
                                    <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                        <FilterSingleOption
                                            filterOptions={parcelaOptions}
                                            selectedOption={selectedParcela}
                                            setSelectedOption={setSelectedParcela}
                                            placeholder="Parcelamento"
                                            width={"250px"}
                                            inputError={inputError}
                                            disabled={pago}
                                        />
                                        <FilterDayMonthYear
                                            handleSubmit={handleDayMonthYear}
                                            value={selectedVencimento}
                                            setState={setSelectedVencimento}
                                            size={"small"}
                                            width={"250px"}
                                            iconPosition={"end"}
                                            label={"Vencimento"}
                                            isEditConta
                                            inputError={inputError}
                                            disabled={pago}
                                        />
                                        {
                                            ["2", "3", "4", "5", "12"].includes(selectedParcela) && (
                                                <InputNumber
                                                    value={selectedIntervaloDias}
                                                    setValue={setSelectedIntervaloDias}
                                                    size={"small"}
                                                    width={"250px"}
                                                    label={"Intervalo entre parcelas (dias)"}
                                                    error={inputError}
                                                    disabled={pago}
                                                />
                                            )
                                        }
                                        <FilterSingleOption
                                            filterOptions={formaPagamentoOptions}
                                            selectedOption={selectedFormaPagamento}
                                            setSelectedOption={setSelectedFormaPagamento}
                                            placeholder="Forma de pagamento"
                                            width={"250px"}
                                            inputError={inputError}
                                        />
                                        <FilterSingleOption
                                            filterOptions={contasOptions}
                                            selectedOption={selectedConta}
                                            setSelectedOption={setSelectedConta}
                                            placeholder="Conta de pagamento"
                                            width={"250px"}
                                            inputError={inputError}
                                        />
                                        {
                                            (!["2", "3", "4", "5", "12"].includes(selectedParcela) && tipoMovimentacao === "Despesa") && (
                                                <div style={{display: "flex", gap: 2, alignItems: "center", justifyContent: "center"}}>
                                                    <a style={{
                                                        ...styleText,
                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                    }}>Pago</a>
                                                    <Checkbox
                                                        checked={pago}
                                                        onChange={(event) => setPago(event.target.checked)}
                                                    />
                                                    {
                                                        !pago && (
                                                            <React.Fragment>
                                                                <a
                                                                    style={{
                                                                        ...styleText,
                                                                        color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX
                                                                    }}
                                                                >
                                                                    Agendado
                                                                </a>
                                                                <Checkbox
                                                                    checked={agendado}
                                                                    onChange={(event) => setAgendado(event.target.checked)}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            ["2", "3", "4", "5", "12"].includes(selectedParcela) ? (
                                                <React.Fragment>
                                                    <Grid item xs={12} sx={{marginTop: 3}}>
                                                        <span><p className="title-container-p">Parcelas</p></span>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                                    </Grid>
                                                    {
                                                        (parcelas.length > 0 && parcelas) && (
                                                            (parcelas.map((parcela, index) => (
                                                                <Grid item xs={12} key={index} sx={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    gap: 2,
                                                                    flexWrap: "wrap",
                                                                    marginBottom: 2,
                                                                }}>
                                                                    <span>
                                                                        <p>{index + 1} -</p>
                                                                    </span>
                                                                    <FilterDayMonthYear
                                                                        handleSubmit={(date) => handleParcelaChange(index, "vencimento", date)}
                                                                        value={moment(parcela.vencimento).toDate()}
                                                                        setState={(date) => handleParcelaChange(index, "vencimento", date)}
                                                                        size="small"
                                                                        width="250px"
                                                                        iconPosition="end"
                                                                        label="Vencimento"
                                                                        isEditConta
                                                                        inputError={inputError}
                                                                    />
                                                                    <InputCurrency valor={parcela.valor} setValor={(value) => handleParcelaChange(index, "valor", value)} title={"Valor da parcela"}/>
                                                                    <InputSearch
                                                                        functionProps={() => {}}
                                                                        searchValue={parcela.percentual}
                                                                        setSearchValue={(value) => handleParcelaChange(index, "percentual", value)}
                                                                        label="Percentual"
                                                                    />
                                                                    <InputSearch
                                                                        disabled
                                                                        functionProps={() => {}}
                                                                        searchValue={contaPagamento}
                                                                        label="Conta para pagamento"
                                                                    />
                                                                    <InputSearch
                                                                        functionProps={() => {}}
                                                                        searchValue={parcela.descricao}
                                                                        setSearchValue={(value) => handleParcelaChange(index, "descricao", value)}
                                                                        label="Observações"
                                                                    />
                                                                </Grid>
                                                            )))
                                                        )
                                                    }
                                                </React.Fragment>
                                            ) : (
                                                (
                                                    (pago && !["2", "3", "4", "5", "12"].includes(selectedParcela)) ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} sx={{marginTop: 3}}>
                                                                <span><p className="title-container-p">Informações do pagamento</p></span>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                                            </Grid>
                                                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                                                <FilterDayMonthYear
                                                                    handleSubmit={handleDayMonthYear}
                                                                    value={selectedPrevisaoPagamento}
                                                                    setState={setSelectedPrevisaoPagamento}
                                                                    size={"small"}
                                                                    width={"250px"}
                                                                    iconPosition={"end"}
                                                                    label={"Data do pagamento"}
                                                                    isEditConta
                                                                    inputError={inputError}
                                                                />
                                                                <InputCurrency valor={valorIsPago} setValor={setValorIsPago} title={"Valor"}/>
                                                                <InputCurrency valor={juros} setValor={setJuros} title={"Juros"} required={false}/>
                                                                <InputCurrency valor={multa} setValor={setMulta} title={"Multa"} required={false}/>
                                                                <InputCurrency valor={desconto} setValor={setDesconto} title={"Desconto"} required={false}/>
                                                            </Grid>
                                                            <Grid item xs={12} sx={{padding: 2, display: "flex", alignItems: "start", justifyContent: "space-between", border: `1px solid ${temaEscuro ? "#616169" : "#C3C3C3"}`, borderRadius: 1, marginTop: 2}}>
                                                                <span style={{...styleText, fontWeight: "700", fontSize: 20, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                                                                    Totais
                                                                </span>
                                                                <Grid container sx={{width: "auto", display: "flex", gap: 6}}>
                                                                    <Grid item sx={{display: "flex", gap: 1, flexDirection: "column", alignItems: "end"}}>
                                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Valor em Aberto (R$)</span>
                                                                        <span style={{color: RED_INFO, fontWeight: 600}}>{format(valor - valorIsPago)}</span>
                                                                    </Grid>
                                                                    <Grid item sx={{display: "flex", gap: 1, flexDirection: "column", alignItems: "end"}}>
                                                                        <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Total a pagar (R$)</span>
                                                                        <span style={{fontWeight: 600, color: BLUE_INFO_UX}}>{format(valor + juros + multa - desconto, true)}</span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Grid item xs={12} sx={{marginTop: 3}}>
                                                                <span><p className="title-container-p">Previsão de pagamento</p></span>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                                            </Grid>
                                                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}}>
                                                                <FilterDayMonthYear
                                                                    handleSubmit={handleDayMonthYear}
                                                                    value={selectedPrevisaoPagamento}
                                                                    setState={setSelectedPrevisaoPagamento}
                                                                    size={"small"}
                                                                    width={"250px"}
                                                                    iconPosition={"end"}
                                                                    label={"Previsão do pagamento"}
                                                                    isEditConta
                                                                    inputError={inputError}
                                                                />
                                                                <InputCurrency valor={desconto} setValor={setDesconto} title={"Desconto"}/>
                                                                <InputCurrency valor={juros} setValor={setJuros} title={"Juros"}/>
                                                                <InputCurrency valor={multa} setValor={setMulta} title={"Multa"}/>
                                                                <InputCurrency valor={valor - valorIsPago} title={"Valor a pagar"} disabled={true}/>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )
                                                )
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    )
                }
                <Paper
                    sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro
                            ? PAPER_PADDING_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        border: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        margin: `20px ${
                            isLargeScreen ? "120px" : "20px"
                        } 20px ${isLargeScreen ? "120px" : "20px"}`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px"}}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                className="buttonTabs"
                            >
                                <Tab
                                    value={"observacoes"}
                                    label="Observações"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                <Tab
                                    value={"anexo"}
                                    label="Anexo"
                                    sx={{
                                        borderRadius: "20px",
                                        color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                        backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                        "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                            color: BLACK_LABEL_UX,
                                        },
                                    }}
                                />
                                {
                                    (rateios.length > 1 && rateios[0].categoria !== null) && (
                                        <Tab
                                            data-testid="categoria-tab"
                                            value={"categoria_info"}
                                            label="Informações de categoria"
                                            sx={{
                                                borderRadius: "20px",
                                                color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                    color: BLACK_LABEL_UX,
                                                },
                                            }}
                                        />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        {selectedTab === "observacoes" ? (
                            <Grid item xs={12}>
                                <TextField
                                    data-testid="observacoes-textfield"
                                    label={"Observações"}
                                    id="input-observacoes-textfield"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    maxRows={6}
                                    value={observacoes}
                                    onChange={(event) => {
                                        setObservacoes(event.target.value);
                                    }}
                                    sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                />
                            </Grid>
                        ) : selectedTab === "anexo" ? (
                            <Anexos
                                loading={loadingAnexos}
                                anexos={anexos}
                                setAnexos={setAnexos}
                            />
                        ) : (
                            <TableContainer sx={{".MuiTableCell-root": {
                                padding: ".8rem 1rem",
                                fontWeight: "400",
                                textAlign: "center"
                            }}}>
                                <Table>
                                    <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                        <TableRow>
                                            <TableCell>
                                                Categoria
                                            </TableCell>
                                            <TableCell>
                                                Valor
                                            </TableCell>
                                            <TableCell>
                                                Porcentagem
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rateios.map((rateio, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {rateio.categoria}
                                                </TableCell>
                                                <TableCell>
                                                    {formatterCurrency(rateio.valor, 2)}
                                                </TableCell>
                                                <TableCell>
                                                    {formatterPercentage(rateio.percentual, 2)}%
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Paper>
                <Grid container>
                    <Grid 
                        item
                        xs={12} 
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            gap: 2,
                            margin: `0px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`
                        }}
                    >
                        <DefaultButton
                            onClick={() => closeModal()}
                            title="Cancelar"
                        />
                        <DefaultButton
                            onClick={() => setOpenInformarPagamento(true)}
                            title="Informar pagamento"
                            disabled={loading || pago}
                            variant="blue"
                        />
                        <DefaultButton
                            title={"Salvar"}
                            onClick={() => handleEditDespesa()}
                            variant="yellow"
                            disabled={loading}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default React.memo(EditarMovimentacao);