import React, {useState, useEffect} from "react";
import {
    PAPER_PADDING_THEME,
    paperStyle,
    LINE_TABLE,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER,
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    YELLOW_BG_UX,
    GRAY_BG_UX,
    GRAY_LABEL_UX_THEME,
    format,
    DASH_KPI_DATA_COLOR,
    BLACK_TABLE,
    RED_INFO,
    BLACK_LABEL_UX,
    handleDayMonthYear, formatterCurrency,
    DARK_GREEN_BG_UX,
    DARK_GREEN_TEXT_UX,
    DARK_YELLOW_BG_UX,
    LIGHT_YELLOW_BG_UX,
    DARK_YELLOW_TEXT_UX,
    LIGHT_YELLOW_TEXT_UX,
    GRAY_LABEL_UX,
    BLUE_INFO_UX,
    LIGHT_GREEN_BG_UX,
    LIGHT_GREEN_TEXT_UX,
    formatterPercentage,
    GRAY_PDF,
    BLACK_TABLE_PERFIL,
    TITLE_SIZE,
    GRAY_STRONG_UX,
    BORDER_BUTTON
} from "../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../../../axios";
import Anexos from "../../../components/Tabs/Anexos";
import {showSnackMessage} from "../../../actions/SnackActions";
import InputSearch from "../../../components/Input/InputSearch";
import FilterDayMonthYear from "../../../components/Filter/FilterDayMonthYear";
import FilterSingleOption from "../../../components/Filter/FilterSingleOption";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import Paper from "@mui/material/Paper";
import { Skeleton } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DataGrid from "../../../components/DataGrid/DataGrid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InformarPagamentoModal from "../../../components/Modal/InformarPagamentoModal";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EditarPrevisao from "../../../components/Modal/EditarPrevisao";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditarContasPagarReceber = (props) => {

    const {idMovimentacao, recorrencia, tipoLancamentoMov, open, setOpen, recalculatePagePrevious} = props;

    const nav = useNavigate();
    const dispatch = useDispatch();
    const id_lancamento = idMovimentacao;
    const tipoLancamento = tipoLancamentoMov;
    const isLargeScreen = useMediaQuery("(min-width:1440px)");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        if (open) {
            getInfoLancamento();
        }
    }, [open]);

    const [fornecedor, setFornecedor] = useState("");
    const [cliente, setCliente] = useState("");
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [selectedTab, setSelectedTab] = useState("observacoes");
    const [selectedDataCompetencia, setSelectedDataCompetencia] = useState("");
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [valorTotal, setValorTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [tipoSelected, setTipoSelected] = useState("");
    const [selectedVencimento, setSelectedVencimento] = useState("");
    const [inputError, setInputError] = useState(false);
    const [data, setData] = useState([]);
    const [dataRecorrencia, setDataRecorrencia] = useState([]);
    const [valorParcelaAberto, setValorParcelaAberto] = useState(0);
    const [valorParcelaPago, setValorParcelaPago] = useState(0);
    const [valorMulta, setValorMulta] = useState(0);
    const [valorJuros, setValorJuros] = useState(0);
    const [valorDesconto, setValorDesconto] = useState(0);
    const [valorParcelaMulta, setValorParcelaMulta] = useState(0);
    const [valorParcelaJuros, setValorParcelaJuros] = useState(0);
    const [valorParcelaDesconto, setValorParcelaDesconto] = useState(0);
    const [strRecorrencia, setStrRecorrencia] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [isCompra, setIsCompra] = useState(false);

    const [action, setAction] = useState(null);

    const [centroCustoOptions, setCentroCustoOptions] = useState([]);
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [fornecedorOptions, setFornecedorOptions] = useState([]);
    const [clientesOptions, setClientesOptions] = useState([]);
    const [openInformaPagamento, setOpenInformaPagamento] = useState(false);
    const [openPrevisao, setOpenPrevisao] = useState(false);
    const [baixaId, setBaixaId] = useState("");

    const [loadingAnexos, setLoadingAnexos] = useState(true);

    const [observacoes, setObservacoes] = useState("");
    const [anexos, setAnexos] = useState([
        {
            forma_anexo: "Arquivo",
            arquivo: null,
            tipo_anexo: "Contrato",
            descricao: "",
        },
    ]);
    const [rateios, setRateios] = useState([{
        categoria: null,
        valor_total: 0,
        porcentagem: 0,
        centro_custo: null,
    }]);

    useEffect(() => {
        if (selectedTab === "anexo") {
            getAnexos();
        }
    }, [selectedTab]);

    const getAnexos = () => {
        setLoadingAnexos(true);

        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            baixa_id: id_lancamento,
        };

        api.GetAnexos(dataRequest).then((response) => {
            const data = response.data;
            processAnexosData(data);
            setLoadingAnexos(false);
        });
    };

    const handleChangeOptions = (event) => {
        setAction(event.target.value);
    };

    const getInfoLancamento = () => {
        setLoading(true);
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            lancamento_id: id_lancamento,
            state: tipoLancamento === "contas_pagar" ? "contas_pagar" : "contas_receber",
        };
        api.GetInfoMovimentacao(dataRequest).then((response) => {
            const data = response.data;
            setFornecedor(data.fornecedor_cliente);
            setCliente(data.fornecedor_cliente);
            setTipoSelected(data.tipo_despesa_receita);
            setSelectedDataCompetencia(moment(data.data_competencia, "DD-MM-YYYY").toDate());
            setSelectedCategoria(data.categoria);
            setSelectedCentroCusto(data.centro_custo);
            setCodigoReferencia(data.codigo_referencia);
            setSelectedVencimento(moment(data.vencimento, "DD-MM-YYYY").toDate());
            setDescricao(data.descricao);
            setFornecedorOptions(data.inputs_options.input_fornecedor_cliente);
            setClientesOptions(data.inputs_options.input_fornecedor_cliente);
            setCategoriaOptions(data.inputs_options.input_categoria);
            setCentroCustoOptions(data.inputs_options.input_centro_custo);
            setValorTotal(data.valor_total);
            setData(data.detalhe_contas_pagar);
            setObservacoes(data.movimentacao.observacoes);
            setValorParcelaAberto(data.valor_parcela_aberto);
            setValorParcelaPago(data.valor_parcela_pago);
            setValorMulta(data.valor_multa);
            setValorJuros(data.valor_juros);
            setValorDesconto(data.valor_desconto);
            setValorParcelaMulta(data.valor_parcela_multa);
            setValorParcelaJuros(data.valor_parcela_juros);
            setValorParcelaDesconto(data.valor_parcela_desconto);
            setStrRecorrencia(data.recorrencia?.str_recorrencia);
            setDataRecorrencia(data.recorrencia?.datagrid_data_recorrencia);
            setRateios(data.rateios);
            if (data.item_compra) {
                setIsCompra(true);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const processAnexosData = (anexos_data) => {
        if (anexos_data.length === 0) {
            setAnexos([
                {
                    forma_anexo: "Arquivo",
                    arquivo: null,
                    tipo_anexo: "Contrato",
                    descricao: "",
                }
            ]);
            return;
        }

        let anexos_list = [];

        for (let i = 0; i < anexos_data.length; i++) {
            const byteCharacters = atob(anexos_data[i].arquivo);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: anexos_data[i].mime_type });

            let file = new File([blob], anexos_data[i].nome_arquivo, { type: anexos_data[i].mime_type });
        
            anexos_list.push({
                forma_anexo: anexos_data[i].forma_anexo,
                arquivo: file,
                tipo_anexo: anexos_data[i].tipo_anexo,
                descricao: anexos_data[i].descricao,
            });
        }

        setAnexos(anexos_list);
    };

    const handleEditPagarReceber = () => {
        const isInvalid =
            !selectedDataCompetencia ||
            !selectedCategoria ||
            !selectedVencimento ||
            !descricao;
        if (isInvalid) {
            setInputError(true);
            return;
        }

        setLoading(true);
        setLoadingAnexos(true);

        let formData = new FormData();

        formData.append("estabelecimento_id", estabelecimentoId);
        formData.append("movimentacao_id", id_lancamento);
        formData.append("fornecedor", fornecedor);
        formData.append("data_compra", moment(selectedDataCompetencia).format("DD/MM/YYYY"));
        formData.append("categoria_movimentacao", selectedCategoria);
        formData.append("codigo_referencia", codigoReferencia);
        formData.append("vencimento_movimentacao", moment(selectedVencimento).format("DD/MM/YYYY"));
        formData.append("descricao_movimentacao", descricao);
        formData.append("centro_custo", selectedCentroCusto);
        formData.append("tipo_movimentacao", tipoLancamento);

        formData.append("observacoes", observacoes);
        formData.append("anexos", JSON.stringify(anexos));

        if (anexos[0].arquivo != null){
            formData.append("quantidade_anexo", anexos.length);
            anexos.forEach((anexo, index) => {
                formData.append(`anexos[${index}][forma_anexo]`, anexo.forma_anexo);
                formData.append(`anexos[${index}][arquivo]`, anexo.arquivo);
                formData.append(`anexos[${index}][tipo_anexo]`, anexo.tipo_anexo);
                formData.append(`anexos[${index}][descricao]`, anexo.descricao);
            });
        }
         
        api.EditarMovimentacao(formData).then(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Lançamento editado com sucesso!", severity: "success"}));
            closeModal();
            recalculatePagePrevious();
        }).catch(() => {
            setLoading(false);
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const getBackgroudColor = (situacao) => {
        return situacao === "Pago" ? (temaEscuro ? DARK_GREEN_BG_UX : LIGHT_GREEN_BG_UX) : (temaEscuro ? DARK_YELLOW_BG_UX : LIGHT_YELLOW_BG_UX);
    };

    const getColor = (situacao) => {
        return situacao === "Pago" ? (temaEscuro ? DARK_GREEN_TEXT_UX : LIGHT_GREEN_TEXT_UX) : (temaEscuro ? DARK_YELLOW_TEXT_UX : LIGHT_YELLOW_TEXT_UX);
    };

    const getTextTitle = () => {
        return tipoLancamento === "contas_pagar" ? "Informações detalhadas do pagamento" : "Informações detalhadas do recebimento";
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const columns = [
        {
            field: "formatted_data_movimentacao",
            headerName: "Data",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "forma_pagamento_formatted",
            headerName: "Forma de Pagamento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "conta__apelido",
            headerName: "Conta",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor",
            headerName: "Valor (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.valor < 0 && RED_INFO,
                        fontWeight: params.row.valor < 0 ? "bold" : "normal"
                    }}>
                        {formatterCurrency(params.row.valor, 2)}
                    </span>
                );
            }
        },
        {
            field: "furos_multa",
            headerName: "Juros/Multa (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.furos_multa < 0 && RED_INFO,
                        fontWeight: params.row.furos_multa < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.juros_multa, 2)}
                </span>

                );
            }
        },
        {
            field: "desconto",
            headerName: "Desconto (R$)",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{
                        color: params.row.desconto < 0 && RED_INFO,
                        fontWeight: params.row.desconto < 0 ? "bold" : "normal"
                    }}>
                    {formatterCurrency(params.row.desconto, 2)}
                </span>

                );
            }
        },
        {
            field: "situacao",
            headerName: "Situação",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                    }}>
                        <Typography
                            style={{
                                backgroundColor: getBackgroudColor(params.row.situacao),
                                color: getColor(params.row.situacao),
                                borderRadius: "5px",
                                padding: "3px 10px",
                                fontSize: "15px",
                                textAlign: "center",
                                width: "50%",
                            }}
                        >
                            {params.row.situacao}
                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "acoes",
            headerName: "",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const isEmAberto = params.row.situacao === "Em Aberto";
                
                return (
                    <div style={{ marginTop: 6.5 }}>
                        <FormControl fullWidth size="small" data-testId="btn-acoes">
                            <InputLabel shrink={!!action} sx={{color: !isEmAberto && BORDER_BUTTON, fontSize: "15px"}}>Ações</InputLabel>
                            <Select
                                value={action}
                                onChange={handleChangeOptions}
                                sx={{ height: "36px" }}
                                size="small"
                                displayEmpty
                                disabled={!isEmAberto}
                            >
                                {isEmAberto && (
                                    <React.Fragment>
                                        <MenuItem
                                            value={"pagamento"}
                                            onClick={() => handleOpenInformarPagamento(params)}
                                            data-testid="btn-informar-pagamento"
                                        >
                                            Informar pagamento
                                        </MenuItem>
                                        <MenuItem
                                            value={"editar"}
                                            onClick={() => handleOpenEditarPrevisao(params)}
                                        >
                                            Editar
                                        </MenuItem>
                                    </React.Fragment>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                );
            },
        },
    ];

    const handleOpenInformarPagamento = (params) => {
        setOpenInformaPagamento(true);
        setBaixaId(params.row.id);
    };

    const handleOpenEditarPrevisao = (params) => {
        setOpenPrevisao(true);
        setBaixaId(params.row.id);
    };

    const columnsRecorrencia = [
        {
            field: "data_competencia_formatted",
            headerName: "Data de criação",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "data_vencimento_formatted",
            headerName: "Data de vencimento",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "descricao",
            headerName: "Descrição",
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "valor_total",
            headerName: "Valor",
            flex: 1,
            align: "center",
            headerAlign: "center",
        }
    ];

    const handleOpenModalEditCompra = () => {
        const tabAux = "compras-tab";
        nav("/notas_compras", { state:
            {detalhe: 
                { 
                    movimentacaoUUID: codigoReferencia,
                    tab: tabAux
                } 
            } 
        });
    };

    const closeModal = () => {
        setInputError(false);
        setOpen(false);
        recalculatePagePrevious();
    };

    return (
        <React.Fragment>
            {
                openInformaPagamento && (
                    <InformarPagamentoModal
                        open={openInformaPagamento}
                        setOpen={setOpenInformaPagamento}
                        baixaId={baixaId}
                        onConfirmInformarPagamento={() => getInfoLancamento()}
                        setAction={setAction}
                    />
                )
            }
            <EditarPrevisao
                open={openPrevisao}
                setOpen={setOpenPrevisao}
                baixaId={baixaId}
                onConfirmInformarPagamento={() => getInfoLancamento()}
                valorParcelaAberto={valorParcelaAberto}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={() => closeModal()}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        backgroundColor: temaEscuro ? BLACK_TABLE_PERFIL : GRAY_LABEL_UX_THEME,
                        backgroundImage: "none"
                    },
                }}
                data-testid="movimentacao-container"
            >
                <AppBar
                    sx={{
                        backgroundColor: temaEscuro
                            ? BLUE_THEME
                            : LINE_TABLE,
                        backgroundImage: temaEscuro && "none",
                        borderBottom: temaEscuro
                            ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                            : `1px solid ${BORDER_PAPER}`,
                        padding: `0 ${isLargeScreen ? "120px" : "0"}`,
                        position: "relative",
                        boxShadow: "none"
                    }}
                >
                    <Toolbar>
                        <Typography sx={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, fontSize: TITLE_SIZE, fontWeight: "bold", lineHeight: 1, flex: 1}}>
                            {tipoLancamento === "contas_pagar" ? "Editar pagamento" : "Editar recebimento"}
                        </Typography>
                        <IconButton
                            autoFocus
                            color="inherit"
                            onClick={() => closeModal()}
                            aria-label="close"
                            sx={{
                                color: !temaEscuro && BLACK_LABEL_UX
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ overflow: "auto", maxHeight: "100vh" }} data-testid="exm-container-editar-pagar-receber">
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro
                                ? PAPER_PADDING_THEME
                                : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro
                                ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${
                                isLargeScreen ? "120px" : "20px"
                            } 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                        data-testid="perfil-screen"
                    >
                        {loading ? (
                            [...Array(5).keys()].map((i,d) =>{
                                return(
                                    <Skeleton key={d} height={50}/>
                                );
                            })
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <span><p className="title-container-p">Informações do lançamento</p></span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 2, flexWrap: "wrap"}} data-testid="grid-inputs">
                                    <FilterSingleOption
                                        filterOptions={tipoLancamento === "contas_pagar" ? fornecedorOptions : clientesOptions}
                                        selectedOption={tipoLancamento === "contas_pagar" ? fornecedor : cliente}
                                        setSelectedOption={tipoLancamento === "contas_pagar" ? setFornecedor: setCliente}
                                        placeholder={tipoLancamento === "contas_pagar" ? "Fornecedor" : "Cliente"}
                                        width={"250px"}
                                    />
                                    <FilterSingleOption
                                        selectedOption={tipoSelected}
                                        placeholder={tipoLancamento === "contas_pagar" ? "Tipo de despesa" : "Tipo de receita"}
                                        width={"250px"}
                                        disabled
                                    />
                                    <FilterDayMonthYear
                                        handleSubmit={handleDayMonthYear}
                                        value={selectedDataCompetencia}
                                        setState={setSelectedDataCompetencia}
                                        size={"small"}
                                        width={"250px"}
                                        iconPosition={"end"}
                                        label={"Data competência"}
                                        inputError={inputError}
                                        disableMinDate
                                        isRequired
                                    />
                                    <FilterSingleOption
                                        filterOptions={categoriaOptions}
                                        selectedOption={selectedCategoria}
                                        setSelectedOption={setSelectedCategoria}
                                        placeholder="Categoria"
                                        width={"250px"}
                                        inputError={inputError}
                                        isRequired
                                    />
                                    <FilterSingleOption
                                        filterOptions={centroCustoOptions}
                                        selectedOption={selectedCentroCusto}
                                        setSelectedOption={setSelectedCentroCusto}
                                        placeholder="Centro de custo"
                                        width={"250px"}
                                    />
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setCodigoReferencia}
                                        searchValue={codigoReferencia}
                                        label={"Código de referência"}
                                        width={"325px"}
                                        testId="codigo-input"
                                        detalheCompras={handleOpenModalEditCompra}
                                        isCompra={isCompra}
                                    />
                                    <FilterDayMonthYear
                                        handleSubmit={handleDayMonthYear}
                                        value={selectedVencimento}
                                        setState={setSelectedVencimento}
                                        size={"small"}
                                        width={"250px"}
                                        iconPosition={"end"}
                                        label={"Vencimento"}
                                        inputError={inputError}
                                        disableMinDate
                                        isRequired
                                    />
                                    <InputSearch
                                        functionProps={() => {}}
                                        setSearchValue={setDescricao}
                                        searchValue={descricao}
                                        label={"Descrição"}
                                        width={"325px"}
                                        testId="descricao-input"
                                        inputError={inputError}
                                        isRequired
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex", alignItems: "end", gap: 2, flexDirection: "column"}} data-testid="grid-valor-total">
                                    <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                        <p style={{margin: 0}}>Valor total</p>
                                        <p style={{margin: 0, fontSize: 27, fontWeight: "bold"}}>R$ {format(valorTotal)}</p>
                                    </span>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                    {
                        recorrencia && (
                            <Paper
                                sx={{
                                    ...paperStyle,
                                    backgroundColor: temaEscuro
                                        ? PAPER_PADDING_THEME
                                        : LINE_TABLE,
                                    backgroundImage: temaEscuro && "none",
                                    border: temaEscuro
                                        ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                        : `1px solid ${BORDER_PAPER}`,
                                    margin: `20px ${
                                        isLargeScreen ? "120px" : "20px"
                                    } 20px ${isLargeScreen ? "120px" : "20px"}`,
                                }} 
                                data-testid="container-recorrencia"
                            >
                                {loading ? (
                                    [...Array(5).keys()].map((i,d) =>{
                                        return(
                                            <Skeleton key={d} height={50}/>
                                        );
                                    })
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <span><p className="title-container-p">Informações de recorrência</p></span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", gap: 20, flexWrap: "wrap"}}>
                                            <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, display: "flex", gap: 6, flexDirection: "column"}}>
                                                <p className="item-container-p" style={{fontSize: 14}}>Lançamento recorrente?</p>
                                                <p className="item-container-p" style={{fontWeight: "bold", fontSize: 15}}>{recorrencia}</p>
                                            </span>
                                            <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, display: "flex", gap: 6, flexDirection: "column"}}>
                                                <p className="item-container-p" style={{fontSize: 14}}>Configuração de recorrência</p>
                                                <p className="item-container-p" style={{fontWeight: "bold", fontSize: 15}}>{strRecorrencia}</p>
                                            </span>
                                        </Grid>
                                    </Grid>
                                )}
                            </Paper>
                        )
                    }
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro
                                ? PAPER_PADDING_THEME
                                : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro
                                ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${
                                isLargeScreen ? "120px" : "20px"
                            } 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                        data-testid="detalhes-lancamento"
                    >
                        {loading ? (
                            [...Array(5).keys()].map((i,d) =>{
                                return(
                                    <Skeleton key={d} height={50}/>
                                );
                            })
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <span><p className="title-container-p">{getTextTitle()}</p></span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_BLACK_THEME_CARD : BORDER_PAPER}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DataGrid
                                        rows={(row) => row.id}
                                        columns={columns}
                                        data={data}
                                        disableRowSelectionOnClick
                                        NoneFooter
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion sx={{ backgroundColor: "transparent", flex: 1}} expanded={expanded === "panel1"} onChange={handleChange("panel1")}> 
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                            sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right", height: "100%"}}
                                        >
                                            <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: 4, marginRight: 2}}>
                                                <Grid item>
                                                    <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                        <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX }}>Valor em aberto (R$)</p>
                                                        <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: RED_INFO}}>{format(valorParcelaAberto)}</p>
                                                    </span>
                                                </Grid>
                                                <Grid item>
                                                    <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                        <p className="item-container-p" style={{fontSize: 14, color: !temaEscuro && GRAY_BG_UX}}>Valor pago (R$)</p>
                                                        <p className="item-container-p" style={{fontWeight: "bold", fontSize: 16, color: BLUE_INFO_UX}}>{format(valorParcelaPago)}</p>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME}}>
                                            <Table sx={{display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
                                                <TableBody sx={{
                                                    ".MuiTableCell-root": {
                                                        borderBottom: "none",
                                                        padding: "0 0 .5rem 6.6rem"
                                                    }}}
                                                >
                                                    <TableRow>
                                                        <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcela (R$)</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaAberto)}</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaPago + valorMulta + valorJuros - valorDesconto)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" sx={{color: "#9B9B9B"}}>Desconto (R$)</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaDesconto)}</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorDesconto)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" sx={{color: "#9B9B9B"}}>Juros (R$)</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaJuros)}</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorJuros)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right" sx={{color: "#9B9B9B"}}>Multas (R$)</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorParcelaMulta)}</TableCell>
                                                        <TableCell align="right" sx={{fontWeight: "bold"}}>{format(valorMulta)}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {
                                    recorrencia && (
                                        <Grid item xs={12} data-testid="accordion-recorrencia">
                                            <Accordion sx={{ backgroundColor: "transparent", flex: 1 }} expanded={expanded === "panel2"} onChange={handleChange("panel2")}> 
                                                <AccordionSummary
                                                    expandIcon={<ArrowDropDownIcon style={{color: !temaEscuro && GRAY_BG_UX }}/>}
                                                    sx={{ backgroundColor: GRAY_LABEL_UX, textAlign: "right"}}
                                                >
                                                    <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: 4, marginRight: 2}}>
                                                        <Grid item>
                                                            <span style={{color: temaEscuro ? WHITE_ESCRITA_THEME : DASH_KPI_DATA_COLOR, marginLeft: "auto"}}>
                                                                <p className="item-container-p" style={{fontSize: 14}}>Recorrência</p>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{paddingY: 3, backgroundColor: temaEscuro && PAPER_PADDING_THEME, height: 500}}>
                                                    <DataGrid
                                                        rows={(row) => row.id}
                                                        columns={columnsRecorrencia}
                                                        data={dataRecorrencia}
                                                        disableRowSelectionOnClick
                                                        NoneFooter
                                                        compact
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )}
                    </Paper>
                    <Paper
                        sx={{
                            ...paperStyle,
                            backgroundColor: temaEscuro
                                ? PAPER_PADDING_THEME
                                : LINE_TABLE,
                            backgroundImage: temaEscuro && "none",
                            border: temaEscuro
                                ? `1px solid ${BORDER_BLACK_THEME_CARD}`
                                : `1px solid ${BORDER_PAPER}`,
                            margin: `20px ${
                                isLargeScreen ? "120px" : "20px"
                            } 20px ${isLargeScreen ? "120px" : "20px"}`,
                        }}
                        data-testid="btns-observacoes-anexos"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{backgroundColor: "transparent", marginBottom: "15px"}}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={(e, value) => setSelectedTab(value)}
                                    TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                    className="buttonTabs"
                                >
                                    <Tab
                                        value={"observacoes"}
                                        label="Observações"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "observacoes" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": {
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                    />
                                    <Tab
                                        value={"anexo"}
                                        label="Anexo"
                                        sx={{
                                            borderRadius: "20px",
                                            color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                            backgroundColor: selectedTab === "anexo" ? YELLOW_BG_UX : "transparent",
                                            "&.Mui-selected": {
                                                color: BLACK_LABEL_UX,
                                            },
                                        }}
                                    />
                                    {
                                        (rateios.length > 1 && rateios[0].categoria !== null) && (
                                            <Tab
                                                data-testid="categoria-tab"
                                                value={"categoria_info"}
                                                label="Informações de categoria"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                    backgroundColor: selectedTab === "categoria_info" ? YELLOW_BG_UX : "transparent",
                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                        color: BLACK_LABEL_UX,
                                                    },
                                                }}
                                            />
                                        )
                                    }
                                </Tabs>
                            </Grid>
                            {selectedTab === "observacoes" ? (
                                <Grid item xs={12}>
                                    <TextField
                                        data-testid="observacoes-textfield"
                                        label={"Observações"}
                                        id="input-observacoes-textfield"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        maxRows={6}
                                        value={observacoes}
                                        onChange={(event) => {
                                            setObservacoes(event.target.value);
                                        }}
                                        sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}}
                                    />
                                </Grid>
                            ) : selectedTab === "anexo" ? (
                                <Anexos
                                    loading={loadingAnexos}
                                    anexos={anexos}
                                    setAnexos={setAnexos}
                                />
                            ) : (
                                <TableContainer sx={{".MuiTableCell-root": {
                                    padding: ".8rem 1rem",
                                    fontWeight: "400",
                                    textAlign: "center"
                                }}}>
                                    <Table>
                                        <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                            <TableRow>
                                                <TableCell>
                                                    Categoria
                                                </TableCell>
                                                <TableCell>
                                                    Valor
                                                </TableCell>
                                                <TableCell>
                                                    Porcentagem
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rateios.map((rateio, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {rateio.categoria__nome}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatterCurrency(rateio.valor, 2)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatterPercentage(rateio.percentual, 2)}%
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Grid>
                    </Paper>
                    <Grid container>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "end", gap: 2, margin: `0px ${isLargeScreen ? "120px" : "20px"} 20px ${isLargeScreen ? "120px" : "20px"}`}}>
                            <DefaultButton
                                onClick={() => closeModal()}
                                title="Cancelar"
                            />
                            <DefaultButton
                                title={"Salvar"}
                                testId="btn-salvar"
                                onClick={() => handleEditPagarReceber()}
                                disabled={loading}
                                loading={loading}
                                variant="yellow"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default React.memo(EditarContasPagarReceber);